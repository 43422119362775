<template>
    <div class="SupportContent">
        <div class="Container">
            <h1 class="h1">
                {{ $t('support_content:title') }}
            </h1>
            <alert-message
                v-if="errorMessage"
                :message="errorMessage"
                type="error"
                class="SupportContent-alert"
            />
            <div class="SupportContent-form">
                <div class="SupportContent-fieldWrapper">
                    <v-text-field
                        v-model="supportId"
                        :hide-details="true"
                        :label="$t('form:support_content:support_id:label')"
                        :pattern="getPattern"
                        :required="true"
                        :variant="getVariant"
                        class="SupportContent-field"
                        density="comfortable"
                    >
                        <template #append>
                            <v-btn
                                :disabled="!getIsSupportIdValid"
                                :title="$t('button:validate')"
                                class="SaleOptionAnonymousFareMediaChoice-button v-btn__iconStyle"
                                color="primary"
                                size="large"
                                variant="flat"
                                @click.stop="checkIfFareMediaExistsAndDisplayContent"
                            >
                                {{ $t('button:read') }}
                            </v-btn>
                        </template>
                    </v-text-field>
                    <a
                        v-if="$te('how_to_write_card_number:link')"
                        class="SupportContent-link font-italic"
                        :href="$t('how_to_write_card_number:link')"
                        target="_blank"
                    >
                        {{ $t('how_to_write_card_number:description') }}
                    </a>
                </div>
                <v-btn
                    v-if="getIsCardReaderAvailable"
                    class="SupportContent-button"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="text"
                    @click="useCardReader"
                >
                    {{ $t('card_reader:use_button_text') }}
                </v-btn>
            </div>
            <support-reader-modal
                v-model="showSupportReaderModal"
                :headerTitle="$t('card_reader:scan_modal:title')"
                :operationType="'READ'"
                @operation-finished="onOperationFinished"
            />
        </div>
    </div>
</template>

<script setup>
import { isEmpty } from 'global-utils'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import config from 'config'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'

const store = useStore()
const route = useRoute()
const router = useRouter()

const errorMessage = ref(undefined)
const providerId = ref(undefined)
const showSupportReaderModal = ref(false)
const supportId = ref(undefined)

const isMobileApp = computed(() => store.state.isMobileApp)
const isMobileOrTablet = computed(() => store.state.isMobileOrTablet)
const getPattern = computed(() => !isEmpty(config.patterns.support_id.conduent) ? config.patterns.support_id.conduent : '')
const getIsSupportIdValid = computed(() => !isEmpty(supportId.value) ? !isEmpty(supportId.value.match(config.patterns.support_id.conduent)) : false)
const getIsCardReaderAvailable = computed(() => !isMobileApp.value && !isMobileOrTablet.value)
const getVariant = computed(() => config?.theme?.inputs_variant ?? null)
const authenticated = computed(() => store.state.login.authenticated)

onMounted(() => {
    const fareMediaId = route.params.fareMediaId

    if (!isEmpty(fareMediaId)) {
        supportId.value = fareMediaId
    }

    if (route.params?.providerId) {
        providerId.value = route.params.providerId
    }
})

function useCardReader () {
    showSupportReaderModal.value = true
}

async function checkIfFareMediaExistsAndDisplayContent (params) {
    errorMessage.value = undefined

    if (!params?.providerId && !params?.fareMediaId) {
        params = {
            fareMediaId: supportId.value,
            providerId: providerId.value,
            silentError: true
        }
    }

    const { isExistent, error } = await store.dispatch(anonymousActionTypes.GET_SUPPORT_INFOS, params)

    if (isExistent) {
        await router.push({ name: 'walletLight', params })
    } else {
        errorMessage.value = `${error?.message ?? ''} ${error?.externalMessage ?? ''}`
    }
}

async function onOperationFinished (data) {
    if (authenticated.value) {
        await store.dispatch(userActionTypes.GET_WALLET, {
            wsOptions: {
                cacheAsync: false,
                cacheValidity: 0
            },
            cachiosOptions: {
                force: true
            }
        })
    }

    if (!isEmpty(data.support)) {
        supportId.value = data.support.fareMediaId
    }

    showSupportReaderModal.value = false
    const params = {
        providerId: 'conduent',
        fareMediaId: supportId.value,
        isTemporary: false,
        silentError: true
    }

    setSelectedFareMediaCookie(params, true)
    await checkIfFareMediaExistsAndDisplayContent(params)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SupportContent {
    &-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: $s5;
        width: 100%;
        margin-top: $s8;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: $s4;
        }
    }

    &-fieldWrapper {
        display: flex;
        flex-direction: column;
        gap: $s1;
        width: 100%;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            max-width: 500px;
        }

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            width: auto;
        }
    }

    &-field {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
            :deep(.v-input__control) {
                width: 250px;
            }
        }
    }

    &-button {
        font-weight: $fontWeight-defaultBold;
    }

    &-alert {
        margin-top: $s5;
    }
}
</style>
