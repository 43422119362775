import config from 'config'
import ISO6391 from 'iso-639-1'
import i18n from 'i18n'
import cachios from 'cachios'
import { localesForApis } from '@/StoreWeb/js/locales-conversion-table'
import LoginManager from 'user/Login/managers/LoginManager'

export const defaultLocale = config.i18n.default_locale
export const supportedLocales = config.i18n.supported_locales

export const getBrowserLanguage = () => (navigator.language || navigator.userLanguage).substring(0, 2)

export const isLocale = (code) => ISO6391.validate(code)

export const isSupportedLocale = (code) => supportedLocales.find(lang => lang.id === code)

export const applyLocale = (locale) => {
    i18n.locale = locale
    i18n.global.locale = locale
    document.documentElement.setAttribute('lang', locale)
    cachios.axiosInstance.defaults.headers.common['Accept-Language'] = localesForApis[i18n.locale]
    if (!config.web_harmony.header) {
        LoginManager.redirectUrl = `${window.location.origin}/${i18n.locale}/signedin`
    }
}

export const deduceLocale = (segment, isLocalizedRoute) => {
    const localStorageLocale = localStorage.getItem('locale')
    let selectedLocale = defaultLocale

    if (
        localStorageLocale &&
        isLocale(localStorageLocale) &&
        isSupportedLocale(localStorageLocale)
    ) {
        selectedLocale = localStorageLocale
    } else if (
        isLocalizedRoute &&
        isSupportedLocale(segment)
    ) {
        selectedLocale = segment
    } else if (
        !isLocalizedRoute ||
        !isLocale(localStorageLocale)
    ) {
        const browserLanguage = getBrowserLanguage()

        if (isSupportedLocale(browserLanguage)) {
            selectedLocale = browserLanguage
        }
    }

    return selectedLocale
}

/**
 * Extracts the locale from the given URL.
 * @param {string} url The URL to extract the locale from.
 * @returns {string|null} The extracted locale from the URL or null if it could not be extracted.
 */
export const extractLocaleFromUrl = (url) => {
    const foundLocale = url.match(/^https?:\/\/[^/]+\/([^/?#]+)/)?.[1] ?? null

    if (foundLocale && isSupportedLocale(foundLocale)) {
        return foundLocale
    }

    return undefined
}

/**
 * Simplifies the language code to its short form (e.g., 'en-US' to 'en').
 * @param {string} lang The full language code.
 * @returns {string} The simplified language code.
 */
export const simplifyLangCode = (lang) => {
    return lang.split(/[-_]/)[0]
}
