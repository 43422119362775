import config from 'config'
import { computed } from 'vue'

export function useTheme () {
    const getVariant = computed(() => config?.theme?.inputs_variant ?? null)

    return {
        getVariant
    }
}
