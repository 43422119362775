<template>
    <div class="AddressSelectorRecapItem">
        <strong
            v-if="isTypeDisplayed"
            class="AddressSelectorRecapItem-line AddressSelectorRecapItem-type"
        >
            {{ address.getTypeLabel() }}
        </strong>
        <strong
            v-if="address.getFullName() !== ''"
            class="AddressSelectorRecapItem-line"
        >
            {{ address.getFullName() }}
        </strong>
        <strong
            v-if="address.depositName"
            class="AddressSelectorRecapItem-line"
        >
            {{ address.depositName }}
        </strong>
        <span v-if="address.depositId">
            {{ address.depositId }}
        </span>
        <span v-if="address.depositDescription">
            {{ address.depositDescription }}
        </span>
        <span class="AddressSelectorRecapItem-line">
            {{ address.getFullStreetName() }}
        </span>
        <span
            v-if="address.streetLine2"
            class="AddressSelectorRecapItem-line"
        >
            {{ address.streetLine2 }}
        </span>
        <span
            v-if="address.getZipCodeAndCity() !== ''"
            class="AddressSelectorRecapItem-line"
        >
            {{ address.getZipCodeAndCity() }}
        </span>
        <span
            v-if="address.getCountry() !== ''"
            class="AddressSelectorRecapItem-line"
        >
            {{ address.getCountry() }}
        </span>
        <div class="AddressSelectorRecapItem-buttonsArea">
            <a
                v-if="isAddressChangeEnabled"
                class="AddressSelectorRecapItem-changeButton js-AddressSelectorRecapItem-changeButton"
                @click="chooseAnotherAddress"
            >
                {{ $t('address:choose_another') }}
            </a>
            <a
                v-if="isAddressEditEnabled"
                class="AddressSelectorRecapItem-changeButton js-AddressSelectorRecapItem-changeButton"
                @click="editAddress"
            >
                {{ $t('button:edit') }}
            </a>
        </div>
    </div>
</template>

<script>
import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'
import { mapGetters, mapState } from 'vuex'
import { isEmpty } from 'global-utils'

export default {
    props: {
        address: {
            type: Object,
            required: true
        },
        showType: {
            type: Boolean,
            default: false
        },
        showChangeButton: {
            type: Boolean,
            default: false
        },
        showEditButton: {
            type: Boolean,
            default: true
        },
        showSelectButton: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            addressManager: AddressManager.getInstance()
        }
    },
    computed: {
        ...mapGetters(['getAddresses']),
        ...mapState({
            addressAuthorizations: state => state.addressModule.addressAuthorizations
        }),
        isAddressChangeEnabled () {
            return this.showChangeButton && this.getAddresses.length > 1
        },
        isAddressEditEnabled () {
            return this.showEditButton && this.addressAuthorizations.edit
        },
        isTypeDisplayed () {
            return this.showType && !isEmpty(this.address.getTypeLabel())
        }
    },
    methods: {
        chooseAnotherAddress () {
            this.$emit('chooseAnotherAddressClicked')
        },
        editAddress () {
            this.$emit('editAddressClicked', this.address.id)
        }
    }
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.AddressSelectorRecapItem {
    padding: 0 0 0 10px;
    font-size: 14px;
    color: $color-lightText;

    &-line {
        display: block;
        line-height: 1.5;
    }

    &-type {
        @extend %h4;

        margin: 0;
    }

    &-buttonsArea {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
    }

    &-changeButton {
        cursor: pointer;
        font-weight: bold;
    }
}
</style>
