import store from '@/StoreWeb/store/store'
import { isEmpty } from 'global-utils'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import { computed } from 'vue'

export function useViewUtils () {
    const pageConfigManager = PageConfigManager.getInstance()

    const pageConfig = computed(() => store.state.pageConfig)

    function addNewPageClasses () {
        if (hasBodyClasses()) {
            document.body.classList.add(pageConfig.value.bodyClasses)
        }
    }

    function hasBodyClasses () {
        return !isEmpty(pageConfig.value?.bodyClasses)
    }

    function removePreviousPageClasses () {
        if (hasBodyClasses()) {
            document.body.classList.remove(pageConfig.value.bodyClasses)
        }
        pageConfigManager.setConfig({})
    }

    return {
        pageConfig,
        pageConfigManager,
        addNewPageClasses,
        hasBodyClasses,
        removePreviousPageClasses
    }
}
