<template>
    <div class="Password">
        <v-card class="Password-description">
            <v-card-text>
                {{ $t('account:password:update:description') }}
                <div class="text-right mt-4">
                    <v-btn
                        class="bg-primary mx-auto"
                        @click="redirectToPasswordPage"
                    >
                        {{ $t('button:edit') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script setup>
import { nextTick, onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import emitter from 'global-emitter'
import { useRouter } from 'vue-router'
import { useViewUtils } from '@/StoreWeb/js/composables/view-utils'
import { updateUrlParams } from '@/StoreWeb/js/composables/url-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const router = useRouter()
const { locale } = useI18n()
const { kasConfigurationSettings } = useLogin()
const { removePreviousPageClasses, addNewPageClasses } = useViewUtils()

const redirectToPasswordPage = () => {
    if (!isEmpty(kasConfigurationSettings.value?.pages?.password)) {
        const redirectUrl = `${window.location.origin}${router.resolve({ name: 'password' }).href}`

        let passwordEditUrl = kasConfigurationSettings.value.pages.password.replace('{{redirect_uri}}', window.location.href)
        passwordEditUrl = updateUrlParams(passwordEditUrl, {
            kc_locale: locale.value,
            referrer_uri: encodeURIComponent(redirectUrl)
        })

        window.location.href = passwordEditUrl
    }
    emitter.on('getConfigurationSettingsError', () => {
        ErrorManager.displayErrorModal('account:password:update', {})
    })
}

onBeforeMount(() => {
    removePreviousPageClasses()
})

onMounted(async () => {
    await nextTick()
    addNewPageClasses()
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.Password {
    &-description {
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
    }
}
</style>
