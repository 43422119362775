<template>
    <div class="DebugApplicationSession">
        <p>AUTHENTICATED : {{ isAuthenticated ? 'true' : 'false' }}</p>
        <div class="d-flex">
            <v-btn
                color="black"
                class="mb-5"
                @click="resumeFlowNfcNewSession"
            >
                Resume NFC NEW
            </v-btn>
            <v-btn
                color="black"
                class="mb-5"
                @click="resumeFlowNfc"
            >
                Resume NFC existant
            </v-btn>
        </div>
        <v-btn
            color="black"
            class="mb-5"
            @click="logUserTokenInConsole"
        >
            Logger le userToken dans la console
        </v-btn>
        <v-btn
            color="primary"
            class="mb-4"
            @click="simulateResumeAfterLogin"
        >
            Simulate RESUME after LogIn
        </v-btn>
        <v-btn
            color="primary"
            class="mb-4"
            @click="simulateResumeAfterNfc"
        >
            Simulate RESUME after NFC
        </v-btn>
        <v-btn
            color="primary"
            class="mb-4"
            @click="saveTempUserToken"
        >
            Store tempUserToken
        </v-btn>
        <v-btn
            color="primary"
            class="mb-4"
            @click="createApplicationSession"
        >
            Créer une nouvelle session
        </v-btn>
        <v-text-field
            :model-value="getApplicationSessionId"
            :variant="getVariant"
            class="mb-4"
            label="Session ID"
            @update:model-value="updateSessionId"
        />
        <v-btn
            v-if="getApplicationSessionId !== null"
            class="mb-5"
            color="primary"
            @click="getApplicationSession"
        >
            Lire la session créée
        </v-btn>
        <template v-if="getApplicationSessionId !== null">
            <div
                class="d-flex flex-column mt-6 mb-6"
            >
                <v-textarea
                    v-model="userTokenToSet"
                    :variant="getVariant"
                    label="Renseignez le userToken pour simuler la connexion depuis l'application"
                />
                <v-btn
                    color="black"
                    class="mb-5"
                    @click="addTokenToSession"
                >
                    Ajouter le token à la session
                </v-btn>
                <v-btn
                    color="black"
                    class="mb-5"
                    @click="addTokenFromCookieToSession"
                >
                    Ajouter le cookie userToken à la session
                </v-btn>
                <v-btn
                    v-if="getApplicationSessionUserToken !== null"
                    color="black"
                    class="mb-5"
                    @click="removeTokenFromSession"
                >
                    Retirer le token de la session
                </v-btn>
            </div>
            <p v-if="false">
                <router-link :to="{ name: 'externalLinks', params: { action: 'logIn' } }">
                    Go to externalLinks LogIn
                </router-link>
            </p>
            <div
                class="d-flex flex-column mt-6 mb-6"
            >
                <v-textarea
                    v-model="userToken"
                    :variant="getVariant"
                    label="Renseignez le userToken pour simuler la connexion depuis l'application"
                />
                <v-btn
                    color="black"
                    class="mb-5"
                    @click="addTokenFromCookieToSession"
                >
                    Ajouter le cookie userToken à la session
                </v-btn>
                <v-btn
                    color="black"
                    class="mb-5"
                    @click="logOut"
                >
                    Se déconnecter
                </v-btn>
            </div>

            <ul class="Contact-internalLinksList">
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'contact' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks Contact
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks Catalog
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: getApplicationSessionId, initialRequest: externalActionTypes.LOG_IN, status: 'error' } }">
                        internalLinks Catalog ERROR LOGIN
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: getApplicationSessionId, initialRequest: externalActionTypes.GET_FARE_MEDIA_ID_THROUGH_NFC, status: 'error' } }">
                        internalLinks Catalog ERROR NFC
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'wallet' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks wallet
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'orders' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks orders
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'bankAccounts' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks bankAccounts
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'directDebits' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks directDebits
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'addresses' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks addresses
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'accountLinks' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks accountLinks
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'reducedMobility' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks reducedMobility
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'internalLinks', params: { action: 'alertingPreferences' }, query: { sessionId: getApplicationSessionId } }">
                        internalLinks alertingPreferences
                    </router-link>
                </li>
            </ul>
        </template>
    </div>
</template>

<script setup>
import {
    computed,
    onMounted,
    ref
} from 'vue'
import { useRouter } from 'vue-router'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as debuggingMutationTypes from '@/StoreWeb/store/modules/debugging/mutation-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import { isEmpty } from 'global-utils'
import Cookies from 'js-cookie'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
// import ApplicationSessionNormalizer from '@/StoreWeb/normalizers/application-session/ApplicationSessionNormalizerSingleton'
import { useStore } from 'vuex'
import * as loginActionTypes from 'user/Login/store/action-types'
// import readSession from '@/StoreWeb/mocks/application-session/read-application-session/read-application-session-resume-after-login.json'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'

const store = useStore()
const { isAuthenticated } = useLogin()
const router = useRouter()
const { getVariant } = useTheme()

// const applicationSessionNormalizer = ref(ApplicationSessionNormalizer.getInstance())
const debuggingManager = ref(DebuggingManager.getInstance())
const sessionId = ref('')
const userToken = ref('')
const userTokenToSet = ref('')

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const getApplicationSessionId = computed(() => {
    if (!isEmpty(applicationSession.value) && applicationSession.value.id) {
        return applicationSession.value.id
    } else {
        if (!isEmpty(sessionId.value)) {
            return sessionId.value
        }
    }
    return null
})
const getApplicationSessionUserToken = computed(() => { return applicationSession.value?.data?.userToken || null })

const addTokenFromCookieToSession = () => {
    if (isAuthenticated.value) {
        userToken.value = decodeURIComponent(Cookies.get('userToken'))
    }
    if (typeof applicationSession.value.data === 'undefined') {
        applicationSession.value.data = {
            userToken: userToken.value
        }
    } else {
        applicationSession.value.data.userToken = userToken.value
    }
    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
}

const addTokenToSession = () => {
    if (!isEmpty(userTokenToSet.value)) {
        userToken.value = userTokenToSet.value
    } else if (isAuthenticated.value) {
        userToken.value = Cookies.get('userToken')
    }
    if (typeof applicationSession.value.data === 'undefined') {
        applicationSession.value.data = {
            userToken: userToken.value
        }
    } else {
        applicationSession.value.data.userToken = userToken.value
    }
    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
}

const removeTokenFromSession = () => {
    if (applicationSession.value?.data?.userToken) {
        delete applicationSession.value.data.userToken
    }
    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
}

const createApplicationSession = () => {
    store.dispatch(applicationSessionActionTypes.CREATE_APPLICATION_SESSION)
}

const logOut = () => {
    this.$store.dispatch(`login/${loginActionTypes.LOGOUT}`)
}

const logUserTokenInConsole = () => {
    let userToken = Cookies.get('userToken')
    if (!isEmpty(userToken)) {
        userToken = decodeURIComponent(userToken)
        // eslint-disable-next-line
        console.log('userToken :')
        // eslint-disable-next-line
        console.log(userToken)
    } else {
        // eslint-disable-next-line
        console.log('There is no userToken in cookies')
    }
}

const getApplicationSession = () => {
    store.dispatch(applicationSessionActionTypes.READ_APPLICATION_SESSION, {
        id: getApplicationSessionId.value
    })
}

const saveTempUserToken = () => {
    const userTokenCookie = Cookies.get('userToken')
    if (!isEmpty(userTokenCookie)) {
        localStorage.setItem('tempUserToken', userTokenCookie)
    } else {
        // eslint-disable-next-line
        console.log('Aucun token trouvé...')
    }
}

const simulateResumeAfterLogin = async () => {
    // if (!isEmpty(getApplicationSessionId.value)) {
    //     await store.dispatch(applicationSessionActionTypes.CREATE_APPLICATION_SESSION)
    // }
    // debuggingManager.value.simulateAuthenticatedUser()
    // if (typeof applicationSession.value.data === 'undefined') {
    //     applicationSession.value.data = {
    //         userToken: userToken.value
    //     }
    // } else {
    //     applicationSession.value.data.userToken = userToken.value
    // }
    // applicationSession.value.data.addToCartFlow = userToken.value
    // store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    // store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION, applicationSession.value)
    await debuggingManager.value.simulateAuthenticatedUser()
    await debuggingManager.value.enableAllMocks()
    const mock = {
        action: 'getApplicationSession',
        state: true,
        version: 'resume-after-login'
    }
    await store.commit(debuggingMutationTypes.SET_MOCK_STATE, mock)
    await debuggingManager.value.updateConfig(mock)
    // const applicationSessionResponse = await fetch(`${process.env.VUE_APP_CDN_URL}mocks/application-session/read-application-session/read-application-session-resume-after-login.json`)
    // const applicationSession = await applicationSessionResponse.json()
    // console.log('applicationSession', applicationSession)
    // const normalizedSession = await applicationSessionNormalizer.value.normalize(applicationSession)
    // console.log('normalizedSession', normalizedSession)
    // await store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, normalizedSession)
    // // console.log('getApplicationSessionId.value', getApplicationSessionId.value)
    // console.log('simulateResumeAfterLogin >>> applicationSession.value', applicationSession.value)
    await router.push({ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: 'a30ef4ea-7c9d-4f4e-8345-788500f8ed1c' } })
}

const simulateResumeAfterNfc = async () => {
    // await debuggingManager.value.enableAllMocks()
    // const mock = {
    //     action: 'getApplicationSession',
    //     state: true,
    //     version: 'resume-after-nfc'
    // }
    // await store.commit(debuggingMutationTypes.SET_MOCK_STATE, mock)
    // await debuggingManager.value.updateConfig(mock)
    // await router.push({ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: 'a30ef4ea-7c9d-4f4e-8345-788500f8ed1c' } })
    if (typeof applicationSession.value.data === 'undefined') {
        applicationSession.value.data = {}
    }

    applicationSession.value.data.isNfcSupported = true
    // applicationSession.value.data.fareMediaId = '1111111111'
    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
    await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
    window.location.href = router.resolve({ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: applicationSession.value.id, status: 'success' } }).href
}

const resumeFlowNfcNewSession = async () => {
    await store.dispatch(applicationSessionActionTypes.CREATE_APPLICATION_SESSION)
    // console.log('resumeFlowNfc', applicationSession.value)
    const applicationSessionId = applicationSession.value.id
    localStorage.setItem('applicationSessionId', applicationSessionId)
    // const applicationSessionId = '5dfa2e90-5d65-47db-aad3-f29d63c079ac'
    window.location.href = router.resolve({ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: applicationSessionId, status: 'success' } }).href
}

const resumeFlowNfc = async () => {
    // await store.dispatch(applicationSessionActionTypes.CREATE_APPLICATION_SESSION)
    // console.log('resumeFlowNfc', applicationSession.value)
    // const applicationSessionId = applicationSession.value.id
    // const applicationSessionId = '5dfa2e90-5d65-47db-aad3-f29d63c079ac'
    const applicationSessionId = localStorage.getItem('applicationSessionId')
    if (!isEmpty(applicationSessionId)) {
        window.location.href = router.resolve({ name: 'internalLinks', params: { action: 'catalog' }, query: { sessionId: applicationSessionId, status: 'success' } }).href
    } else {
        // eslint-disable-next-line
        console.log('No applicationSessionId found in localStorage')
    }
}

const updateSessionId = (value) => {
    sessionId.value = value
}

onMounted(() => {
    const localStorageApplicationSessionId = localStorage.getItem('applicationSessionId')
    if (isEmpty(localStorageApplicationSessionId)) {
        if (!isEmpty(applicationSession.value) && applicationSession.value.id) {
            localStorage.setItem('applicationSessionId', applicationSession.value.id)
        } else if (!isEmpty(sessionId.value)) {
            localStorage.setItem('applicationSessionId', sessionId.value)
        }
    } else {
        sessionId.value = !isEmpty(applicationSession.value) && applicationSession.value.id ? applicationSession.value.id : localStorageApplicationSessionId
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DebugApplicationSession {
    display: flex;
    flex-direction: column;
    padding: 20px 15px;

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item {
        margin-bottom: 20px;

        &Link {
            cursor: pointer;
        }
    }

    &-debugModeToggleSwitch {
        background: none !important;
    }

    &-mockSwitchItem {
        margin-bottom: 10px;
    }

    &-button {
        width: 100%;
    }
}
</style>
