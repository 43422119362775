<template>
    <div class="ValidityDatesForm">
        <v-text-field
            v-model="$v.validityDate.$model"
            :error="$v.validityDate?.$error"
            :hint="validityDateDescription"
            :label="getLabel"
            :max="maxValidityDate"
            :min="minValidityDate"
            :variant="getVariant"
            class="ValidityDatesForm-field"
            persistent-hint
            type="date"
            @update:model-value="onDateInput"
        />
        <v-alert
            v-if="$v.validityDate?.$errors[0]"
            class="mt-2 mb-4"
            color="error"
            density="compact"
            variant="text"
            size="small"
            icon="mdi-alert"
        >
            <template
                v-for="(error, index) in errors"
                :key="index"
            >
                <br v-if="index > 0">
                {{ error }}
            </template>
        </v-alert>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import { isEmpty } from 'global-utils'
import moment from 'moment'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@/StoreWeb/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'

const store = useStore()
const { t } = useI18n()
const { getVariant } = useTheme()

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    productParameter: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['updateValidityDatesForm'])

const addToCartFlowManager = AddToCartFlowManager.getInstance()

const validityDate = ref('')

const errors = computed(() => {
    return $v.value.validityDate.$errors.map(e => e.$message)
})
const getLabel = computed(() => props.productParameter.label ? props.productParameter.label : '')
const maxValidityDate = computed(() => {
    const maxValue = getPreselectedDate.value?.maxValue ?? props.productParameter?.maxValue
    return (maxValue) ? moment(maxValue).format('YYYY-MM-DD') : ''
})
const minValidityDate = computed(() => {
    const minValue = getPreselectedDate.value?.minValue ?? props.productParameter?.minValue
    return (minValue) ? moment(minValue).format('YYYY-MM-DD') : ''
})
const validityDateDescription = computed(() => {
    let description = ''
    if (!isEmpty(minValidityDate.value)) {
        description += t('form:validity_dates:min').replace('%minDate%', moment(minValidityDate.value).format(t('date_format:default')))
    }
    if (!isEmpty(maxValidityDate.value)) {
        description += (description !== '' ? ', ' : '') + t('form:validity_dates:max').replace('%maxDate%', moment(maxValidityDate.value).format(t('date_format:default')))
    }
    return description
})
const getPreselectedDate = computed(() => props.productParameter?.preselection?.find(value => value.id === props.productParameter.id))

watch(() => props.productParameter, (productParameter) => {
    if (getPreselectedDate.value?.value) {
        validityDate.value = getPreselectedDate.value?.value
    }
})

function isAfterMinDate (date) {
    const momentDate = moment(date)

    return isEmpty(minValidityDate.value) || momentDate.isSameOrAfter(moment(minValidityDate.value))
}
function isBeforeMaxDate (date) {
    const momentDate = moment(date)

    return isEmpty(maxValidityDate.value) || momentDate.isSameOrBefore(moment(maxValidityDate.value))
}
function isValidDate (date) {
    return moment(date).isValid()
}
function isFullyValidDate (date) {
    return isValidDate(date) && isAfterMinDate(date) && isBeforeMaxDate(date)
}

async function onDateInput () {
    await $v.value.validityDate.$touch
    const isValid = await $v.value.validityDate.$validate()

    if (isValid) {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
        updateProductParameter()
    } else {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    }
}

function updateProductParameter () {
    const params = {
        id: props.productParameter.id,
        isProductParameterValid: isFullyValidDate(validityDate.value),
        productParameterValues: [],
        isQuotationRequired: true
    }
    if (!isEmpty(validityDate.value)) {
        params.productParameterValues.push({
            productParameter: props.productParameter.id,
            code: 'VALUE',
            value: `${validityDate.value}`
        })
    }
    emit('updateValidityDatesForm', params)
}

const rules = {
    $autoDirty: true,
    required,
    checkIsValidDate: helpers.withMessage(
        t('validations.hasToBeValidDate').replace('%format%', t('date_format:default')),
        () => {
            return isValidDate(validityDate.value)
        }
    )
}
if (minValidityDate.value) {
    rules.checkIsAfterMinDate = helpers.withMessage(
        t('validations.hasToBeAfterMinDate').replace('%minDate%', moment(minValidityDate.value).format(t('date_format:default'))),
        () => {
            return isAfterMinDate(validityDate.value)
        }
    )
}
if (maxValidityDate.value) {
    rules.checkIsBeforeMaxDate = helpers.withMessage(
        t('validations.hasToBeBeforeMaxDate').replace('%maxDate%', moment(maxValidityDate.value).format(t('date_format:default'))),
        () => {
            return isBeforeMaxDate(validityDate.value)
        }
    )
}

const $v = useVuelidate(
    {
        validityDate: rules
    },
    {
        validityDate
    }
)

onMounted(() => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    if (!isEmpty(addToCartFlowManager?.currentStep?.productParameterValues)) {
        const settledValidityDate = addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.productParameter === props.productParameter.id)

        if (!isEmpty(settledValidityDate?.value) && isFullyValidDate(settledValidityDate.value)) {
            validityDate.value = settledValidityDate.value
        }
    } else if (props.productParameter.id === 'START_VALIDITY_DATE') {
        const value = getPreselectedDate.value?.value ?? props.productParameter?.minValue
        validityDate.value = (value) ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    } else if (props.productParameter.id === 'END_VALIDITY_DATE') {
        const value = getPreselectedDate.value?.value ?? props.productParameter?.maxValue
        validityDate.value = (value) ? moment(value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    }

    if (!isEmpty(validityDate.value)) {
        updateProductParameter()
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
    }
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.ValidityDatesForm {
    &-form {
        margin-bottom: 20px;
    }

    &-field {
        margin-bottom: 15px;
    }

    &-field :deep(.v-field) {
        max-width: 170px;
    }

    &-field :deep(.v-field.v-field--variant-outlined) {
        max-width: 194px;
    }
}
</style>
