<template>
    <div class="TransportClassForm">
        <div class="TransportClassForm-form">
            <strong
                v-if="productParameter.label !== ''"
                class="TransportClassForm-label"
            >
                {{ productParameter.label }}
            </strong>
            <div class="TransportClassForm-classListWrapper">
                <div
                    v-if="showTransportClassesLoader"
                    class="text-center mt-4"
                >
                    <v-progress-circular indeterminate />
                </div>
                <ul
                    v-else-if="transportClasses.length > 0"
                    class="TransportClassForm-list"
                >
                    <li
                        v-for="transportClass in transportClasses"
                        :key="transportClass.value"
                        :class="[{'TransportClassForm-item--active': (selectedTransportClass !== null && selectedTransportClass.value === transportClass.value)}]"
                        class="TransportClassForm-item"
                        @click="updateTransportClass(transportClass)"
                    >
                        {{ transportClass.label }}
                        <span class="TransportClassForm-itemSelectedIconWrapper">
                            <icomoon-icon
                                class="TransportClassForm-itemSelectedIcon"
                                name="Check"
                            />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import { isEmpty } from 'global-utils'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const addToCartFlowManager = AddToCartFlowManager.getInstance()

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    productParameter: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['updateTransportClassForm'])

const transportClasses = ref([])
const saleOptionStep = ref(null)
const selectedTransportClass = ref(null)
const showTransportClassesLoader = ref(false)

const getTransportClasses = async () => {
    showTransportClassesLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value
    })
        .then(transportClassListLoadedHandler)
        .catch(() => {
            showTransportClassesLoader.value = false
        })
}

const transportClassListLoadedHandler = (data) => {
    showTransportClassesLoader.value = false
    transportClasses.value = data.productParameter.values

    if (selectedTransportClass.value === null && !isEmpty(transportClasses.value)) {
        updateTransportClass(transportClasses.value[0])
    }
}

const updateTransportClass = (transportClass) => {
    selectedTransportClass.value = transportClass
    updateProductParameter()
}

const updateProductParameter = () => {
    emit('updateTransportClassForm', {
        id: props.productParameter.id,
        isProductParameterValid: true,
        productParameterValues: [
            {
                productParameter: props.productParameter.id,
                code: 'VALUE',
                label: `${selectedTransportClass.value.label}`,
                value: `${selectedTransportClass.value.value}`
            }
        ],
        isQuotationRequired: true
    })
}

onMounted(() => {
    if (
        !isEmpty(addToCartFlowManager.currentStep) &&
        !isEmpty(addToCartFlowManager.currentStep.productParameterValues)
    ) {
        const transportClass = addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.productParameter === 'TRANSPORT_CLASS')
        if (!isEmpty(transportClass) && !isEmpty(transportClass.value)) {
            updateTransportClass(transportClass.value)
        }
    }

    const saleOptionSteps = addToCartFlowManager.findInPreviousSteps({
        type: 'saleOption'
    }); // Adding a semi colon ";" at this line's end to use decomposition
    [saleOptionStep.value] = saleOptionSteps

    getTransportClasses()
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.TransportClassForm {
    &-form {
        margin-bottom: 20px;

        @media (min-width: $tablet-breakpoint) {
            justify-content: flex-start;
        }
    }

    &-label {
        display: block;
        margin-bottom: 10px;
    }

    &-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        margin: 0;
        padding: 0;
    }

    &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        position: relative;
        min-width: 100px;
        min-height: 50px;
        border-radius: $border-radius-xsmall;
        box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-lightgray3;
        background: $color-lightest;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        color: $color-lightText;
        transition: all .5s;

        &SelectedIconWrapper {
            display: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            width: 22px;
            height: 22px;
            border-top-right-radius: $border-radius-xsmall;
            border-bottom-left-radius: $border-radius-xsmall;
            background: $color-success;
        }

        &SelectedIcon {
            font-size: 16px;
            color: $color-lightest;
        }

        &:hover,
        &:focus {
            box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-gray;
        }

        &--active {
            box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;

            &:hover,
            &:focus {
                box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;
            }

            .TransportClassForm-itemSelectedIconWrapper {
                display: flex;
            }
        }
    }

    &-classListWrapper {
        position: relative;
        min-height: 50px;

        .TransportClassForm-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -21px;
            margin-left: -21px;
        }
    }
}
</style>
