<template>
    <div
        v-if="type !== null"
        class="ResetAnonymousConfirmationModal"
    >
        <modal
            v-model="showModal"
            :title="$t(`reset_anonymous_mode:${type}:modal:title`)"
        >
            <span v-html="$sanitize($t(`reset_anonymous_mode:${type}:modal:subtitle`))" />
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="primary"
                    variant="flat"
                    @click="resetAndContinue"
                >
                    {{ $t('button:continue') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { sessionExpired } from '@/StoreWeb/js/mixins/inactivity-utils'
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue', 'goToPreviousStep', 'sessionExpired'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    type: {
        type: String,
        required: true,
        validator: (value) => {
            return ['catalog_full', 'card_reader', null].includes(value)
        }
    }
})

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emit('update:modelValue', newValue)
    }

})

function closeModal () {
    showModal.value = false
    emit('goToPreviousStep')
}

function resetAndContinue () {
    sessionExpired(true)
    emit('sessionExpired')
}
</script>
