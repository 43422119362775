import i18n from 'i18n'
import { isEmpty } from 'global-utils'

export default class {
    constructor (
        id,
        providerId
    ) {
        this._accountId = ''
        this._actions = []
        this._attributionDate = ''
        this._firstName = ''
        this._id = id
        this._isTemporary = false
        this._label = ''
        this._lastName = ''
        this._products = []
        this._profile = null
        this._providerId = providerId
        this._providerUserId = ''
        this._providerUserExternalId = ''
        this._recipientUser = null
        this._status = ''
        this._type = null
        this._validityEndDate = ''
        this._providerFareMediaId = id
    }

    static activeStatuses = [
        'ACTIVE',
        'CREATING',
        'TO_UPDATE'
    ]

    get accountId () {
        return this._accountId
    }

    set accountId (accountId) {
        this._accountId = accountId
    }

    get actions () {
        return this._actions
    }

    set actions (actions) {
        this._actions = actions
    }

    get attributionDate () {
        return this._attributionDate
    }

    set attributionDate (attributionDate) {
        this._attributionDate = attributionDate
    }

    get fareMediaId () {
        return this._id
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get image () {
        return this._image
    }

    set image (image) {
        this._image = image
    }

    get isTemporary () {
        return this._isTemporary
    }

    set isTemporary (isTemporary) {
        this._isTemporary = isTemporary
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    get products () {
        return this._products
    }

    set products (products) {
        this._products = products
    }

    get profile () {
        return this._profile
    }

    set profile (profile) {
        this._profile = profile
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    get providerUserId () {
        return this._providerUserId
    }

    set providerUserId (providerUserId) {
        this._providerUserId = providerUserId
    }

    get providerUserExternalId () {
        return this._providerUserExternalId
    }

    set providerUserExternalId (providerUserExternalId) {
        this._providerUserExternalId = providerUserExternalId
    }

    get recipientUser () {
        return this._recipientUser
    }

    set recipientUser (recipientUser) {
        this._recipientUser = recipientUser
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get type () {
        return this._type
    }

    set type (type) {
        this._type = type
    }

    get validityEndDate () {
        return this._validityEndDate
    }

    set validityEndDate (validityEndDate) {
        this._validityEndDate = validityEndDate
    }

    getFullName (firstName, lastName) {
        return (typeof firstName === 'string' ? firstName.ucFirst() + ' ' : '') + (typeof lastName === 'string' ? lastName.ucFirst() : '')
    }

    getIsExpired () {
        return new Date(this._validityEndDate).getTime() < new Date().getTime()
    }

    getIcon () {
        return 'Card'
    }

    getStatusLabel () {
        const statusKey = 'fare_media:status:' + this.status.id.toLowerCase()
        const statusMessage = i18n.global.t('fare_media:status:' + this.status.id.toLowerCase())
        if (statusKey === statusMessage) {
            return ''
        }
        return statusMessage
    }

    getSubtitle () {
        return `n°${this.id}`
    }

    getTitle () {
        if (this.isTemporary) {
            return i18n.global.t('fare_media:new_card')
        }
        if (!isEmpty(this.firstName) || !isEmpty(this.lastName)) {
            return i18n.global.t('fare_media:card_of').replace('%cardOwner%', this.getFullName(this.firstName, this.lastName))
        } else if (!isEmpty(this.recipientUser) && (!isEmpty(this.recipientUser.firstName) || !isEmpty(this.recipientUser.lastName))) {
            return i18n.global.t('fare_media:card_of').replace('%cardOwner%', this.getFullName(this.recipientUser.firstName, this.recipientUser.lastName))
        }
        if (this.type?.id && this.type?.label) {
            return this.type.label
        }
        return i18n.global.t('fare_media:card_number').replace('%cardNumber%', this.id)
    }

    get providerFareMediaId () {
        return this._providerFareMediaId
    }

    set providerFareMediaId (providerFareMediaId) {
        this._providerFareMediaId = providerFareMediaId
    }

    toJSON () {
        return {
            actions: this.actions,
            accountId: this.accountId,
            attributionDate: this.attributionDate,
            firstName: this.firstName,
            id: this.id,
            image: this.image,
            isTemporary: this.isTemporary,
            label: this.label,
            lastName: this.lastName,
            products: this.products,
            profile: this.profile,
            providerId: this.providerId,
            providerUserId: this.providerUserId,
            providerUserExternalId: this.providerUserExternalId,
            recipientUser: this.recipientUser,
            status: this.status,
            type: this.type,
            validityEndDate: this.validityEndDate,
            providerFareMediaId: this.providerFareMediaId
        }
    }
}
