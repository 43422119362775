import Mandate from '@/StoreWeb/models/payment/Mandate'
import { isEmpty } from 'global-utils'

export default class {
    normalize (item) {
        const mandate = new Mandate()

        mandate.creditorId = item.creditorId ?? ''
        mandate.identifier = item.identifier ?? ''
        mandate.label = item.label ?? ''
        mandate.signingDate = item.signingDate ?? ''
        mandate.status = item.status ?? ''
        mandate.uniqueMandateReference = item.uniqueMandateReference ?? ''

        return mandate
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }
}
