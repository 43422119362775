import { isEmpty } from 'global-utils'
import Basket from '@/StoreWeb/models/cart/Basket'
import Cart from '@/StoreWeb/models/cart/Cart'
import CartItem from '@/StoreWeb/models/cart/CartItem'
import RecipientUser from '@/StoreWeb/models/user/RecipientUser'

const getLastFareMedia = (data) => {
    let lastFareMedia = null

    if (!isEmpty(data) && !isEmpty(data.baskets)) {
        const count = data.baskets.length
        lastFareMedia = data.baskets[count - 1]
    }
    return lastFareMedia
}

const normalizeCart = (data) => {
    const cart = new Cart()

    if (!isEmpty(data)) {
        cart.baskets = []
        if (!isEmpty(data.baskets)) {
            data.baskets.forEach(item => {
                cart.baskets.push({
                    basket: normalizeBasket(item)
                })
            })
        }
        cart.currency = data.currency
        cart.empty = data.empty
        cart.id = data.id
        cart.packages = []
        if (!isEmpty(data.packages)) {
            data.packages.forEach(item => {
                cart.packages.push({
                    item: normalizePackage(item)
                })
            })
        }
        cart.plans = []
        if (!isEmpty(data.plans)) {
            data.plans.forEach(item => {
                cart.plans.push({
                    item: normalizePlan(item)
                })
            })
        }
        cart.status = data.status
        cart.totalAmountExcludingTaxes = data.totalAmountExcludingTaxes
        cart.paymentModes = data.paymentModes
        cart.totalAmountIncludingTaxes = data.totalAmountIncludingTaxes
        cart.totalVatAmount = data.totalVatAmount
        if (!isEmpty(data.immediateAmountExcludingTaxes)) {
            cart.immediateAmountExcludingTaxes = data.immediateAmountExcludingTaxes
        }
        if (!isEmpty(data.immediateAmountIncludingTaxes)) {
            cart.immediateAmountIncludingTaxes = data.immediateAmountIncludingTaxes
        }
        if (!isEmpty(data.immediateVatAmount)) {
            cart.immediateVatAmount = data.immediateVatAmount
        }
        cart.userId = data.userId
        cart.actions = data.actions ?? []
    }

    return cart
}

const normalizeBasket = (data) => {
    const basket = new Basket()

    if (typeof data.basketType !== 'undefined') {
        basket.type = data.basketType
    }
    basket.info = data.basketInfo
    if (typeof data.deliveryMode !== 'undefined') {
        basket.deliveryMode = data.deliveryMode
    }
    basket.line = data.line
    if (typeof data.products !== 'undefined') {
        basket.products = []
        data.products.forEach(item => {
            basket.products.push({
                item: normalizeProduct(item)
            })
        })
    }
    basket.providerId = data.providerId
    basket.providerUserEmail = data.providerUserEmail ?? ''
    basket.providerUserExternalId = data.providerUserExternalId
    basket.providerUserFirstName = data.providerUserFirstName ?? ''
    basket.providerUserId = data.providerUserId
    basket.providerUserLastName = data.providerUserLastName ?? ''

    if (typeof data.fareMediaId !== 'undefined') {
        basket.fareMediaId = data.fareMediaId
    }
    if (typeof data.fareMediaLabel !== 'undefined') {
        basket.fareMediaLabel = data.fareMediaLabel
    }
    if (typeof data.ownerFirstName !== 'undefined') {
        basket.ownerFirstName = data.ownerFirstName
    }
    if (typeof data.ownerLastName !== 'undefined') {
        basket.ownerLastName = data.ownerLastName
    }
    if (typeof data.recipientUser !== 'undefined') {
        basket.recipientUser = normalizeRecipientUser(data.recipientUser)
    }

    if (typeof data.deviceId !== 'undefined') {
        basket.deviceId = data.deviceId
    }

    if (typeof data.deviceLabel !== 'undefined') {
        basket.deviceLabel = data.deviceLabel
    }

    return basket
}

const normalizeProduct = (data) => {
    const item = new CartItem(data.modalProductId)

    item.basketLine = data.basketLine
    item.currency = data.currency
    item.deliveryMode = data.deliveryMode
    item.fareExcludingTaxes = data.fareExcludingTaxes
    item.fareIncludingTaxes = data.fareIncludingTaxes
    item.line = data.line
    item.name = data.modalProductName
    item.address = data.address ?? null
    if (typeof data.providerItemDescription !== 'undefined') {
        item.providerItemDescription = data.providerItemDescription
    }
    item.actions = data.actions
    item.quantity = data.quantity
    item.saleableUnit = data.saleableUnit
    item.totalFareExcludingTaxes = data.totalFareExcludingTaxes
    item.totalFareIncludingTaxes = data.totalFareIncludingTaxes
    item.totalVatAmount = data.totalVatAmount
    item.vatAmount = data.vatAmount
    item.vatRate = data.vatRate
    item.version = data.modalProductVersion
    item.providerNetworkLabel = data.providerNetworkLabel

    return item
}

const normalizeRecipientUser = (data) => {
    const item = new RecipientUser()

    if (!isEmpty(data.id)) {
        item.associationId = data.id
    }
    if (!isEmpty(data.associationId)) {
        item.associationId = data.associationId
    }
    item.email = data.email
    item.firstName = data.firstName
    item.lastName = data.lastName
    item.userId = data.userId

    return item
}

const normalizePackage = (data) => {
    const item = new CartItem(data.modalPackageId)
    if (typeof data.basketLine !== 'undefined') {
        item.basketLine = data.basketLine
    }
    item.currency = data.currency
    item.fare = data.fare
    item.line = data.line
    item.name = data.modalPackageName
    if (typeof data.providerItemDescription !== 'undefined') {
        item.providerItemDescription = data.providerItemDescription
    }
    item.quantity = data.quantity
    item.saleableUnit = data.saleableUnit
    item.taxes = data.taxes
    item.totalFare = data.totalFare
    item.totalTaxes = data.totalTaxes
    item.version = data.modalPackageVersion

    return item
}

const normalizePlan = (data) => {
    const item = new CartItem(data.modalPlanId)
    item.fareExcludingTaxes = data.fareExcludingTaxes
    item.fareIncludingTaxes = data.fareIncludingTaxes
    item.headerId = data.headerId
    item.line = data.planLine
    item.name = data.modalPlanName
    item.unit = data.unit
    item.vatAmount = data.vatAmount
    item.vatRate = data.vatRate
    item.version = data.modalPlanVersion

    return item
}

const getModalPackageMinMax = (state, id) => {
    if (state.products && state.products[id]) {
        return {
            min: state.products[id].modalPackage.minNumber,
            max: state.products[id].modalPackage.maxNumber
        }
    }
    return {
        min: null,
        max: null
    }
}

const getModalProductMinMax = (state, id) => {
    if (state.products && state.products[id]) {
        return {
            min: state.products[id].modalProduct.minNumber,
            max: state.products[id].modalProduct.maxNumber
        }
    }
    return {
        min: null,
        max: null
    }
}

export default {
    getLastFareMedia,
    normalizeCart,
    getModalPackageMinMax,
    getModalProductMinMax
}
