import { isEmpty } from 'global-utils'
import * as mutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import cachios from 'cachios'
import config from 'config'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import FileManager from './FileManager'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'

export default class {
    constructor () {
        this._debuggingManager = DebuggingManager.getInstance()
        this._store = null
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    dataURLToFile (dataUrl, filename) {
        const arr = dataUrl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime })
    }

    cropImage (params, documents) {
        const updatedDocument = documents.find(document => parseInt(document.id) === parseInt(params.id))
        if (updatedDocument) {
            updatedDocument.base64String = params.base64String
        }
    }

    updateDocuments (id, file, documents) {
        const updatedDocument = documents.find(document => {
            return document.id === id
        })

        if (updatedDocument && file) {
            updatedDocument.fileObject = file
            updatedDocument.fileName = file?.name || ''
            this.updateDocumentBase64String(updatedDocument, updatedDocument.fileObject)
        }

        return documents
    }

    getDocumentsInstructions (documents) {
        if (!isEmpty(documents)) {
            return FileManager.getFileInstructions(documents[0].mimeTypes, documents[0].maxSize, true)
        }

        return ''
    }

    updateDocumentBase64String (document, file) {
        if (file !== null) {
            const reader = new FileReader()
            reader.onload = () => {
                document.base64String = reader.result
            }
            reader.readAsDataURL(file)
        } else {
            document.base64String = ''
        }
        return document
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    uploadDocumentAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_PENDING_REQUEST, { key: 'uploadDocument', value: true })
            const body = params.formData
            const id = encodeURIComponent(params.id)
            cachios
                .post(`${config.storeWebMiddlewareUrl}/documents?documentId=${id}`,
                    body, { force: true }
                )
                .then(response => {
                    if (!isEmpty(params) && typeof params.callback === 'function') {
                        params.callback(response.data)
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    ErrorManager.displayErrorModal('document:upload_document', error)

                    reject(error)
                })
                .finally(() => {
                    this.store.commit(mutationTypes.SET_PENDING_REQUEST, { key: 'uploadDocument', value: false })
                    this.store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                })
        })
    }
}
