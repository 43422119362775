import { createI18n } from 'vue-i18n'
import translations from 'translations'
import config from 'config'
import { extractLocaleFromUrl, simplifyLangCode } from '@/StoreWeb/js/composables/i18n-utils'

// Attempt to extract the locale from the URL, then the browser's language settings, and finally default to 'fr'.
const finalLang = extractLocaleFromUrl(window.location.href) ??
    simplifyLangCode(navigator.languages?.[0] ?? navigator.language) ??
    'fr'

const i18n = createI18n({
    allowComposition: true,
    locale: finalLang,
    fallbackLocale: 'fr',
    messages: translations
})

document.documentElement.setAttribute('lang', finalLang)

// We want to overload i18n to ensure each time a {cmsUrl} variable is used in a translation, it is replaced by the CMS URL.
const alwaysIncludedTranslationVariables = {
    cmsUrl: config.cms_url
    // Add more variables here if needed
}

const originalTranslationFunction = i18n.global.t

i18n.global.t = (key, params = {}) => {
    return originalTranslationFunction(key, {
        ...params,
        ...alwaysIncludedTranslationVariables
    })
}

export default i18n
