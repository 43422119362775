<template>
    <main class="Cart">
        <div class="Container Cart-container">
            <router-link :to="{ name: 'catalog' }">
                {{ $t('cart:continue_shopping') }}
            </router-link>
            <h1 class="Cart-title">
                {{ $t('cart:title') }}
            </h1>
            <block-loader v-if="showCartLoader" />
            <div v-else-if="!isEmptyCart">
                <v-row>
                    <v-col
                        class="Cart-container"
                        cols="12"
                        md="7"
                    >
                        <cart-content
                            class="Cart-content"
                            :isEditable="true"
                        />
                        <span
                            v-if="basketHasCardReaderMandatory"
                            class="Cart-cardReaderMandatory"
                        >
                            <icomoon-icon
                                :layersCount="3"
                                name="CardReader"
                                class="Cart-cardReaderMandatoryIcon"
                            />
                            <span
                                v-html="$sanitize($t('cart:card_reader_mandatory'))"
                            />
                        </span>
                        <address-selector
                            v-if="isAuthenticated"
                            :isCartConfirmed="isCartConfirmed"
                            class="Cart-addressSelector"
                            @address-forms-error="updateAddressFormsError"
                            @address-changed="cartConfirmation"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        md="5"
                        class="pl-md-15"
                    >
                        <v-card class="Cart-consents">
                            <v-card-title>
                                <div class="Cart-immediateTotal">
                                    <span class="Cart-immediateTotalLabel">{{ $t('cart:total_to_settle_immediately') }}</span>
                                    <formatted-fare
                                        :currency="getCurrency(getCart.currency)"
                                        :fare="cartImmediateAmount"
                                        size="Small"
                                        tag="span"
                                        class="Cart-immediateTotalFare"
                                    />
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <sales-terms
                                            class="Cart-termsAndConsents"
                                            @sales-terms-approval-changed="updateSalesTermsAccepted"
                                        />
                                        <div
                                            v-if="userPendingRequests.getConsents"
                                            class="Cart-loader"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            />
                                        </div>
                                        <template v-else-if="consentsList.length > 0">
                                            <terms-and-consents
                                                v-for="(consent, index) in consentsList"
                                                :key="index"
                                                :isChecked="consent.accepted"
                                                :label="consent.content"
                                                :forId="`js-consent-${index}`"
                                                :name="consent.code"
                                                class="Cart-termsAndConsents"
                                                @terms-and-consents-approval-changed="(event) => updateConsentsAccepted(index, event)"
                                            />
                                        </template>
                                        <v-spacer />
                                        <v-btn
                                            class="mb-4"
                                            color="primary"
                                            width="100%"
                                            :disabled="isConfirmButtonDisabled"
                                            @click="cartConfirmationButtonClicked"
                                        >
                                            {{ $t('button:order') }}
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn
                                            :disabled="isDeleteCartButtonDisabled"
                                            variant="text"
                                            color="primary"
                                            width="100%"
                                            @click="clearCart"
                                        >
                                            {{ $t('button:cancel:order') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div
                v-else
                class="Cart-emptyCart"
            >
                <p class="Cart-emptyCartMessage">
                    {{ $t('cart:empty') }}
                </p>
                <v-btn
                    :to="{ name: 'catalog' }"
                    color="primary"
                    variant="flat"
                >
                    {{ $t('button:back_to_catalog') }}
                </v-btn>
            </div>
        </div>
    </main>
</template>

<script setup>
import AddressSelector from '@/StoreWeb/components/address/AddressSelector'
import CartContent from '@/StoreWeb/components/cart/CartContent'
import SalesTerms from '@/StoreWeb/components/checkout/SalesTerms'
import TermsAndConsents from '@/StoreWeb/components/checkout/TermsAndConsents'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import { storeConfirmedCartInfos } from '@/StoreWeb/js/composables/cart-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import config from 'config'
import { getCurrency, isEmpty } from 'global-utils'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const { isAuthenticated } = useLogin()

const billingAddress = ref(null)
const deliveryAddress = ref(null)
const addressFormsError = ref(null)
const salesTermsAccepted = ref(false)
const isCartConfirmed = ref(false)
const isDeleteCartButtonDisabled = ref(false)
const consentsList = ref([])

const cartPendingRequests = computed(() => store.state.cartModule.cartPendingRequests)
const getCart = computed(() => store.getters.getCart)
const getAddressTemplates = computed(() => store.getters.getAddressTemplates)
const isConfirmButtonDisabled = computed(() => !salesTermsAccepted.value || addressFormsError.value)
const isEmptyCart = computed(() => getCart.value?.empty)
const showCartLoader = computed(() => cartPendingRequests.value.getCart)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)

const cartImmediateAmount = computed(() => {
    if (isEmpty(getCart.value)) {
        return 0
    }

    return getCart.value.immediateAmountIncludingTaxes || 0
})
const basketHasCardReaderMandatory = computed(() => {
    return getCart.value?.baskets.some(basket => basket?.basket?.products.some(product => product?.item?.deliveryMode === 'CARDREADER_MANDATORY'))
})

async function clearCart () {
    if (!isEmpty(getCart) && !getCart.value.empty) {
        isDeleteCartButtonDisabled.value = true
        const isCartDeleted = await store.dispatch(cartActionTypes.DELETE_CART, {
            id: getCart.value.id
        })

        if (!isCartDeleted) {
            isDeleteCartButtonDisabled.value = false
        } else {
            await router.push({ name: 'catalog' })
        }
    }
}

async function cartConfirmationButtonClicked () {
    isCartConfirmed.value = salesTermsAccepted.value

    if (!isAuthenticated.value) {
        cartConfirmation({
            billingAddress: billingAddress.value,
            deliveryAddress: store.getters.getCart.baskets.find((value) => value.basket.deliveryMode === 'POSTAL') ? deliveryAddress.value : null
        })
    } else if (consentsList.value.length) {
        await store.dispatch(userActionTypes.SET_CONSENTS, {
            consents: consentsList.value
        })
    }
}

function formatConsentsList (consents) {
    for (const consent of consents) {
        if (consent.type === 'BOOLEAN' && !consent.accepted) {
            consentsList.value.push(consent)
        }
    }
}

async function cartConfirmation (data) {
    isCartConfirmed.value = false
    storeConfirmedCartInfos(data)
    await router.push({ name: 'cartConfirmation' })
}

function updateSalesTermsAccepted (isChecked) {
    salesTermsAccepted.value = isChecked
}

function updateConsentsAccepted (index, isChecked) {
    consentsList.value[index].accepted = isChecked
}

function updateAddressFormsError (error) {
    addressFormsError.value = error
}

onMounted(() => {
    if (isAuthenticated.value && config.features.consents.cart) {
        store.dispatch(userActionTypes.GET_CONSENTS, {
            type: 'sales'
        }).then((response) => {
            const marketingConsents = response.find(section => section.category === 'MARKETING')

            if (marketingConsents?.consents?.length) {
                formatConsentsList(marketingConsents.consents)
            }
        })

        if (isEmpty(getAddressTemplates)) {
            store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
        }
    }
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.Cart {
    background: $color-lightest;

    &-title {
        @extend %h1;

        margin-bottom: $s8;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: $s6;
    }

    &-consents {
        border-radius: $border-radius-medium;
        box-shadow: $boxShadow-card;
    }

    &-immediateTotal {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: $s3;
        margin-bottom: $s5;
    }

    &-immediateTotalLabel {
        flex: 1;
        font-size: $font-small;
        white-space: break-spaces;
    }

    &-termsAndConsents {
        margin-bottom: $s6;
    }

    &-emptyCart {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $s10;
        padding-top: $s3;
    }

    &-emptyCartMessage {
        font-size: $font-xsmall;
    }

    &-cardReaderMandatory {
        display: flex;
        gap: $s2;
        padding: $s4;
        border-radius: $border-radius-medium;
        background: $color-brandSecondaryLightBackground;
        font-weight: bold;
        color: $color-brandSecondary;
    }

    &-cardReaderMandatoryIcon {
        margin-top: 2px;
        font-size: $font-medium;
    }
}
</style>

<style lang='scss'>
/* stylelint-disable */
// Case really special where we need to bypass the a css style

@import 'globalScss';

.Cart {
    &-link {
        color: $color-brandPrimary;
        transition: color .3s ease-in-out;

        &:hover,
        &:active,
        &:focus {
            color: $color-brandPrimaryDark;
        }
    }

    &-loader {
        width: 100%;
        margin-top: $s4;
        text-align: center;
    }
}
/* stylelint-enable */
</style>
