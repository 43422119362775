<template>
    <div class="HeaderAccount">
        <button
            :aria-label="getToggleButtonAriaText"
            :class="isAuthenticated ? 'HeaderAccount-toggleButton--loggedIn' : ''"
            :title="getToggleButtonAriaText"
            class="HeaderAccount-toggleButton"
            @click="toggle"
        >
            <icomoon-icon
                class="HeaderAccount-toggleButtonIcon"
                name="Account--outlined"
            />
            <span class="HeaderAccount-toggleButtonText">{{ getToggleButtonText }}</span>
        </button>
        <modal
            v-if="xs"
            v-model="showAccountNav"
            :title="$t('account:account:title')"
            :width="'full'"
            :is-rounded="withRoundedModal"
        >
            <template #actions>
                <account-nav
                    :accountNav="navigationMixins.getAccountNav.value"
                    class="HeaderAccount-fullScreenModalNav"
                />
            </template>
        </modal>
        <header-account-nav-content
            v-show="smAndUp && showAccountNav"
            v-click-outside="clickOutside"
            :style="contentStyle"
            class="HeaderAccount-content"
        />
    </div>
</template>

<script setup>
import AccountNav from '@/StoreWeb/components/account/AccountNav'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { startLogInProcess, useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useNavigationMixins } from '@/StoreWeb/js/navigation-mixins'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import config from 'config'
import { isEmpty } from 'global-utils'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'
import HeaderAccountNavContent from './HeaderAccountNavContent'

defineProps({
    withRoundedModal: {
        type: Boolean,
        default: false
    }
})

const store = useStore()
const route = useRoute()
const { t } = useI18n()
const navigationMixins = useNavigationMixins()
const { xs, smAndUp, mdAndUp } = useDisplay()
const { isAuthenticated } = useLogin()

const contentStyle = ref('')

const showAccountNav = computed(() => store.state.userModule.showAccountNav)
const userInfos = computed(() => store.state.login.userInfos)
const getToggleButtonText = computed(() => {
    let authenticatedToggleButtonText = t('account:account:title')
    const isFirstNameEmpty = isEmpty(userInfos.value.firstName)

    if (!isFirstNameEmpty && config.header.template !== 'sytral') {
        authenticatedToggleButtonText = userInfos.value.firstName
    }

    return isAuthenticated.value ? authenticatedToggleButtonText : t('account:nav:offline_toggle')
})
const getToggleButtonAriaText = computed(() => {
    return isAuthenticated.value ? t('account:nav:toggle') : t('account:nav:offline_toggle')
})

onMounted(() => {
    document.addEventListener('click', clickOutside)
})

onUnmounted(() => {
    document.removeEventListener('click', clickOutside)
})

function clickOutside (event) {
    if (!(event.target.className.includes('HeaderAccount'))) {
        hideAccountNavContent()
    }
}

function setAccountNavContentOffset () {
    const headerAccount = document.querySelector('.HeaderAccount')
    const button = document.querySelector('.HeaderAccount-toggleButton')
    const buttonIcon = document.querySelector('.HeaderAccount-toggleButtonIcon')
    const buttonStyle = button.currentStyle || window.getComputedStyle(button)
    const buttonPaddingLeft = parseInt(buttonStyle.paddingLeft)
    const headerAccountWidth = headerAccount.getBoundingClientRect().width
    const buttonIconWidth = buttonIcon.getBoundingClientRect().width
    let right
    let left

    if (mdAndUp.value) {
        right = `${headerAccountWidth - buttonPaddingLeft - buttonIconWidth / 2 - 19}px`
        left = 'auto'
    } else {
        right = 'auto'
        left = 0
    }

    contentStyle.value = `right: ${right}; left: ${left};`
}

function hideAccountNavContent () {
    store.commit(userMutationTypes.SET_SHOW_ACCOUNT_NAV, false)
}

function logIn () {
    startLogInProcess(route)
}

function toggle () {
    if (isAuthenticated.value) {
        updateAccountNavContentDisplay()
    } else {
        logIn()
    }
}

function updateAccountNavContentDisplay () {
    if (!showAccountNav.value && window.innerWidth >= 600) {
        setAccountNavContentOffset()
    }

    store.commit(userMutationTypes.SET_SHOW_ACCOUNT_NAV, !showAccountNav.value)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.HeaderAccount {
    position: relative;

    &-toggleButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        position: relative;
        min-width: 55px;
        height: 100%;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        white-space: nowrap;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active,
        &:focus {
            color: $color-lightText;
        }

        @media (min-width: $desktop-breakpoint) {
            padding: 0 20px 0 15px;
        }

        &Icon {
            position: relative;
            font-size: 28px;
        }

        &Text {
            display: none;

            @media (min-width: $desktop-breakpoint) {
                display: inline;
            }
        }

        &--loggedIn {
            .HeaderAccount-toggleButtonIcon::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                background: $color-brandSecondary;
            }
        }
    }

    &-content {
        position: absolute;
        top: 50px;
        padding: $s3 $s5;
    }
}
</style>
