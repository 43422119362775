<template>
    <component
        :is="'li'"
        class="AccountNavItem"
        @click="accountItemClicked"
    >
        <router-link
            v-if="to !== null"
            :to="to"
            class="AccountNavItem-link"
        >
            <icomoon-icon
                :layers-count="iconLayersCount"
                :name="icon"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </router-link>
        <a
            v-else-if="externalLink !== ''"
            :href="sanitizeUrl(externalLink)"
            class="AccountNavItem-link"
            :title="title ? title : null"
            target="_blank"
        >
            <icomoon-icon
                :layers-count="iconLayersCount"
                :name="icon"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
            <v-icon
                :aria-label="ariaLabel"
                class="pl-3"
                icon="mdi-open-in-new"
                color="primary"
                size="medium"
            />
        </a>
        <a
            v-else-if="onClick !== null"
            class="AccountNavItem-link"
            @click="onClick"
        >
            <icomoon-icon
                :layers-count="iconLayersCount"
                :name="icon"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </a>
    </component>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { sanitizeUrl } from '@braintree/sanitize-url'
import emitter from 'global-emitter'

defineProps({
    externalLink: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        required: true
    },
    iconClassPrefix: {
        type: String,
        default: 'Icon'
    },
    iconLayersCount: {
        type: Number,
        default: 0
    },
    label: {
        type: String,
        required: true
    },
    onClick: {
        type: Function,
        default: null
    },
    to: {
        type: Object,
        default: null
    },
    title: {
        type: String,
        default: ''
    },
    ariaLabel: {
        type: String,
        default: ''
    }
})

function accountItemClicked () {
    emitter.emit('accountItemClicked', this)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';
@import 'commonCustomizationScss';

.AccountNavItem {
    font-size: 16px;
    font-weight: $fontWeight-defaultRegular;

    &-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active {
            color: $color-lightText;
        }
    }

    &-link.router-link-active {
        font-weight: $fontWeight-defaultBold;
        color: $color-lightText;
    }

    &-itemIcon {
        margin-right: 8px;
        font-size: 20px;
        color: $color-brandPrimary;
    }
}
</style>
