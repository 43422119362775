<template>
    <div class="OriginDestinationForm">
        <v-autocomplete
            ref="originAutocomplete"
            v-model="selectedOrigin"
            :custom-filter="filterSuggestion"
            :items="origins"
            :label="$t('add_to_cart_flow:origin_destination_selector:origin')"
            :loading="showOriginsLoader"
            :no-data-text="$t('autocomplete:no_result')"
            :variant="getVariant"
            item-title="label"
            item-value="value"
            return-object
            @update:model-value="updateSelectedOrigin"
            @update:search="resetMatches(originAutocomplete.filteredItems)"
        >
            <template #item="{ props: activatorProps, item }">
                <v-list-item
                    v-bind="activatorProps"
                    :title="item.name"
                >
                    <template #title>
                        <div
                            v-html="$sanitize(highlightMatch(item))"
                        />
                    </template>
                </v-list-item>
            </template>
        </v-autocomplete>
        <v-autocomplete
            v-if="selectedOrigin !== null"
            ref="destinationAutocomplete"
            v-model="selectedDestination"
            :custom-filter="filterSuggestion"
            :items="destinations"
            :label="$t('add_to_cart_flow:origin_destination_selector:destination')"
            :loading="showDestinationsLoader"
            :no-data-text="$t('autocomplete:no_result')"
            :variant="getVariant"
            item-title="label"
            item-value="value"
            return-object
            @update:model-value="updateSelectedDestination"
            @update:search="resetMatches(destinationAutocomplete.filteredItems)"
        >
            <template #item="{ props: activatorProps, item }">
                <v-list-item
                    v-bind="activatorProps"
                    :title="item.name"
                >
                    <template #title>
                        <div
                            v-html="$sanitize(highlightMatch(item))"
                        />
                    </template>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import { isEmpty } from 'global-utils'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { filterSuggestion, highlightMatch, resetMatches } from '@/StoreWeb/js/mixins/autocomplete-utils'
import { useStore } from 'vuex'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'

const store = useStore()
const { getVariant } = useTheme()

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    productParameter: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['updateOriginDestinationForm'])

const addToCartFlowManager = AddToCartFlowManager.getInstance()

const defaultDestinationValue = ref('')
const defaultOriginValue = ref('')
const destinationAutocomplete = ref(null)
const destinations = ref([])
const originAutocomplete = ref(null)
const origins = ref([])
const saleOptionStep = ref(null)
const selectedDestination = ref(null)
const selectedOrigin = ref(null)
const showDestinationsLoader = ref(false)
const showOriginsLoader = ref(false)

const getOrigins = async () => {
    showOriginsLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value,
        currentProductParameterOptions: {
            subParameter: 'origin'
        }
    })
        .then(originListLoadedHandler)
        .catch(() => {
            showOriginsLoader.value = false
        })
}

const getDestinations = async () => {
    showDestinationsLoader.value = true

    await store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value,
        currentProductParameterOptions: {
            subParameters: [
                {
                    productParameter: props.productParameter.id,
                    code: 'ORIGIN',
                    value: selectedOrigin.value.value
                }
            ],
            subParameter: 'destination'
        }
    })
        .then(destinationListLoadedHandler)
        .catch(() => {
            showDestinationsLoader.value = false
        })
}

const destinationListLoadedHandler = (data) => {
    destinations.value = data.productParameter.values

    if (!isEmpty(props.productParameter.preselection)) {
        const destinationDefaultValue = props.productParameter.preselection.find(preselection => preselection.code === 'DESTINATION')

        if (destinationDefaultValue) {
            selectedDestination.value = destinations.value.find(destination => destination.value === destinationDefaultValue.value)
            if (selectedDestination.value) {
                updateSelectedDestination(selectedDestination.value)
                defaultDestinationValue.value = selectedDestination.value
            }
        }
    }

    showDestinationsLoader.value = false
}

const originListLoadedHandler = (data) => {
    origins.value = data.productParameter.values

    if (!isEmpty(props.productParameter.preselection)) {
        const originDefaultValue = props.productParameter.preselection.find(preselection => preselection.code === 'ORIGIN')

        if (originDefaultValue) {
            selectedOrigin.value = origins.value.find(origin => origin.value === originDefaultValue.value)
            if (selectedOrigin.value) {
                updateSelectedOrigin(selectedOrigin.value)
                defaultOriginValue.value = selectedOrigin.value
            }
        }
    }

    showOriginsLoader.value = false
}

const updateProductParameter = () => {
    const productParameterValues = []

    productParameterValues.push({
        productParameter: props.productParameter.id,
        code: 'DESTINATION',
        value: `${selectedDestination.value.value}`
    })
    productParameterValues.push({
        productParameter: props.productParameter.id,
        code: 'ORIGIN',
        value: `${selectedOrigin.value.value}`
    })

    emit('updateOriginDestinationForm', {
        id: props.productParameter.id,
        isProductParameterValid: true,
        productParameterValues,
        isQuotationRequired: true
    })
}

const updateSelectedDestination = (destination) => {
    selectedDestination.value = destination

    if (selectedOrigin.value !== null && selectedDestination.value !== null) {
        updateProductParameter()
    }
}
const updateSelectedOrigin = (origin) => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    selectedOrigin.value = origin

    if (selectedOrigin.value !== null) {
        getDestinations()
    }
}

const setDefaultValues = () => {
    if (!isEmpty(addToCartFlowManager?.currentStep?.productParameterValues)) {
        const origin = addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'origin')
        const destination = addToCartFlowManager.currentStep.productParameterValues.find(productParameter => productParameter.code === 'destination')

        if (origin?.value) {
            updateSelectedOrigin({ value: origin.value })
        }

        if (destination?.value) {
            updateSelectedDestination({ value: destination.value })
        }
    }
}

onMounted(() => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)

    setDefaultValues()

    const saleOptionSteps = addToCartFlowManager.findInPreviousSteps({
        type: 'saleOption'
    }); // Adding a semi colon ";" at this line's end to use decomposition
    [saleOptionStep.value] = saleOptionSteps

    getOrigins()
})
</script>
