<template>
    <div :class="isSytral ? '' : 'AuthenticationModeSelector'">
        <div
            :class="{ 'AuthenticationModeSelector-container--sytral' : isSytral }"
            class="AuthenticationModeSelector-container"
        >
            <h2
                v-if="getTitle !== ''"
                :class="{ 'AuthenticationModeSelector-title--sytral' : isSytral }"
                class="AuthenticationModeSelector-title"
                v-html="$sanitize(getTitle)"
            />
            <template v-if="selectedAuthenticationMode === null && isEmpty(selectedFareMedia)">
                <template v-if="isSytral">
                    <authentication-descriptions class="mt-4 mb-8" />
                    <ul
                        v-if="getAuthenticationModes.length"
                        class="AuthenticationModeSelector-AuthenticationModeList"
                    >
                        <authentication-mode-item-sytral
                            v-for="authenticationMode in getAuthenticationModes"
                            :id="authenticationMode.id"
                            :key="authenticationMode.id"
                            :action="authenticationMode.action"
                            :icon="authenticationMode.icon"
                            :title="authenticationMode.title"
                            :subtitle="authenticationMode.subtitle"
                            class="AuthenticationModeSelector-AuthenticationModeItem"
                            @authentication-mode-clicked="authenticationModeClicked"
                        />
                    </ul>
                    <authentication-mode-item-sytral
                        v-if="emailMandatoryMode"
                        :id="emailMandatoryMode.id"
                        :key="emailMandatoryMode.id"
                        :action="emailMandatoryMode.action"
                        :icon="emailMandatoryMode.icon"
                        :title="emailMandatoryMode.title"
                        :subtitle="emailMandatoryMode.subtitle"
                        :type="'div'"
                        class="AuthenticationModeSelector-AuthenticationModeItem mt-4"
                        @authentication-mode-clicked="authenticationModeClicked"
                    />
                </template>
                <template v-else>
                    <authentication-descriptions class="justify-center" />
                    <div class="AuthenticationModeSelector-buttons">
                        <ul
                            v-if="getAuthenticationModes.length"
                            class="AuthenticationModeSelector-list"
                        >
                            <authentication-mode-item
                                v-for="authenticationMode in getAuthenticationModes"
                                :id="authenticationMode.id"
                                :key="authenticationMode.id"
                                :color="authenticationMode.color"
                                :label="authenticationMode.label"
                                @authentication-mode-clicked="authenticationModeClicked"
                            />
                        </ul>
                        <authentication-mode-item
                            v-if="emailMandatoryMode"
                            :id="emailMandatoryMode.id"
                            :label="emailMandatoryMode.label"
                            :type="'div'"
                            class="AuthenticationModeSelector-emailMandatory"
                            @authentication-mode-clicked="authenticationModeClicked"
                        />
                    </div>
                </template>
            </template>
            <authentication-mode-anonymous
                v-if="getAuthenticationModeAnonymous"
                @update-email="updateAuthenticationModeAnonymous"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import config from 'config'
import emitter from 'global-emitter'
import { useStore } from 'vuex'
import AuthenticationDescriptions from '@/StoreWeb/components/common/AuthenticationDescriptions'
import AuthenticationModeItem from '@/StoreWeb/components/common/AuthenticationModeItem'
import AuthenticationModeItemSytral from '@/StoreWeb/components/common/AuthenticationModeItemSytral'
import AuthenticationModeAnonymous from '@/StoreWeb/components/common/AuthenticationModeAnonymous'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import addCartFlowMixins from '@/StoreWeb/js/mixins/add-cart-flow-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import Cookies from 'js-cookie'

const store = useStore()
const { t } = useI18n()
const addToCartFlowManager = AddToCartFlowManager.getInstance()
const { storeAddCartFlow, triggerLogin } = addCartFlowMixins()

const props = defineProps({
    authenticationModes: {
        type: Array,
        required: true
    },
    title: {
        type: String,
        default: ''
    }
})
const emit = defineEmits(['selectedAuthenticationModeUpdated'])

const selectedAuthenticationMode = ref(null)
const selectedEmail = ref('')
const addToCartEmail = computed(() => store.state.cartModule.addToCartEmail)
const hasAddToCartEmail = computed(() => addToCartEmail.value === Cookies.get('cartEmail'))
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const getTitle = computed(() => getAuthenticationModeAnonymous.value ? '' : props.title)

const getAuthenticationModeAnonymous = computed(() => {
    return (selectedAuthenticationMode.value === 'EMAILMANDATORY' || !isEmpty(selectedFareMedia.value)) && !hasAddToCartEmail.value
})

const emailMandatoryMode = computed(() => {
    return config.features.modal_auth_guest_mode_activated && props.authenticationModes.includes('EMAILMANDATORY')
        ? {
            id: 'EMAILMANDATORY',
            icon: 'Wallet--outlined',
            label: t('user_auth_mode:email_mandatory_button'),
            title: t('user_auth_mode:email_mandatory:title'),
            subtitle: t('user_auth_mode:email_mandatory:subtitle')
        }
        : false
})

const getAuthenticationModes = computed(() => {
    const authenticationModes = []

    props.authenticationModes.forEach(authenticationMode => {
        if (authenticationMode === 'USERACCOUNT') {
            authenticationModes.push({
                color: 'primary',
                icon: 'Account--outlined',
                id: authenticationMode,
                label: t('user_auth_mode:user_account:title'),
                title: t('user_auth_mode:user_account:title'),
                subtitle: t('user_auth_mode:user_account:subtitle')
            })
        } else if (authenticationMode !== 'EMAILMANDATORY') {
            authenticationModes.push({
                id: authenticationMode,
                label: ''
            })
        }
    })

    return authenticationModes
})

const isSytral = computed(() => {
    return config.features.modal_auth === 'sytral'
})

const checkFormError = () => {
    if (!isEmpty(selectedAuthenticationMode.value)) {
        if (selectedAuthenticationMode.value === 'EMAILMANDATORY' && !isEmpty(selectedEmail.value)) {
            return true
        }
    }
    return false
}
const authenticationModeClicked = async (authenticationMode) => {
    selectedAuthenticationMode.value = authenticationMode
    switch (selectedAuthenticationMode.value) {
        case 'USERACCOUNT':
            await storeAddCartFlow({
                mode: 'PRODUCT_DETAILS',
                product: addToCartFlowManager.product
            })
            triggerLogin({
                callback: emitter.emit('hideProductModal', { keep: true })
            })
            break
    }
}

const updateAuthenticationModeAnonymous = (email) => {
    selectedEmail.value = email
    store.commit(cartMutationTypes.SET_ADD_TO_CART_EMAIL, email)
    Cookies.set('cartEmail', email, {
        domain: config?.userweb?.cookies_domain ?? window.location.hostname,
        path: '/'
    })
    emit('selectedAuthenticationModeUpdated', {
        authenticationMode: selectedAuthenticationMode.value,
        isAuthenticationModeParametersValid: checkFormError(),
        authenticationModeParameters: {
            email: selectedEmail.value
        },
        goToNext: true
    })
}

onMounted(() => {
    // Skip the email modal if the authentication mode is 'EMAILMANDATORY' and an email has already been entered.
    // Automatically select the cart email, update the authentication mode settings, and proceed to the next step.
    if (emailMandatoryMode.value && hasAddToCartEmail.value) {
        selectedEmail.value = addToCartEmail.value
        emit('selectedAuthenticationModeUpdated', {
            authenticationMode: selectedAuthenticationMode.value,
            isAuthenticationModeParametersValid: checkFormError(),
            authenticationModeParameters: {
                email: selectedEmail.value
            },
            goToNext: true
        })
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AuthenticationModeSelector {
    display: flex;
    justify-content: center;

    &-container {
        width: 100%;
        text-align: center;

        &--sytral {
            max-width: 100%;
            text-align: left;
        }
    }

    &-title {
        margin: 0 0 $s6;
        font-size: 16px;
        text-align: left;

        &--sytral {
            margin: 0 0 $s4;
            font-weight: $fontWeight-defaultBold;
        }
    }

    &-label {
        margin: 0;
        font-size: 14px;
    }

    &-AuthenticationModeList {
        padding: 0;
        list-style: none;
    }

    &-AuthenticationModeItem {
        margin-bottom: $s4;
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        gap: $s4;
        padding: $padding-small 0;
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: $s4;
        margin-top: $s6;
        padding: 0;
        list-style: none;
    }

    &-description {
        display: block;
    }

    &-singleAuthenticationMode {
        font-size: 14px;
    }

    &-noAvailableSupportError {
        border: 1px solid;
        border-radius: 6px;
    }
}
</style>
