import i18n from '@/StoreWeb/i18n'
import moment from 'moment/moment'

export default class {
    constructor (providerBankAccountIdentifier) {
        this._bankName = ''
        this._bic = ''
        this._iban = ''
        this._actions = []
        this._mandates = []
        this._providerBankAccountIdentifier = providerBankAccountIdentifier
        this._providerId = ''
        this._providerUserId = ''
        this._signUrl = ''
    }

    get bankName () {
        return this._bankName
    }

    set bankName (bankName) {
        this._bankName = bankName
    }

    get bic () {
        return this._bic
    }

    set bic (bic) {
        this._bic = bic
    }

    get iban () {
        return this._iban
    }

    set iban (iban) {
        this._iban = iban
    }

    get actions () {
        return this._actions
    }

    set actions (actions) {
        this._actions = actions
    }

    get mandates () {
        return this._mandates
    }

    set mandates (mandates) {
        this._mandates = mandates
    }

    get providerBankAccountIdentifier () {
        return this._providerBankAccountIdentifier
    }

    set providerBankAccountIdentifier (providerBankAccountIdentifier) {
        this._providerBankAccountIdentifier = providerBankAccountIdentifier
    }

    get providerId () {
        return this._providerId
    }

    set providerId (providerId) {
        this._providerId = providerId
    }

    get providerUserId () {
        return this._providerUserId
    }

    set providerUserId (providerUserId) {
        this._providerUserId = providerUserId
    }

    get signUrl () {
        return this._signUrl
    }

    set signUrl (signUrl) {
        this._signUrl = signUrl
    }

    isSigned () {
        if (this.mandates.length === 0) {
            return false
        }

        return this.mandates.some(mandate => mandate.status === 'A' || mandate.signingDate)
    }

    getSignatureDate () {
        if (this.mandates.length === 0) {
            return ''
        }

        const signedMandate = this.mandates.find(mandate => mandate.signingDate)

        if (signedMandate) {
            return moment(signedMandate.signingDate).format(i18n.global.t('date_format:default'))
        }

        return ''
    }

    toJSON () {
        return {
            bankName: this.bankName,
            bic: this.bic,
            iban: this.iban,
            actions: this.actions,
            mandates: this.mandates,
            providerBankAccountIdentifier: this.providerBankAccountIdentifier,
            providerId: this.providerId,
            providerUserId: this.providerUserId,
            signUrl: this.signUrl
        }
    }
}
