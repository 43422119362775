import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from '@/StoreWeb/utils/global-utils'

export const useUserAccountSubscription = () => {
    const store = useStore()

    const showUserAccountSubscriptionModal = ref(false)

    const getSelectedProvider = computed(() => {
        if (store.getters.getWalletProviders.length > 0) {
            return store.getters.getWalletProviders[0]
        }

        return null
    })
    const getShowUserAccountSubscription = computed(() => {
        return store.getters.getWalletProviderAccounts.length === 0 &&
            !isEmpty(getSelectedProvider.value)
    })
    const getWallet = computed(() => store.getters.getWallet)

    function setShowUserAccountSubscriptionModal () {
        showUserAccountSubscriptionModal.value = true
    }

    return {
        showUserAccountSubscriptionModal,
        getSelectedProvider,
        getShowUserAccountSubscription,
        getWallet,
        setShowUserAccountSubscriptionModal
    }
}
