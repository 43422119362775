<template>
    <div class="PersonalDataSytral">
        <alert-message
            v-if="getAlertMessage"
            :message="getAlertMessage"
            class="PersonalDataSytral-errorMessage mb-2"
            type="error"
        />
        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:consents') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:consents:modify') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="displayConsents"
                >
                    {{ $t('button:edit') }}
                </v-btn>
            </v-card-actions>
            <iframe
                v-if="consentsIframeUrl"
                :src="consentsIframeUrl"
                frameborder="0"
                allowfullscreen
            />
        </v-card>

        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:personal_data:title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:personal_data:description') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <a
                    :href="sanitizeUrl($t('account:personal_data:user_data_claim'))"
                    class="PersonalDataSytral-link"
                    target="_blank"
                    v-text="$t('account:personal_datas:retreive_my_infos')"
                />
            </v-card-actions>
        </v-card>

        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:deactivate:confirm_modal_title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:deactivate:confirm_modal_description2') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <v-btn
                    color="primary"
                    variant="flat"
                    @click="confirmDeletion"
                >
                    {{ $t('account:deactivate:confirm_modal_delete_button') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="mb-5">
            <v-card-title>
                {{ $t('account:deactivate_with_data:confirm_modal_title') }}
            </v-card-title>
            <v-card-text>
                {{ $t('account:deactivate_with_data:confirm_modal_description') }}
            </v-card-text>
            <v-card-actions class="justify-center px-4 pb-4">
                <a
                    :href="sanitizeUrl($t('account:personal_data:user_data_claim'))"
                    class="PersonalDataSytral-link"
                    target="_blank"
                    v-text="$t('account:deactivate:confirm_modal_delete_withdatas_button')"
                />
            </v-card-actions>
        </v-card>
        <modal
            v-model="showConfirmDeletionModal"
            :title="$t('account:deactivate:confirm_modal_title')"
        >
            <div class="pa-6">
                <p
                    class="text-justify"
                    v-html="$sanitize(getFormattedDescription($t('account:deactivate:re_confirm_modal_description')))"
                />
                <div class="PersonalDataSytral-deleteModalButtonAreaWrapper">
                    <div class="PersonalDataSytral-deleteModalButtonArea">
                        <v-btn
                            color="primary"
                            class="PersonalDataSytral-deleteModalConfirmWithDatasButton"
                            @click="deleteAccount"
                        >
                            {{ $t('account:deactivate:confirm_modal_delete_button') }}
                        </v-btn>
                        <v-btn
                            variant="text"
                            @click="closeConfirmDeletionModal"
                        >
                            {{ $t('account:deactivate:confirm_modal_cancel') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script setup>
import AlertMessage from '@/StoreWeb/components/common/AlertMessage.vue'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { sanitizeUrl } from '@braintree/sanitize-url'
import config from 'config'
import * as loginActionTypes from 'user/Login/store/action-types'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const store = useStore()
const { t } = useI18n()
const pageConfigManager = PageConfigManager.getInstance()

const showConfirmDeletionModal = ref(false)
const showDeleteAccountModal = ref(false)
const errorMessage = ref(null)
const consentsIframeUrl = ref('')

const getAlertMessage = computed(() => errorMessage.value)

onMounted(() => {
    pageConfigManager.setConfig({
        title: 'account:personal_data:title',
        currentAccountPage: 'personalData'
    })
})

function confirmDeletion () {
    setShowConfirmDeletionModal()
}

async function deleteAccount () {
    closeDeleteAccountModal()

    await store.dispatch(userActionTypes.DELETE_ACCOUNT).then(async () => {
        if (config.web_harmony.header) {
            document.body.dispatchEvent(new CustomEvent('kas-logout'))
        } else {
            await store.dispatch(`login/${loginActionTypes.LOGOUT}`)
            closeConfirmDeletionModal()
        }
    })
}

function displayConsents () {
    store.dispatch(userActionTypes.GET_SYTRAL_CONSENTS).then((response) => {
        errorMessage.value = null
        consentsIframeUrl.value = response.url
    }).catch(() => {
        errorMessage.value = t('account:consents:get_consents:error')
    })
}

function getFormattedDescription (description) {
    return description.replace(/\\n/g, '<br>')
}

function setShowConfirmDeletionModal () {
    showConfirmDeletionModal.value = true
}

function closeConfirmDeletionModal () {
    showConfirmDeletionModal.value = false
}

function closeDeleteAccountModal () {
    showDeleteAccountModal.value = false
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.PersonalDataSytral {
    max-width: 750px;

    &-sectionActionButton {
        position: absolute;
        top: 4px;
        right: 4px;
    }

    iframe {
        width: 100%;
        height: 500px;
        margin-top: 20px;
    }

    &-link {
        padding: 8px $padding-xsmall;
        border-radius: 10px;
        background-color: $primary-color !important;
        font-size: $font-xsmall;
        font-weight: $fontWeight-defaultMedium;
        color: $color-white;
        transition: opacity .3s ease-in-out;

        &:hover {
            opacity: .92;
        }
    }

    &-deactivateAccountLink {
        cursor: pointer;
        font-weight: 500;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active {
            color: $color-lightText;
        }
    }

    &-deleteModalButtonAreaWrapper {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    &-deleteModalButtonArea {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 15px;

        .v-btn {
            margin: 0;
        }
    }
}
</style>
