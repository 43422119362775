<template>
    <div class="UserAccountThirdPartySubscriptionModal">
        <alert-message
            v-if="getSubscriptionThirdAccountErrorMessage.value !== ''"
            :message="getSubscriptionThirdAccountErrorMessage.value"
            class="UserAccountSubscriptionModal-errorMessage"
            type="error"
        />
        <modal
            v-model="showModal"
            :title="$t('account:new_subscription:title')"
            width="medium"
        >
            <v-form
                @submit.prevent="submitSubscription"
            >
                <p class="mb-4">
                    {{ $t('form:required_field_info') }}
                </p>
                <v-select
                    v-model="form.civility"
                    :error-messages="v$.civility.$errors.map(e => e.$message)"
                    :items="civility"
                    :variant="getVariant"
                    item-title="label"
                    @blur="v$.civility.$touch()"
                    @update:model-value="v$.civility.$touch()"
                >
                    <template #label>
                        <div v-html="getRequiredLabel('civility')" />
                    </template>
                </v-select>
                <v-text-field
                    v-model="form.lastName"
                    :error-messages="v$.lastName.$errors.map(e => e.$message)"
                    :variant="getVariant"
                    @update:model-value="v$.lastName.$touch()"
                >
                    <template #label>
                        <div v-html="getRequiredLabel('lastname')" />
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="form.firstName"
                    :error-messages="v$.firstName.$errors.map(e => e.$message)"
                    :variant="getVariant"
                    @update:model-value="v$.firstName.$touch()"
                >
                    <template #label>
                        <div v-html="getRequiredLabel('firstname')" />
                    </template>
                </v-text-field>
                <fieldset class="UserAccountThirdPartySubscriptionModal-fieldset rounded-lg mb-5">
                    <legend class="UserAccountThirdPartySubscriptionModal-legend px-2">
                        {{ $t('user_account_third_party_subscription:form:birth:label') }}
                    </legend>
                    <v-row>
                        <v-col
                            class="pb-0 pb-sm-3"
                            cols="12"
                            sm="5"
                            md="4"
                        >
                            <v-text-field
                                v-model="form.birthDate"
                                :error-messages="v$.birthDate.$errors.map(e => e.$message)"
                                :variant="getVariant"
                                class="UserAccountThirdPartySubscriptionModal-dateField"
                                type="date"
                                @update:model-value="v$.birthDate.$touch()"
                            >
                                <template #label>
                                    <div v-html="getRequiredLabel('date')" />
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="pt-0 pt-sm-3"
                            cols="12"
                            sm="7"
                            md="8"
                        >
                            <v-text-field
                                v-model="form.birthPlace"
                                :error-messages="v$.birthPlace.$errors.map(e => e.$message)"
                                :label="$t('user_account_third_party_subscription:form:place:label')"
                                :variant="getVariant"
                                @update:model-value="v$.birthPlace.$touch()"
                            />
                        </v-col>
                    </v-row>
                </fieldset>
                <v-text-field
                    v-model="form.email"
                    :error-messages="v$.email.$errors.map(e => e.$message)"
                    :label="$t('user_account_third_party_subscription:form:email:label')"
                    :variant="getVariant"
                    @update:model-value="v$.email.$touch()"
                >
                    <template #label>
                        <div v-html="getRequiredLabel('user_account_third_party_subscription:form:email:label')" />
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="form.mobilePhoneNumber"
                    :error-messages="v$.mobilePhoneNumber.$errors.map(e => e.$message)"
                    :label="$t('mobile_phone')"
                    :variant="getVariant"
                    @update:model-value="v$.mobilePhoneNumber.$touch()"
                />
                <v-text-field
                    v-model="form.landlinePhoneNumber"
                    :error-messages="v$.landlinePhoneNumber.$errors.map(e => e.$message)"
                    :label="$t('user_account_third_party_subscription:form:landing_phone:label')"
                    :variant="getVariant"
                    @update:model-value="v$.landlinePhoneNumber.$touch()"
                />
                <v-text-field
                    v-model="form.deskPhoneNumber"
                    :error-messages="v$.deskPhoneNumber.$errors.map(e => e.$message)"
                    :label="$t('user_account_third_party_subscription:form:desk_phone:label')"
                    :variant="getVariant"
                    @update:model-value="v$.deskPhoneNumber.$touch()"
                />
                <v-select
                    v-model="form.communicationPreference"
                    :items="communicationPreference"
                    :label="$t('form:user_infos:communication_preference:label')"
                    :variant="getVariant"
                    item-title="label"
                    @update:model-value="v$.communicationPreference.$touch()"
                />
                <v-checkbox
                    v-model="form.isSubscriptionTermsAccepted"
                    :error-messages="v$.isSubscriptionTermsAccepted.$errors.map(e => e.$message)"
                >
                    <template #label>
                        <div>
                            {{ $t('account:new_subscription:conditions_terms:acceptation') }}
                            <button
                                class="text--primary"
                                @click.prevent="goToLink('https://www.tcl.fr/cgu')"
                            >
                                {{ $t('terms_conditions:title') }}
                                <v-icon
                                    :aria-label="$t('open_new_tab')"
                                    icon="mdi-open-in-new"
                                    color="primary"
                                    size="medium"
                                />
                            </button>
                        </div>
                    </template>
                </v-checkbox>
            </v-form>
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="submitForm"
                >
                    {{ $t('next') }}
                </v-btn>
            </template>
        </modal>
        <modal
            v-model="showSuccessModal"
            size
            :title="$t('account:new_subscription:title')"
            @close-modal="reloadPage"
        >
            <v-alert
                density="compact"
                :text="t('account:new_subscription:success')"
                type="success"
                variant="tonal"
            />
            <template #actions>
                <v-btn
                    class="bg-primary ml-2"
                    @click="reloadPage()"
                >
                    {{ $t('next') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import AlertMessage from '@/StoreWeb/components/common/AlertMessage.vue'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'
import * as actionTypes from '@/StoreWeb/store/modules/user/action-types'
import { email, maxLength, required } from '@/StoreWeb/utils/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import DOMPurify from 'dompurify'
import emitter from 'global-emitter'
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()
const { getVariant } = useTheme()

const civility = reactive([
    {
        value: 'MR',
        label: computed(() => t('user_account_third_party_subscription:form:civility:mr:label'))
    },
    {
        value: 'MS',
        label: computed(() => t('user_account_third_party_subscription:form:civility:ms:label'))
    },
    {
        value: 'UNDEFINED',
        label: computed(() => t('user_account_third_party_subscription:form:civility:undefined:label'))
    }
])

const communicationPreference = ref([
    {
        value: '',
        label: computed(() => t('form:user_infos:communication_preferences:none'))
    },
    {
        value: 'SMS',
        label: computed(() => t('form:user_infos:communication_preferences:sms'))
    },
    {
        value: 'EMAIL',
        label: computed(() => t('form:user_infos:communication_preferences:email'))
    },
    {
        value: 'POSTALMAIL',
        label: computed(() => t('form:user_infos:communication_preferences:mail'))
    },
    {
        value: 'PHONE',
        label: computed(() => t('form:user_infos:communication_preferences:phone'))
    }
])
const showSuccessModal = ref(false)

const initialState = {
    civility: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    birthPlace: '',
    email: '',
    mobilePhoneNumber: '',
    landlinePhoneNumber: '',
    deskPhoneNumber: '',
    communicationPreference: '',
    isSubscriptionTermsAccepted: false
}

const form = reactive({
    ...initialState
})

const rules = {
    firstName: { required, maxLength: maxLength(30) },
    lastName: { required, maxLength: maxLength(30) },
    email: { required, email },
    civility: { required },
    birthDate: { required },
    birthPlace: {},
    mobilePhoneNumber: { maxLength: maxLength(25) },
    landlinePhoneNumber: { maxLength: maxLength(25) },
    deskPhoneNumber: { maxLength: maxLength(25) },
    communicationPreference: {},
    isSubscriptionTermsAccepted: { checked: value => value === true }
}
const v$ = useVuelidate(rules, form)
const subscriptionThirdAccountErrorMessage = ref('')
const getSubscriptionThirdAccountErrorMessage = computed(() => subscriptionThirdAccountErrorMessage)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (!newValue) {
            clear()
        }

        emit('update:modelValue', newValue)
    }
})

onMounted(() => {
    emitter.on('getSubscriptionThirdAccountSuccess', getSubscriptionAccountSuccessHandler)
    emitter.on('getSubscriptionThirdAccountError', getSubscriptionAccountErrorHandler)
})

onUnmounted(() => {
    emitter.off('getSubscriptionThirdAccountSuccess', getSubscriptionAccountSuccessHandler)
    emitter.off('getSubscriptionThirdAccountError', getSubscriptionAccountErrorHandler)
})

function clear () {
    v$.value.$reset()
    for (const [key, value] of Object.entries(initialState)) {
        form[key] = value
    }
}

function goToLink (link) {
    window.open(link, '_blank')
}

async function submitForm () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        submitSubscription()
    }
}

async function submitSubscription () {
    await store.dispatch(actionTypes.SET_NEW_THIRD_ACCOUNT, {
        form,
        cachiosOptions: {
            force: true
        }
    })
}

function getSubscriptionAccountSuccessHandler () {
    subscriptionThirdAccountErrorMessage.value = ''
    closeModal()
    showSuccessModal.value = true
}

function getSubscriptionAccountErrorHandler () {
    subscriptionThirdAccountErrorMessage.value = t('account:new_subscription:error')
    closeModal()
}

function closeModal () {
    showModal.value = false
}

function getRequiredLabel (key) {
    return DOMPurify.sanitize(`${t(key)} <span class="RequiredFieldAsterisk" aria-label="${t('form:required_field')}">*</span>`, { ADD_TAGS: ['span'], ADD_ATTR: ['class', 'aria-label'] })
}

function reloadPage () {
    location.reload()
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.UserAccountThirdPartySubscriptionModal {
    &-fieldset {
        padding: $s4;
        border: 1px solid $input-borderColor;
    }

    &-dateField :deep(.v-field) {
        max-width: 170px;
    }

    &-dateField :deep(.v-field.v-field--variant-outlined) {
        max-width: 194px;
    }
}
</style>
