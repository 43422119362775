<template>
    <i
        :alt
        :aria-hidden="ariaHidden"
        :class="'Icon' + name"
        class="IcomoonIcon"
    >
        <span
            v-for="index in layersCount"
            :key="index"
            :class="'path' + index"
            class="IcomoonIcon-path"
        />
    </i>
</template>

<script>
export default {
    props: {
        alt: {
            type: String,
            default: ''
        },
        ariaHidden: {
            type: Boolean,
            default: true
        },
        layersCount: {
            type: Number,
            default: 0
        },
        name: {
            type: String,
            required: true
        }
    }
}
</script>
