<template>
    <component
        :is="'li'"
        class="OrderItem"
        @click="showOrderDetails"
    >
        <div class="OrderItem-header">
            <div class="OrderItem-headerLeft">
                <div class="OrderItem-titleAndDate">
                    <div class="OrderItem-title">
                        {{ order.getTitle() }}
                    </div>
                    <div class="OrderItem-date">
                        {{ getOrderDate }}
                    </div>
                </div>
                <v-chip
                    :disabled="true"
                    class="OrderItem-status rounded-xl font-weight-bold opacity-100 hidden-xs"
                    color="secondary"
                >
                    {{ order.getStatus() }}
                </v-chip>
            </div>
            <formatted-fare
                :fare="order.totalAmountIncludingTaxes"
                :currency="order?.currency"
                :size="'XSmall'"
                class="OrderItem-total"
            />
        </div>
        <v-chip
            :disabled="true"
            class="OrderItem-status rounded-xl font-weight-bold opacity-100 hidden-sm-and-up"
            color="secondary"
        >
            {{ order.getStatus() }}
        </v-chip>
    </component>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import { isEmpty } from 'global-utils'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import moment from 'moment'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const props = defineProps({
    order: {
        type: Object,
        required: true
    },
    orderPage: {
        type: Number,
        required: true
    }
})

const getOrderDate = computed(() => {
    const date = !isEmpty(props.order.purchaseDate) ? props.order.purchaseDate : props.order.creationDate
    return moment(date).format(t('date_format:litteral_day_month_year'))
})

const showOrderDetails = () => {
    const selectedOrderOptions = { order: props.order, orderPage: props.orderPage }
    store.commit(userMutationTypes.SET_SELECTED_ORDER, selectedOrderOptions)
    router.push({ name: 'orderDetails', params: { id: props.order.id } })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OrderItem {
    display: flex;
    flex-direction: column;
    gap: $s3;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px 10px 14px;
    border-radius: 6px;
    box-shadow: $box-shadow-light;
    background: $color-lightest;
    cursor: pointer;
    color: $color-lighterText;

    &:hover,
    &:active {
        background: $color-lightgray1;
    }

    &:last-child {
        margin-bottom: 0;
    }

    :deep(.v-chip__content) {
        font-size: $font-xsmall !important;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            margin-top: -2px;
            font-size: $font-small !important;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: $s5;
    }

    &-headerLeft {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: $s5;
        overflow: hidden;
    }

    &-titleAndDate {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }

    &-title {
        font-size: $font-smedium;
        font-weight: $fontWeight-defaultBold;
        color: $color-defaultText;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            font-size: $font-medium;
        }
    }

    &-date {
        font-size: $font-xsmall;
        color: $color-lighterText;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            font-size: $font-small;
        }
    }

    &-free,
    &-total {
        color: $color-defaultText;
    }

    &-status {
        align-self: flex-start;
        flex-shrink: 1;
    }
}
</style>
