<template>
    <div class="SepaPayment">
        <h2 class="SepaPayment-title">
            {{ $t('bank_account_information') }}
        </h2>
        <template v-if="!isEmptyBankAccount">
            <ul class="SepaPayment-list">
                <li
                    v-for="(item, index) in filteredBankAccounts"
                    :key="'bank-account-list_' + index"
                    :class="isSelectedBankAccount(item) ? 'SepaPayment-listItem--checked' : ''"
                    class="SepaPayment-listItem"
                    @click.prevent="updateValue(item)"
                >
                    <label
                        :for="item.providerBankAccountIdentifier"
                        class="SepaPayment-listItemLabel"
                    >
                        <span class="SepaPayment-listItemInputWrapper inputWrapper">
                            <input
                                :id="item.providerBankAccountIdentifier"
                                v-model="selectedBankAccount"
                                :aria-checked="isSelectedBankAccount(item)"
                                :value="item.activeMandate.uniqueMandateReference"
                                class="SepaPayment-listItemInput"
                                role="radio"
                                tabindex="0"
                                type="radio"
                            >
                        </span>
                        <icomoon-icon
                            class="SepaPayment-listItemIcon"
                            name="Bank--outlined"
                        />
                        <span class="SepaPayment-listItemInfo">
                            <span class="SepaPayment-listItemIban">
                                <span class="SepaPayment-listItemIbanTitle">{{ $t('IBAN') }}</span>
                                <span class="SepaPayment-listItemIbanBank">{{ item.bankName }}</span>
                                <span class="SepaPayment-listItemIbanNumber">{{ item.iban }}</span>
                            </span>
                            <span class="SepaPayment-listItemOwner">
                                <span class="SepaPayment-listItemOwnerTitle">{{ $t('Owner_name') }}</span>
                                <span class="SepaPayment-listItemOwnerInfo">
                                    {{ currentProviderUserFirstName }} {{ currentProviderUserLastName }}
                                </span>
                            </span>
                        </span>
                    </label>
                </li>
            </ul>
            <v-btn
                class="bg-primary"
                size="small"
                @click.prevent="submitPayment"
            >
                {{ buttonLabel }}
            </v-btn>
        </template>
        <router-link
            :to="{ name: 'paymentMeans' }"
            class="SepaPayment-addBankAccount"
        >
            {{ $t('button:create_bank_account') }}
        </router-link>
    </div>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { getCartConfirmPayload } from '@/StoreWeb/js/composables/cart-utils'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    buttonLabel: {
        type: String,
        default: 'confirm_order_sepa_payment_button'
    },
    selectedPaymentMode: {
        type: String,
        required: true
    }
})

const store = useStore()

const filteredBankAccounts = ref([])
const currentProvider = ref(null)
const currentProviderUserExternalId = ref(null)
const currentProviderUserId = ref(null)
const currentProviderUserFirstName = ref(null)
const currentProviderUserLastName = ref(null)
const selectedBankAccount = ref(null)

const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const bankAccounts = computed(() => store.state.userModule.bankAccounts)
const cart = computed(() => store.state.cartModule.cart)

const addressNormalizer = AddressNormalizer.getInstance()
const isEmptyBankAccount = computed(() => isEmpty(filteredBankAccounts.value))

onMounted(() => {
    if (!isEmpty(getWalletProviderAccounts.value) && isEmpty(filteredBankAccounts.value)) {
        getBankAccounts()
    }

    emitter.on('walletLoaded', getBankAccounts)
    emitter.on('getBankAccountsSuccess', () => {
        getFilteredBankAccounts()
        getDefaultSelectedBankAccount()
    })
})

onUnmounted(() => {
    emitter.off('walletLoaded', getBankAccounts)
    emitter.off('getBankAccountsSuccess', () => {
        getFilteredBankAccounts()
        getDefaultSelectedBankAccount()
    })
})

function getBankAccounts () {
    getProviderAccounts()
    if (currentProvider.value && currentProviderUserId.value) {
        store.dispatch(paymentActionTypes.GET_BANK_ACCOUNTS, {
            providerId: currentProvider.value,
            providerUserExternalId: currentProviderUserExternalId.value,
            providerUserId: currentProviderUserId.value,
            eligibleForPayment: true
        })
    }
}

function getProviderAccounts () {
    if (!isEmpty(getWalletProviderAccounts.value)) {
        const currentProviderAccount = getWalletProviderAccounts.value[0]
        currentProvider.value = currentProviderAccount.provider
        currentProviderUserId.value = currentProviderAccount.id
        currentProviderUserExternalId.value = currentProviderAccount.providerUserExternalId
        currentProviderUserFirstName.value = currentProviderAccount.getFirstName()
        currentProviderUserLastName.value = currentProviderAccount.getLastName()
    }
}

function getFilteredBankAccounts () {
    if (!isEmpty(currentProvider.value) && !isEmpty(currentProviderUserId.value) && !isEmpty(bankAccounts.value)) {
        filteredBankAccounts.value = bankAccounts.value[currentProvider.value][currentProviderUserId.value].filter((bank) => {
            const mandate = bank?.mandates.find((mandate) => mandate.status === 'A')
            if (!isEmpty(mandate)) {
                bank.activeMandate = mandate
                return true
            }
            return false
        })
    }
}

function getDefaultSelectedBankAccount () {
    if (!isEmpty(filteredBankAccounts.value)) {
        selectedBankAccount.value = filteredBankAccounts.value[0].activeMandate.uniqueMandateReference
    }
}

function isSelectedBankAccount (mandate) {
    return selectedBankAccount.value === mandate.activeMandate.uniqueMandateReference
}

function updateValue (newValue) {
    if (newValue !== selectedBankAccount.value) {
        selectedBankAccount.value = newValue.activeMandate.uniqueMandateReference
    }
}

async function submitPayment () {
    try {
        const paymentInitPayload = {
            paymentInfos: {
                paymentMode: props.selectedPaymentMode,
                amount: cart.value.totalAmountIncludingTaxes,
                uniqueMandateReference: selectedBankAccount.value
            }
        }
        const cartConfirmPayload = getCartConfirmPayload(normalizeAddressFields)

        if (!cartConfirmPayload) {
            return
        }

        await store.dispatch(cartActionTypes.CART_CONFIRMATION, { ...cartConfirmPayload, ...paymentInitPayload })
        await store.dispatch(paymentActionTypes.PAYMENT_INIT, paymentInitPayload)
    } catch (error) {
        ErrorManager.displayErrorModal('cart:get_cart_confirmation', error)
    }
}

function normalizeAddressFields (payload) {
    const fields = ['billingAddress', 'deliveryAddress', 'deliveryClickAndCollectAddress']
    fields.forEach(field => {
        if (payload[field]) {
            payload[field] = addressNormalizer.normalize(payload[field])
        }
    })
}

</script>

<style lang="scss" scoped>
@import 'globalScss';

.SepaPayment {
    margin: 0;
    text-align: center;

    &-title {
        @extend %h2;

        margin-bottom: $margin-small;
        text-align: center;
    }

    &-list {
        width: 100%;
        margin: 0 0 $margin-medium 0;
        padding: 0;
        list-style: none;
        text-align: left;
    }

    &-listItem {
        padding: $padding-small 0 $padding-small 0;
        border-bottom: 1px solid $color-lightgray2;

        &:last-child {
            border: 0;
        }
    }

    &-listItemIban {
        flex: 1 1 0;
    }

    &-listItemOwner {
        flex: 1 1 0;
    }

    &-listItemLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color-lighterText;

        @media (max-width: $desktop-breakpoint) {
            justify-content: flex-start;
        }
    }

    &-listItemInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 1;
        padding: $padding-xxsmall $padding-xxsmall;

        @media (max-width: $tablet-breakpoint) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-listItemIcon {
        font-size: $font-xxlarge;
    }

    &-listItemIbanTitle,
    &-listItemOwnerTitle,
    &-listItemIbanBank {
        display: block;
        padding-bottom: $padding-xxxsmall;
        font-weight: bold;
    }

    &-listItemOwnerTitle {
        @media (max-width: $tablet-breakpoint) {
            padding-top: $padding-xsmall;
        }
    }

    &-listItemIbanTitle,
    &-listItemIbanNumber {
        font-size: $font-xxsmall;
    }

    &-listItemInput {
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 0;
        height: 0;
    }

    &-listItemInputWrapper {
        position: relative;
        overflow: hidden;
        width: 17px;
        height: 17px;
        margin-right: 5px;
        border: solid 1px $color-lightgray3;
        border-radius: 17px;
        transition: border-color .2s;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 6px;
            margin-top: -3px;
            margin-left: -3px;
            border-radius: 5px;
            background: $color-brandPrimary;
            opacity: 0;
            transition: opacity .2s;
        }
    }

    &-listItem--checked {
        .inputWrapper {
            border-color: $color-brandPrimary;

            &::after {
                opacity: 1;
            }
        }
    }

    &-confirmButton {
        margin: auto;
    }

    &-addBankAccount {
        display: inline-block;
        margin: $margin-small;
        text-decoration: underline;
    }
}
</style>
