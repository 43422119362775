<template>
    <div class="PaymentModeListContent">
        <div
            v-for="(item, index) in paymentModesAvailable"
            :key="'payment_mode_list_content_' + index"
            class="PaymentModeListContent-item"
        >
            <div
                v-show="isSelectedPaymentMode(item.id)"
                class="PaymentModeListContent-itemContainer"
            >
                <div class="PaymentModeListContent-paymentModeAvailable">
                    <card-payment
                        v-if="isCardPaymentMode(item.id)"
                        :buttonLabel="getButtonLabel"
                    />
                    <sepa-payment
                        v-if="isSepaPaymentMode(item.id)"
                        :selected-payment-mode="selectedPaymentMode"
                        :buttonLabel="getSepaButtonLabel"
                    />
                    <recurring-sepa-payment
                        v-if="isRecurringSepaPaymentMode(item.id)"
                        :selected-payment-mode="selectedPaymentMode"
                        :buttonLabel="getSepaButtonLabel"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as paymentMutationTypes from '@/StoreWeb/store/modules/payment/mutation-types'
import CardPayment from '@/StoreWeb/components/payment/CardPayment'
import Cart from '@/StoreWeb/models/cart/Cart'
import SepaPayment from '@/StoreWeb/components/payment/SepaPayment'
import emitter from 'global-emitter'
import RecurringSepaPayment from '@/StoreWeb/components/payment/RecurringSepaPayment.vue'
import i18n from 'i18n'
import { removeCartEmailCookie } from '@/StoreWeb/js/mixins/wallet-utils'

export default {
    components: {
        CardPayment,
        RecurringSepaPayment,
        SepaPayment
    },
    props: {
        paymentModesAvailable: {
            type: Array,
            required: true
        },
        selectedPaymentMode: {
            type: String,
            required: true
        }
    },
    computed: {
        getButtonLabel () {
            return i18n.global.t('confirm_order_card_payment_button')
        },
        getSepaButtonLabel () {
            return i18n.global.t('confirm_order_sepa_payment_button')
        }
    },
    mounted () {
        emitter.on('paymentInitSuccess', this.paymentInitSuccess)
        emitter.on('paymentInitFailure', this.paymentInitFailure)
    },
    unmounted () {
        emitter.off('paymentInitSuccess', this.paymentInitSuccess)
        emitter.off('paymentInitFailure', this.paymentInitFailure)
    },
    methods: {
        isSelectedPaymentMode (mode) {
            return mode === this.selectedPaymentMode
        },
        isCardPaymentMode (mode) {
            return mode === 'CREDITCARD'
        },
        isSepaPaymentMode (mode) {
            return mode === 'DIRECTSEPA' || mode === 'SEPA'
        },
        isRecurringSepaPaymentMode (mode) {
            return mode === 'RECURRINGSEPA'
        },
        paymentInitSuccess () {
            removeCartEmailCookie()
            this.$store.commit(cartMutationTypes.SET_CART, new Cart())
            this.$store.commit(paymentMutationTypes.SET_PAYMENT, null)
            this.$router.push({ name: 'orderSuccess' })
        },
        paymentInitFailure () {
            this.$router.push({ name: 'paymentError' })
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.PaymentModeListContent {
    min-height: 165px;
    margin-top: 0;
    background-color: $color-lightgray1;

    &-loaderContainer {
        padding: 50px;
    }

    &-loader {
        margin: auto;
    }

    &-itemContainer {
        width: 100%;
        max-width: 1095px;
        min-height: 100px;
        margin: 0 auto;
        padding: 25px;
        font-size: $font-xsmall;
        color: $color-lighterText;
    }
}
</style>
