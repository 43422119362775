import * as mutationTypes from './mutation-types'
import cloneDeep from 'lodash.clonedeep'

export default {
    [mutationTypes.SET_BANK_ACCOUNTS] (state, bankAccounts) {
        state.bankAccounts = cloneDeep(bankAccounts)
    },
    [mutationTypes.SET_FARE_MEDIA_PRODUCT] (state, fareMediaProduct) {
        state.fareMediaProduct = fareMediaProduct
    },
    [mutationTypes.SET_FUTURE_DIRECT_DEBITS] (state, futureDirectDebits) {
        state.futureDirectDebits = futureDirectDebits
    },
    [mutationTypes.SET_PAST_DIRECT_DEBITS] (state, pastDirectDebits) {
        state.pastDirectDebits = pastDirectDebits
    },
    [mutationTypes.SET_INVOICES] (state, invoices) {
        state.invoices = invoices
    },
    [mutationTypes.SET_IS_AUTHENTICATED] (state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated
    },
    [mutationTypes.SET_IS_LOGGING_OUT] (state, isLoggingOut) {
        state.isLoggingOut = isLoggingOut
    },
    [mutationTypes.SET_KAS_CONFIGURATION_SETTINGS] (state, kasConfigurationSettings) {
        state.kasConfigurationSettings = kasConfigurationSettings
    },
    [mutationTypes.SET_NOTIFICATIONS] (state, notifications) {
        state.notifications = notifications
    },
    [mutationTypes.SET_ORDERS] (state, orders) {
        state.orders = orders
    },
    [mutationTypes.SET_PERSONAL_INFO] (state, personalInfo) {
        state.personalInfo = personalInfo
    },
    [mutationTypes.SET_RAW_USER_INFOS] (state, rawUserInfos) {
        state.rawUserInfos = rawUserInfos
    },
    [mutationTypes.SET_SELECTED_SUPPORT] (state, selectedFareMedia) {
        state.selectedFareMedia = cloneDeep(selectedFareMedia)
    },
    [mutationTypes.SET_SELECTED_ORDER] (state, options) {
        state.selectedOrder = options?.order || null
        state.orderPage = options?.orderPage || null
    },
    [mutationTypes.SET_SHOW_ACCOUNT_NAV] (state, showAccountNav) {
        state.showAccountNav = showAccountNav
    },
    [mutationTypes.SET_SHOW_NOTIFICATIONS] (state, showNotifications) {
        state.showNotifications = showNotifications
    },
    [mutationTypes.SET_USER_INFOS] (state, userInfos) {
        state.userInfos = userInfos
    },
    [mutationTypes.SET_USER_PENDING_REQUEST] (state, pendingRequest) {
        if (state.userPendingRequests[pendingRequest.key] !== undefined) {
            state.userPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_WAITING_FOR_ACTION_FARE_MEDIA] (state, waitingForActionFareMedia) {
        state.waitingForActionFareMedia = waitingForActionFareMedia
    },
    [mutationTypes.SET_WALLET] (state, wallet) {
        state.wallet = wallet
    },
    [mutationTypes.SET_CERTIFICATE_DOWNLOAD_ERROR] (state, error) {
        state.downloadFareMediaCertificateError = error
    }
}
