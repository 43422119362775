import CatalogProductDocument from '@/StoreWeb/models/catalog/CatalogProductDocument'
import IdPatternNormalizer from '@/StoreWeb/normalizers/patterns/IdPatternNormalizerSingleton'
import { isEmpty } from 'global-utils'
import { convertToOctets } from '@/StoreWeb/managers/FileManager'

export default class {
    constructor () {
        this._idPatternNormalizer = IdPatternNormalizer.getInstance()
    }

    get idPatternNormalizer () {
        return this._idPatternNormalizer
    }

    normalize (item) {
        const document = new CatalogProductDocument(item.id)
        document.cropAvailable = item.supportedTypes ? this.isCropAvailable(item.supportedTypes) : false
        document.cropEnabled = item.cropEnabled ?? false
        document.description = item.description ?? ''
        document.externalId = item.externalId ?? ''
        if (!isEmpty(item.size) && !isEmpty(item.unit)) {
            const { size, unit } = item
            document.maxSize = convertToOctets(size, unit)
        }
        if (!isEmpty(item.supportedTypes)) {
            document.mimeTypes = this.setMimeTypes(item.supportedTypes)
        }
        document.name = item.name
        if (!isEmpty(item.provider)) {
            document.provider = this.idPatternNormalizer.normalize(item.provider)
        }
        document.supportedTypes = item.supportedTypes ?? []

        return document
    }

    isCropAvailable (supportedTypes) {
        const count = supportedTypes.length
        let i
        for (i = 0; i < count; i++) {
            if (['BMP', 'GIF', 'JPG', 'JPEG', 'PNG', 'TIF', 'TIFF', 'WEBP'].find(type => type === supportedTypes[i])) {
                return true
            }
        }
        return false
    }

    normalizeArray (items) {
        const normalizedItems = []

        if (!isEmpty(items)) {
            items.forEach(item => {
                normalizedItems.push(this.normalize(item))
            })
        }

        return normalizedItems
    }

    setMimeTypes (supportedTypes) {
        const mimeTypes = []
        supportedTypes.forEach(supportedType => {
            switch (supportedType) {
                case 'BMP':
                    mimeTypes.push('image/bmp')
                    break
                case 'GIF':
                    mimeTypes.push('image/gif')
                    break
                case 'JPEG':
                case 'JPG':
                    mimeTypes.push('image/jpeg')
                    break
                case 'PDF':
                    mimeTypes.push('application/pdf')
                    break
                case 'PNG':
                    mimeTypes.push('image/png')
                    break
                case 'TIF':
                case 'TIFF':
                    mimeTypes.push('image/tiff')
                    break
                case 'WEBP':
                    mimeTypes.push('image/webp')
                    break
            }
        })
        return mimeTypes
    }
}
