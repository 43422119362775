<template lang="html">
    <div class="AdjacentZonesForm">
        <v-select
            ref="zoneList"
            v-model="currentZone"
            :items="formatedZones"
            :label="$t('add_to_cart_flow:adjacent_zones:select_a_zone')"
            :loading="showZonesLoader"
            :no-data-text="$t('add_to_cart_flow:adjacent_zones:no_other_available_zones')"
            :variant="getVariant"
            class="AdjacentZonesForm-select"
            @update:model-value="onZonesChanged"
        />
        <v-alert
            v-if="showZonesLoader"
            color="info"
            density="compact"
            prominent
            variant="tonal"
            class="AdjacentZonesForm-loadingMessage mb-6"
        >
            {{ $t('add_to_cart_flow:adjacent_zones:loading_zones') }}
        </v-alert>
        <template v-if="selectedZones.length > 0">
            <p class="my-0 font-weight-bold">
                {{ $t('add_to_cart_flow:adjacent_zones:selected_zones') }}
            </p>
            <div class="AdjacentZonesForm-selectedZones d-flex justify-space-between align-center mb-5 py-1 px-3 rounded-lg">
                <v-chip-group column>
                    <v-chip
                        v-for="zone in selectedZones"
                        :key="zone.value"
                        color="primary"
                    >
                        {{ zone.title }}
                    </v-chip>
                </v-chip-group>
                <v-btn
                    :title="$t('add_to_cart_flow:adjacent_zones:reset_button_text')"
                    class="rounded-circle"
                    icon="mdi-close"
                    size="x-small"
                    variant="text"
                    @click="onClearSelectedZones"
                />
            </div>
        </template>
    </div>
</template>

<script setup>
import {
    computed,
    onMounted,
    ref
} from 'vue'
import { useStore } from 'vuex'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { isEmpty } from 'global-utils'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    productParameter: {
        type: Object,
        required: true
    }
})

const emit = defineEmits(['updateAdjacentZonesForm'])

const store = useStore()
const addToCartFlowManager = AddToCartFlowManager.getInstance()
const { getVariant } = useTheme()

const currentZone = ref(null)
const zoneList = ref(null)
const saleOptionStep = ref(null)
const selectedZones = ref([])
const showZonesLoader = ref(false)
const zones = ref([])

const formatedZones = computed(() => getFormattedZones(zones.value))

const getFormattedZones = (items) => {
    const formatedItems = []

    if (!isEmpty(items)) {
        items.forEach(item => {
            formatedItems.push({
                title: item.label,
                value: item.value
            })
        })
    }

    return formatedItems
}

const onClearSelectedZones = () => {
    currentZone.value = null
    selectedZones.value = []
    zones.value = []
    updateProductParameter(false)
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    getZones()
}

const zonesListLoadedHandler = (data) => {
    showZonesLoader.value = false
    zones.value = data.productParameter.values
}

const updateProductParameter = (isQuotationRequired = true) => {
    const productParameterValues = []

    productParameterValues.push({
        productParameter: props.productParameter.id,
        code: 'VALUE',
        value: selectedZones.value.map(zone => zone.value).join(';')
    })

    emit('updateAdjacentZonesForm', {
        id: props.productParameter.id,
        isProductParameterValid: true,
        productParameterValues,
        isQuotationRequired
    })
}

const onZonesChanged = (selectedZone) => {
    let isQuotationRequired = true
    zoneList.value.blur()

    if (selectedZone) {
        selectedZones.value = [...selectedZones.value]
        const correspondingZone = zones.value.find(zone => zone.value === selectedZone)

        if (correspondingZone) {
            selectedZones.value.push({
                title: correspondingZone.label,
                value: correspondingZone.value
            })
        }
    } else {
        zones.value = []
        selectedZones.value = []
        isQuotationRequired = false
    }

    currentZone.value = null
    updateProductParameter(isQuotationRequired)
    getZones()
}

const getZones = () => {
    showZonesLoader.value = true
    const body = {
        product: props.product,
        productParameter: props.productParameter,
        saleOptionStep: saleOptionStep.value
    }
    if (!isEmpty(selectedZones.value)) {
        body.currentProductParameterOptions = {
            subParameters: [
                {
                    productParameter: props.productParameter.id,
                    code: 'VALUE',
                    value: selectedZones.value.map(zone => zone.value).join(';')
                }
            ]
        }
    }
    store.dispatch(catalogActionTypes.GET_LIST_BY_PARAMETER_NAME, body)
        .then((data) => {
            zonesListLoadedHandler(data)
        })
}

onMounted(() => {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)

    const saleOptionSteps = addToCartFlowManager.findInPreviousSteps({
        type: 'saleOption'
    }); // Adding a semi colon ";" at this line's end to use decomposition
    [saleOptionStep.value] = saleOptionSteps

    getZones()
})

</script>

<style lang='scss' scoped>
@import 'globalScss';

.AdjacentZonesForm {
    &-select {
        width: 100%;
    }

    &-selectedZones {
        background: $color-grey-blue;
    }
}
</style>
