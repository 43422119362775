import i18n from 'i18n'

export default class {
    constructor () {
        this._creditorId = ''
        this._identifier = ''
        this._label = ''
        this._signingDate = ''
        this._status = ''
        this._uniqueMandateReference = ''
    }

    get creditorId () {
        return this._creditorId
    }

    set creditorId (creditorId) {
        this._creditorId = creditorId
    }

    get identifier () {
        return this._identifier
    }

    set identifier (identifier) {
        this._identifier = identifier
    }

    get label () {
        return this._label
    }

    set label (label) {
        this._label = label
    }

    get signingDate () {
        return this._signingDate
    }

    set signingDate (signingDate) {
        this._signingDate = signingDate
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get uniqueMandateReference () {
        return this._uniqueMandateReference
    }

    set uniqueMandateReference (uniqueMandateReference) {
        this._uniqueMandateReference = uniqueMandateReference
    }

    getStatus () {
        const labelKey = `sepa_mandate:status:${this.status}`
        return i18n.global.t(labelKey) !== labelKey ? i18n.global.t(labelKey) : this.status
    }

    toJSON () {
        return {
            creditorId: this.creditorId,
            identifier: this.identifier,
            label: this.label,
            signingDate: this.signingDate,
            status: this.status,
            uniqueMandateReference: this.uniqueMandateReference
        }
    }
}
