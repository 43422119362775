<template>
    <div class="TermsAndConsents">
        <label
            :for="forId"
            class="TermsAndConsents-container"
        >
            <span class="TermsAndConsents-content">
                <input
                    :id="forId"
                    :checked="isChecked"
                    :name="name"
                    :readonly="isReadonly"
                    :required="isRequired"
                    alt="checkbox"
                    class="TermsAndConsents-input"
                    type="checkbox"
                    @change="checkboxChange"
                >
                <icomoon-icon
                    name="Check"
                    class="TermsAndConsents-icon"
                />
            </span>
            <span
                class="TermsAndConsents-label"
                v-text="label"
            />
        </label>
    </div>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { onMounted, ref } from 'vue'

const emit = defineEmits(['TermsAndConsentsApprovalChanged'])

const props = defineProps({
    forId: {
        type: String,
        required: true
    },
    isChecked: {
        type: Boolean,
        default: false
    },
    isReadonly: {
        type: Boolean,
        default: false
    },
    isRequired: {
        type: Boolean,
        default: true
    },
    label: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: true
    }
})

const isChecked = ref(props.isChecked)

function checkboxChange (event) {
    isChecked.value = event.target.checked
    emit('TermsAndConsentsApprovalChanged', event.target.checked)
}

onMounted(() => {
    emit('TermsAndConsentsApprovalChanged', isChecked.value)
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.TermsAndConsents {
    &-container {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
    }

    &-content {
        position: relative;
        overflow: hidden;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
        margin-right: 10px;
        border: solid 1px $color-lightgray3;
        border-radius: $borderRadius-small;
        background-color: $color-lightest;
    }

    &-icon {
        font-size: $font-smedium;
        color: $color-brandPrimary;
        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &-input {
        position: absolute;
        top: 0;
        left: -20px;

        &:checked ~ .TermsAndConsents-icon {
            opacity: 1;
        }
    }

    &-label {
        flex-grow: 1;
        font-size: $font-xsmall;
    }
}
</style>
