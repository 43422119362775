/* eslint-disable no-unused-vars */
/* eslint-enable no-unused-vars */
import * as pdfjsLib from 'pdfjs-dist'

async function processFile (file, document) {
    if (file.size <= document.maxSize) {
        return document
    }

    switch (file.type) {
        case 'application/pdf':
            return await processPDF(file, document)
        case 'image/jpeg':
        case 'image/png':
            return await processImage(file, document)
        default:
            throw new Error(`Unsupported file type: ${file.type}`)
    }
}

async function processPDF (pdfFile, document) {
    const jpgDataUrl = await pdfToJpgMultiplePagesWithDynamicScale(pdfFile)
    return await compressDataUrl(jpgDataUrl, document, 'image/jpeg', pdfFile.name)
}

async function processImage (imageFile, document) {
    const imageDataUrl = await toBase64(imageFile)
    return await compressDataUrl(imageDataUrl, document, imageFile.type, imageFile.name)
}

async function toBase64 (file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

async function pdfToJpgMultiplePagesWithDynamicScale (pdfFile, targetDPI = 300) {
    // Set the location of the PDF.js worker script to process PDF files
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.mjs'

    // Read the PDF file as an ArrayBuffer and convert it to Uint8Array for PDF.js processing
    const data = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsArrayBuffer(pdfFile)
    })

    const pdfData = new Uint8Array(data)
    const pdf = await pdfjsLib.getDocument(pdfData).promise

    let totalHeight = 0; let maxWidth = 0
    const pagesRendered = []

    // Process each page of the PDF, render it to a canvas, and track dimensions
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const { canvas, height, width } = await renderPage(pdf, pageNum, targetDPI)
        pagesRendered.push(canvas)
        totalHeight += height
        maxWidth = Math.max(maxWidth, width)
    }
    // Combine all the rendered canvases into one final canvas and return as a data URL
    return combineCanvases(pagesRendered, maxWidth, totalHeight)
}

async function renderPage (pdf, pageNum, dpi) {
    const page = await pdf.getPage(pageNum)
    const scale = dpi / 72 // Convert target DPI to scale relative to 72 DPI
    const viewport = page.getViewport({ scale })
    const canvas = document.createElement('canvas')
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport
    }
    await page.render(renderContext).promise
    return { canvas, height: viewport.height, width: viewport.width }
}

function combineCanvases (pagesRendered, maxWidth, totalHeight) {
    const finalCanvas = document.createElement('canvas')
    finalCanvas.width = maxWidth
    finalCanvas.height = totalHeight
    const finalContext = finalCanvas.getContext('2d')

    let currentHeight = 0
    pagesRendered.forEach(canvas => {
        finalContext.drawImage(canvas, 0, currentHeight, canvas.width, canvas.height)
        currentHeight += canvas.height
    })

    return finalCanvas.toDataURL('image/jpeg', 1)
}

async function compressDataUrl (dataUrl, document, mimeType, fileName) {
    let quality = 0.9
    let currentDataUrl = dataUrl
    let finalSize = 0
    let attempts = 0 // Limit to prevent potential infinite loop
    const maxAttempts = 20 // Maximum number of iterations to attempt

    while (attempts < maxAttempts) {
        const response = await fetch(currentDataUrl)
        const blob = await response.blob()
        finalSize = blob.size

        // Check if the size is within the target or if the quality is too low
        if (finalSize <= document.maxSize || quality <= 0.07) {
            break
        }

        // Reduce the quality and prepare for the next iteration
        quality *= 0.75
        currentDataUrl = await adjustImageQuality(currentDataUrl, quality, mimeType)
        attempts++ // Increment the attempt counter
    }

    // Handle file name change from PDF to JPG if necessary
    const newFileName = fileName.replace(/\.pdf$/i, '.jpg')
    document.fileObject = createFile(currentDataUrl, newFileName, mimeType)
    document.base64String = currentDataUrl
    // Return both the final base64 string and the file object
    return structuredClone(document)
}

function createFile (currentDataUrl, newFileName, mimeType) {
    const arr = currentDataUrl.split(',')
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }

    return new File([u8arr], newFileName, { type: mimeType })
}
async function adjustImageQuality (dataUrl, quality, mimeType) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            const originalWidth = img.width
            const originalHeight = img.height

            const maxDimension = 800

            const scale = Math.min(maxDimension / originalWidth, maxDimension / originalHeight)

            const newWidth = Math.round(originalWidth * scale)
            const newHeight = Math.round(originalHeight * scale)

            canvas.width = newWidth
            canvas.height = newHeight

            ctx.drawImage(img, 0, 0, newWidth, newHeight)

            resolve(canvas.toDataURL(mimeType, quality))
        }

        img.onerror = reject
        img.src = dataUrl
    })
}

export { processFile }
