<template>
    <v-card class="PaymentDeliveryCard">
        <div
            v-if="preDescription"
            class="PaymentDeliveryCard-preDescritionWrapper"
        >
            <span
                class="PaymentDeliveryCard-preDescrition"
                v-html="$sanitize(getPreDescription)"
            />
            <span class="PaymentDeliveryCard-or">{{ $t('miscellaneous:or') }}</span>
        </div>
        <div
            :class="`PaymentDeliveryCard-imageContainer--${env}`"
            class="PaymentDeliveryCard-imageContainer"
        >
            <img
                class="PaymentDeliveryCard-image"
                :src="$versionedUrl(require(`@/StoreWeb/assets/img/${env}/${icon}.svg`))"
                :alt="icon"
            >
        </div>
        <span class="PaymentDeliveryCard-title h1">{{ $t(title) }}</span>
        <span
            class="PaymentDeliveryCard-description"
            v-html="$sanitize(getDescription)"
        />
        <v-btn
            color="primary"
            size="large"
            @click="openCardReader()"
        >
            {{ $t(buttonLabel) }}
        </v-btn>
        <span
            v-if="tip && $te(tip)"
            class="PaymentDeliveryCard-tip"
            v-html="$sanitize($t(tip))"
        />
    </v-card>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const emit = defineEmits(['openSupportReaderModal'])

const props = defineProps({
    buttonLabel: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    id: {
        type: Number,
        required: true
    },
    preDescription: {
        type: String,
        default: ''
    },
    tip: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        required: true
    }
})

const env = ref(process.env.VUE_APP_NETWORK_ID)

const getDescription = computed(() => t(props.description).replace(/\\n/g, '<br>'))
const getPreDescription = computed(() => t(props.preDescription).replace(/\\n/g, '<br>'))

function openCardReader () {
    emit('openSupportReaderModal')
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentDeliveryCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s6;
    padding: $s8 $s4;
    border-radius: map-get($rounded, 'xxl');
    box-shadow: $boxShadow-card;
    background: transparent;
    text-align: center;

    &--57 {
        background: $color-white;
    }

    &--120 {
        border-radius: $borderRadius-xlarge;
        background: $backgroundColor-greyGradient;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding: $s8;
    }

    &-preDescritionWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: $s10;
        margin-bottom: $s4;
    }

    &-preDescrition {
        font-weight: $fontWeight-defaultBold;
    }

    &-or {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: $s2;
        font-size: $font-large;
        text-transform: uppercase;

        &::before,
        &::after {
            content: '';
            width: 100px;
            height: 1px;
            background-color: $color-grey;
        }
    }

    &-imageContainer {
        width: 250px;
        max-height: 180px;
        margin-bottom: $s4;
        padding: $s4;

        &--120 {
            border-radius: 60px;
            box-shadow: rgb(187, 187, 187, .24) 10px 10px 4px 0;
            background-color: rgba(249, 250, 255, 1);
        }
    }

    &-image {
        width: 100%;
        height: 100%;
    }

    &-title {
        font-size: $font-large;
        font-weight: 600;
        line-height: $font-xlarge;
    }

    &-description {
        font-size: $font-small;
        line-height: $font-medium;
    }

    &-tip {
        margin-top: $s6;
        font-size: $font-xsmall;
        line-height: $font-small;
    }
}
</style>

<style lang='scss'>
/* stylelint-disable */
// Case really special where we need to bypass the a css style

@import 'globalScss';

.PaymentDeliveryCard-link {
    color: $color-brandPrimary;
    transition: color .3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        color: $color-brandPrimaryDark;
    }
}
/* stylelint-enable */
</style>
