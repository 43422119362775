<template>
    <div class="PersonalData">
        <div
            v-if="userPendingRequests.getConsents"
            class="PersonalData-loader"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            />
        </div>
        <v-alert
            v-else-if="errorMessage"
            color="error"
            density="compact"
            prominent
            :text="errorMessage"
            variant="tonal"
        />
        <v-alert
            v-else-if="sections.length === 0"
            color="info"
            density="compact"
            prominent
            :text="$t('consents:info:get_consents_empty')"
            variant="tonal"
        />
        <div
            v-for="(section, index) in sections"
            v-else
            :key="`section_${index}`"
            class="PersonalData-section"
        >
            <template v-if="section.consents?.length">
                <div
                    class="PersonalData-title"
                    v-text="$t(`consents:category:${section.category}`)"
                />
                <div
                    v-for="(consent, index2) in section.consents"
                    :key="`section_${index}_consent_${index2}`"
                >
                    <template v-if="consent.type === 'BOOLEAN'">
                        <v-switch
                            v-model="consent.accepted"
                            :label="consent.content"
                            color="primary"
                            @update:model-value="updateConsents(consent)"
                        />
                    </template>
                    <template v-if="consent.type === 'LIST' && consent?.channels?.length">
                        <span
                            class="PersonalData-channelsLabel"
                            v-text="`${$t('consents:list:channels')}:`"
                        />
                        <v-checkbox
                            v-for="(channel, index3) in consent.channels"
                            :key="`section_${index}_consent_${index2}_channel_${index3}`"
                            v-model="channel.accepted"
                            :label="channel.content"
                            hide-details
                            color="primary"
                            @update:model-value="updateConsents(consent)"
                        />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import cloneDeep from 'lodash.clonedeep'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const pageConfigManager = PageConfigManager.getInstance()

const store = useStore()
const { t } = useI18n()

const errorMessage = ref(null)
const sections = ref([])

const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)

function updateConsents (consent) {
    const consentToUpdate = cloneDeep(consent)

    // Needs to reformate the channels data when LIST
    if (consentToUpdate.type === 'LIST') {
        consentToUpdate.channels = consentToUpdate.channels
            .filter(channel => channel.accepted)
            .map(channel => channel.content)
    }

    store.dispatch(userActionTypes.SET_CONSENTS, {
        consents: [consentToUpdate]
    })
}

onMounted(() => {
    store.dispatch(userActionTypes.GET_CONSENTS, {
        type: 'account'
    }).then((response) => {
        sections.value = response
    }).catch(() => {
        errorMessage.value = t('consents:error:get_consents')
    })

    pageConfigManager.setConfig({
        title: 'account:personal_data:title',
        currentAccountPage: 'personalData'
    })
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.PersonalData {
    max-width: 750px;

    &-loader {
        width: 100%;
        text-align: center;
    }

    &-section {
        margin-bottom: $s4;
        border-bottom: 1px solid #e3e4e4;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    &-title {
        @extend %h2;
    }
}
</style>
