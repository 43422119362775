<template>
    <component
        :is="getLayout"
        class="StoreWeb"
    >
        <router-view />
    </component>
    <reset-cart-modal v-model="showConfirmResetCart" />
    <add-account-modal
        v-if="!isEmpty(addAccountModalTemplate)"
        v-model="isAddAccountModalShown"
        :addAccountModalTemplate="addAccountModalTemplate"
        @account-finalized="accountFinalized"
        @close-modal="closeAccountModal"
    />
</template>

<script setup>
import AddAccountModal from '@/StoreWeb/components/account/AddAccountModal.vue'
import ResetCartModal from '@/StoreWeb/components/common/cart/ResetCartModal.vue'
import { updateSession } from '@/StoreWeb/js/composables/application-session-utils'
import { loadCart } from '@/StoreWeb/js/composables/cart-utils'
import { useCatalogUrlFilters } from '@/StoreWeb/js/composables/catalog-filters'
import {
    getIsAuthenticated,
    updateAuthenticatedStatusAfterLogin,
    updateUserInfosAfterLogin,
    useLogin
} from '@/StoreWeb/js/composables/login-utils'
import { useMspAccount } from '@/StoreWeb/js/composables/msp-account-utils'
import { addTarteAuCitronScript, customizeAppName, customizeFaviconTags } from '@/StoreWeb/js/head-customization'
import { checkAndUpdateIsMobileApp, getApplicationSessionIdFromLocalStorage } from '@/StoreWeb/js/mixins/application-session-utils'
import { getOsName } from '@/StoreWeb/js/mixins/device-state-utils'
import { cancelInactivityEvents, initInactivityEvents } from '@/StoreWeb/js/mixins/inactivity-utils'
import { getSelectedFareMediaCookie, removeCartEmailCookie, removeSelectedFareMediaCookie, scheduleRefreshSelectedSupport } from '@/StoreWeb/js/mixins/wallet-utils'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import * as catalogMutationTypes from '@/StoreWeb/store/modules/catalog/mutation-types'
import * as actionTypes from '@/StoreWeb/store/modules/main/action-types'
import * as mutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import cachios from 'cachios'
import config from 'config'
import emitter from 'global-emitter'
import { isEmpty } from 'global-utils'
import LoginManager from 'user/Login/managers/LoginManager'
import * as loginActionTypes from 'user/Login/store/action-types'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const { addAccountModalTemplate, isAddAccountModalShown, listenAddAccountEvent } = useMspAccount()
const defaultLayout = 'default'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { userInfos } = useLogin()
const { t } = useI18n()
const { resetFilters } = useCatalogUrlFilters()

const debuggingManager = ref(DebuggingManager.getInstance())
const showConfirmResetCart = ref(false)
const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const cart = computed(() => store.state.cartModule.cart)
const getCart = computed(() => store.getters.getCart)
const isMobileApp = computed(() => store.state.isMobileApp)
const getLayout = computed(() => (route.meta.layout || defaultLayout) + '-layout')
// see with Mr Dewil waiting to have a answer from PM team to be sure to remove this
// const productToPutOnCard = computed(() => store.state.catalogModule.productToPutOnCard)
const productToAddOnceAccountFinalized = computed(() => store.state.catalogModule.productToAddOnceAccountFinalized)
const wallet = computed(() => store.state.userModule.wallet)

function onLoggedIn (hasToRemoveSelectedFareMediaCookie = true) {
    removeCartEmailCookie()
    cancelInactivityEvents()
    applicationSession.value.setUserToken(store.state.login.userToken)
    store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)

    if (hasToRemoveSelectedFareMediaCookie) {
        removeSelectedFareMediaCookie()
    }

    if (isEmpty(userInfos.value)) {
        return
    }

    onUserInfosLoaded()
}

function closeAccountModal () {
    emitter.emit('hideProductModal')
}

function onUserInfosLoaded () {
    switch (config.userweb.kas_user_infos_enrichment_method) {
        case 'sytral':
            store.dispatch(userActionTypes.GET_PERSONAL_INFO)
            break
    }

    if (
        isEmpty(cart.value) ||
        (
            cart.value?.userId &&
            userInfos.value?.externalUserId !== cart.value.userId
        )
    ) {
        loadCart()
    }
}

function loggedIn () {
    store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

    updateAuthenticatedStatusAfterLogin()
    updateUserInfosAfterLogin()

    if (config.userweb.login_mode === 'kas' && route.name.indexOf('signedIn') !== -1) {
        const redirectTo = LoginManager.getLocalStorageItem('redirectTo')
        if (!isEmpty(redirectTo)) {
            localStorage.removeItem('redirectTo')
            if (typeof redirectTo === 'string') {
                window.location.href = redirectTo
            } else {
                router.push(redirectTo)
            }
        } else {
            router.push({ name: 'home' })
        }
    }

    onLoggedIn()
}

function logOut () {
    if (config.web_harmony.header) {
        removeSelectedFareMediaCookie()
        document.body.dispatchEvent(new CustomEvent('kas-logout'))
    } else {
        removeSelectedFareMediaCookie()
        store.dispatch(`login/${loginActionTypes.LOGOUT}`)
    }
}

function loggedOut () {
    store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
    removeSelectedFareMediaCookie()
    window.location.href = router.resolve({ name: 'home' }).href
}

async function selectedSupportChanged (params) {
    // In anonymous mode we clean the current cart if the support has changed
    if (!getIsAuthenticated()) {
        const selectedFareMediaCookie = getSelectedFareMediaCookie()
        const selectedSupport = localStorage.getItem('selectedSupport')

        if ((!isEmpty(selectedFareMediaCookie) && !isEmpty(selectedSupport) && selectedSupport !== selectedFareMediaCookie.fareMediaId) || params?.forceClearCart) {
            store.commit(cartMutationTypes.SET_ADD_TO_CART_EMAIL, '')
            removeCartEmailCookie()

            if (!isEmpty(getCart.value) && !getCart.value.empty) {
                await store.dispatch(cartActionTypes.DELETE_CART, {
                    id: getCart.value.id
                })
                if (isEmpty(params) || !params?.forceClearCart) {
                    showConfirmResetCart.value = true
                }
            }
        }
        if (!isEmpty(selectedFareMediaCookie)) {
            initInactivityEvents()
            localStorage.setItem('selectedSupport', selectedFareMediaCookie.fareMediaId)
        } else {
            cancelInactivityEvents()
        }
    }
    // TODO by now we decided to keep an easiest flow to avoid troubles
    /*
        if (productToPutOnCard.value) {
        emitter.emit('showProductModal', productToPutOnCard.value)
        store.commit(catalogMutationTypes.SET_PRODUCT_TO_PUT_ON_CARD, null)
    } */

    if (params?.resetFilters) {
        resetFilters()
    }

    if (params?.withoutGetCatalog) return
    await store.dispatch(catalogActionTypes.GET_CATALOG)
}

function accountFinalized () {
    if (productToAddOnceAccountFinalized.value) {
        emitter.emit('showProductModal', productToAddOnceAccountFinalized.value)
        store.commit(catalogMutationTypes.SET_PRODUCT_TO_ADD_ONCE_ACCOUNT_FINALIZED, null)
    }
}

function checkAccountError (error) {
    const params = {
        debugObject: error.data,
        messageHTML: t('check_account:error:message'),
        title: t('check_account:error:title')
    }

    if (error.status === 401) {
        params.actions = [
            {
                label: t('check_account:error:action_label'),
                onClick: () => {
                    if (!config.web_harmony.header) {
                        LoginManager.refreshTokenAction({
                            callback: () => {
                                LoginManager.clearStoredInfos()
                                LoginManager.startLogInProcess(route)
                            }
                        })
                    }
                }
            },
            {
                label: t('button:logout'),
                onClick: () => {
                    emitter.emit('logOut')
                }
            }
        ]
    }
    emitter.emit('showAlertModal', params)
}

function showFareMediaLoading (fareMedia) {
    router.push({ name: 'fareMediaLoading', params: { providerId: fareMedia.providerId, providerUserId: fareMedia.providerUserId, providerUserExternalId: fareMedia.providerUserExternalId, id: fareMedia.id } })
}

function enteringGuestPurchaseMode () {
    store.commit(cartMutationTypes.SET_IS_GUEST_PURCHASE_FLOW, true)
    router.push({ name: 'cart' })
}

function onUserTokenRefreshed () {
    if (isMobileApp.value) {
        applicationSession.value.setUserToken(store.state.login.userToken)
        updateSession(applicationSession.value)
    }
}

watch(() => route, (to) => {
    document.body.classList.remove('body--withModalOpened')
    store.commit(userMutationTypes.SET_SHOW_ACCOUNT_NAV, false)

    if (typeof window._paq !== 'undefined') {
        window._paq.push(['setCustomUrl', to.path])
        window._paq.push(['trackPageView', to.name])
    }
})

watch(() => userInfos, (newValue) => {
    if (!isEmpty(newValue) && isEmpty(wallet.value)) {
        onUserInfosLoaded()
    }
})

onBeforeMount(() => {
    if (config.web_harmony.header) {
        document.body.classList.add('body--isUsingWebHarmony')
    }

    document.body.addEventListener('login-widget-ready', () => {
        store.commit(mutationTypes.SET_IS_LOGIN_MODULE_LOADED, true)

        document.body.dispatchEvent(new CustomEvent('kas-load-configuration-settings'))
    })

    document.body.addEventListener('kas-login', () => {
        removeSelectedFareMediaCookie()
    })

    document.body.addEventListener('kas-configuration-settings-loaded', (event) => {
        const kasConfigurationSettings = event.detail.kasConfigurationSettings

        if (kasConfigurationSettings) {
            store.commit(userMutationTypes.SET_KAS_CONFIGURATION_SETTINGS, kasConfigurationSettings)
        }
    })

    document.body.addEventListener('kas-user-infos-loaded', () => {
        updateUserInfosAfterLogin()
    })

    document.body.addEventListener('kas-token-refreshed', (event) => {
        const accessToken = event.detail.accessToken

        if (accessToken) {
            cachios.axiosInstance.defaults.headers.common.Authorization = accessToken
        }
    })

    document.body.addEventListener('kas-logout', () => {
        removeSelectedFareMediaCookie()
        store.commit(userMutationTypes.SET_IS_LOGGING_OUT, true)
        router.push({ name: 'home' })
    })

    document.body.addEventListener('kas-logged-out', () => {
        store.commit(userMutationTypes.SET_IS_LOGGING_OUT, false)
    })
})

onMounted(async () => {
    if (checkAndUpdateIsMobileApp()) {
        const applicationSessionId = getApplicationSessionIdFromLocalStorage()

        if (applicationSessionId && isEmpty(applicationSession.value?.id)) {
            await store.dispatch(applicationSessionActionTypes.READ_APPLICATION_SESSION, {
                id: applicationSessionId
            })
        }
    }

    const os = getOsName()

    if (!isEmpty(os)) {
        store.commit(mutationTypes.SET_IS_MOBILE_OR_TABLET, ['ios', 'android'].includes(os.toLowerCase()))
    }

    if (!config.web_harmony.header) {
        LoginManager.loadConfig()
    }
    customizeAppName()
    customizeFaviconTags()
    scheduleRefreshSelectedSupport()

    if (
        process.env.VUE_APP_ENV &&
        process.env.VUE_APP_ENV === 'local' &&
        process.env.VUE_APP_SIMULATE_DURABLE_LOCAL_STORAGE
    ) {
        debuggingManager.value.simulateAuthenticatedUser()
        debuggingManager.value.enableAllMocks()
    }

    emitter.on('loggedIn', loggedIn)
    emitter.on('logOut', logOut)
    emitter.on('loggedOut', loggedOut)
    emitter.on('selectedSupportChanged', selectedSupportChanged)
    emitter.on('checkAccountError', checkAccountError)
    emitter.on('showFareMediaLoading', showFareMediaLoading)
    emitter.on('enteringGuestPurchaseMode', enteringGuestPurchaseMode)
    emitter.on('userTokenRefreshed', onUserTokenRefreshed)

    listenAddAccountEvent()

    if (
        !isMobileApp.value &&
        (!process.env.VUE_APP_ENV || (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV !== 'local')) &&
        !isEmpty(config?.tarteaucitron?.uuid)
    ) {
        const domain = !isEmpty(config?.tarteaucitron.domain) ? config.tarteaucitron.domain : window.location.host
        addTarteAuCitronScript(domain, config.tarteaucitron.uuid)
    }

    await store.dispatch(actionTypes.INIT)

    if (getIsAuthenticated()) {
        const hasToRemoveSelectedFareMediaCookie = false
        onLoggedIn(hasToRemoveSelectedFareMediaCookie)
    } else {
        initInactivityEvents()
    }
})

</script>

<style lang='scss' scoped>
@import 'globalScss';

.StoreWeb {
    @extend %baseLayout;

    &-body {
        @extend %baseLayoutBody;
    }
}
</style>
