<template>
    <div
        :class="{ 'QuantitySelector--noTitle': title === '' }"
        class="QuantitySelector"
    >
        <h2
            v-if="title !== ''"
            class="QuantitySelector-title"
        >
            {{ title }}
        </h2>
        <div class="QuantitySelector-quantityEditorWrapper">
            <div class="QuantitySelector-quantityEditor">
                <a
                    class="QuantitySelector-button QuantitySelector-less"
                    @click="decrement"
                >
                    <icomoon-icon
                        class="QuantitySelector-buttonIcon"
                        name="Less"
                    />
                </a>
                <input
                    v-model="quantity"
                    :min="min"
                    :max="max"
                    class="QuantitySelector-value"
                    type="text"
                    @change="onChangeQuantity"
                >
                <a
                    class="QuantitySelector-button QuantitySelector-more"
                    @click="increment"
                >
                    <icomoon-icon
                        class="QuantitySelector-buttonIcon"
                        name="More"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { ref, onMounted } from 'vue'
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'

const emit = defineEmits(['updateQuantity'])

const props = defineProps({
    max: {
        type: Number,
        default: 0
    },
    min: {
        type: Number,
        default: 1
    },
    title: {
        type: String,
        default: ''
    }
})

const quantity = ref(0)
const addToCartFlowManager = AddToCartFlowManager.getInstance()

onMounted(() => {
    if (
        !isEmpty(addToCartFlowManager.currentStep) &&
        !isEmpty(addToCartFlowManager.currentStep.selectedQuantity)
    ) {
        quantity.value = addToCartFlowManager.currentStep.selectedQuantity
    } else {
        quantity.value = props.min
    }
    emit('updateQuantity', quantity.value)
})

const decrement = () => {
    if (quantity.value > props.min) {
        quantity.value--
        emitUpdateQuantity()
    }
}

const increment = () => {
    if (quantity.value < props.max || props.max === 0) {
        quantity.value++
        emitUpdateQuantity()
    }
}

const onChangeQuantity = () => {
    const qty = parseInt(quantity.value.trim())
    if (isNaN(qty)) {
        quantity.value = props.min
    } else if (props.min <= qty && (props.max === 0 || props.max >= qty)) {
        quantity.value = qty
    } else {
        quantity.value = props.min
    }
    emitUpdateQuantity()
}

const emitUpdateQuantity = () => {
    emit('updateQuantity', quantity.value)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.QuantitySelector {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-quantityEditor {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 12px 30px;
        border-radius: $border-radius-small;
        background: $color-lightgray1;

        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
        }
    }

    &-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background: $color-brandPrimary;
        cursor: pointer;
        color: $color-lightest;
        transition: all .3s;

        &:hover,
        &:active {
            background: $color-brandPrimaryDark;
        }
    }

    &-buttonIcon {
        font-size: 24px;
    }

    &-value {
        width: 30px;
        height: 30px;
        margin: 0 12px;
        padding: 0 2px;
        border: none;
        border-radius: $border-radius-xsmall;
        background: none;
        font-size: 24px;
        font-weight: $fontWeight-defaultBold;
        text-align: center;
        color: $color-lightText;
        transition: all .3s;

        &:hover,
        &:active {
            background: $color-lightest;
        }
    }

    &--noTitle {
        .QuantitySelector-quantityEditorWrapper {
            padding-top: 0;
        }
    }
}

.CartPackageItem-quantity,
.CartItem-quantity {
    &.QuantitySelector {
        padding: 0;
        background: none;
    }

    .QuantitySelector {
        &-button {
            width: 20px;
            height: 20px;
            border-radius: 20px;
        }

        &-buttonIcon {
            font-size: 20px;
        }

        &-value {
            width: 24px;
            height: 20px;
            margin: 0 4px;
            font-size: 16px;
        }
    }
}
</style>
