<template>
    <div class="FineRegularization">
        <v-form
            v-if="fineProductId !== ''"
            @submit.prevent="submitFineForm"
        >
            <v-text-field
                v-model="form.fineReference"
                :error-messages="v$.fineReference.$errors.map(e => e.$message)"
                :label="t('form:fine:reference:label')"
                :variant="getVariant"
                @update:model-value="v$.fineReference.$touch()"
            />
            <v-text-field
                v-model="form.fineToken"
                :error-messages="v$.fineToken.$errors.map(e => e.$message)"
                :label="t('form:fine_form1:token:label')"
                :variant="getVariant"
                type="datetime-local"
                @update:model-value="v$.fineToken.$touch()"
            />
            <fine-form-buttons
                :display-back-button="displayBackButton"
                @click-on-back-button="clickOnBackButton"
            />
        </v-form>
        <v-alert
            v-else
            type="error"
        >
            {{ t('fine:regularization:unavailable_message') }}
        </v-alert>
    </div>
</template>

<script setup>
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import config from 'config'
import FineFormButtons from '@/StoreWeb/components/fine/subcomponent/FineFormButtons'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required, regex } from '@/StoreWeb/utils/i18n-validators'
import { loadCart } from '@/StoreWeb/js/composables/cart-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'

const store = useStore()
const { t } = useI18n()
const { isAuthenticated } = useLogin()
const { getVariant } = useTheme()

const fineEmail = ref('')
const props = defineProps({
    isAnonymousMode: {
        type: Boolean,
        default: false
    },
    email: {
        type: String,
        default: ''
    }
})

const initialState = {
    fineReference: '',
    fineToken: ''
}
const form = reactive({
    ...initialState
})

const rules = {
    fineReference: { required, maxLength: maxLength(30) },
    fineToken: { required, regex: regex(/^(\d{4})-(0\d|1[0-2])-([0-2]\d|3[0-1])(T([0-1]\d|2[0-3])(:[0-5]\d))*$/) }
}

const v$ = useVuelidate(rules, form)

const route = useRoute()

const emit = defineEmits(['clickOnBackButtonFromFineRegularization'])

const displayBackButton = computed(() => !isAuthenticated.value)
const fineProductId = computed(() => {
    const providerSupportingFineRegularization = config.providers.find(provider => provider?.fine?.id)

    if (providerSupportingFineRegularization) {
        return providerSupportingFineRegularization.fine.id
    }

    return ''
})
const cart = computed(() => store.state.cartModule.cart)
const wallet = computed(() => store.getters.getWallet)

onMounted(async () => {
    if (props.email) fineEmail.value = props.email
    if (!cart.value.id || !isAuthenticated.value) {
        let params = null
        if (!isAuthenticated.value) {
            params = { email: fineEmail.value }
        }

        await loadCart(params)
    }
})

async function submitFineForm () {
    const isValid = await v$.value.$validate()
    if (isValid) {
        await addToCart()
    }
}

async function addToCart () {
    let providerUserId = null
    if (isAuthenticated && wallet.value) {
        for (let i = 0; i < wallet.value.providers.length; i++) {
            if (wallet.value.providers[i].id === route.params.provider) {
                providerUserId = wallet.value.providers[i].accounts[0].id
            }
        }
    }

    const cartBody = {
        orderId: cart.value.id,
        items: [
            {
                type: 'MODALPRODUCT',
                id: fineProductId.value,
                version: 1,
                saleableUnit: 'NONE',
                quantity: 1,
                parameters: [
                    {
                        productParameter: 'FINEFORM1',
                        code: 'FINE_ID',
                        value: form.fineReference
                    },
                    {
                        productParameter: 'FINEFORM1',
                        code: 'FINE_TOKEN',
                        value: `${form.fineToken}:00`
                    }
                ],
                saleOption: {
                    target: 'FINE',
                    deliveryMode: 'EMAIL',
                    basketInfo: 'EMAIL'
                }
            }
        ]
    }

    if (providerUserId !== null) {
        cartBody.items[0].saleOption.deliveryMode = 'POSTAL'
        cartBody.items[0].saleOption.basketInfo = 'PROVIDERACCOUNT'
        cartBody.items[0].saleOption.providerId = route.params.provider
        cartBody.items[0].saleOption.providerUserId = providerUserId
    }

    await store.dispatch(cartActionTypes.ADD_TO_CART, {
        addToCartBody: cartBody,
        itemType: 'FINE'
    })
}

function clickOnBackButton () {
    emit('clickOnBackButtonFromFineRegularization')
}
</script>
