<template>
    <component
        :is="'li'"
        :class="{ 'OfferChoiceItem--active': isActive }"
        class="OfferChoiceItem"
        @click.stop="selectOffer"
    >
        <formatted-fare
            v-if="offer && offer.fare > 0"
            :fare="offer ? offer.fare : ''"
            size="medium"
            class="OfferChoiceItem-fare"
        />
        <strong
            v-else
            class="OfferChoiceItem-free"
        >
            {{ $t('catalog:free') }}
        </strong>
        <span class="OfferChoiceItem-selectedIconWrapper">
            <icomoon-icon
                class="OfferChoiceItem-selectedIcon"
                name="Check"
            />
        </span>
    </component>
</template>

<script setup>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'

const emit = defineEmits(['offerClicked'])
const props = defineProps({
    offer: {
        type: Object,
        required: true
    },
    isActive: {
        type: Boolean,
        required: false
    }

})

const selectOffer = () => {
    emit('offerClicked', props.offer.value)
}
</script>
<style lang="scss" scoped>
@import 'globalScss';

.OfferChoiceItem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    min-width: 133px;
    min-height: 92px;
    border-radius: $border-radius-xsmall;
    box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-lightgray3;
    background: $color-lightest;
    cursor: pointer;
    color: $color-lightText;
    transition: all .5s;

    &-selectedIconWrapper {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        border-top-right-radius: $border-radius-xsmall;
        border-bottom-left-radius: $border-radius-xsmall;
        background: $color-success;
    }

    &-selectedIcon {
        font-size: 16px;
        color: $color-lightest;
    }

    &:hover,
    &:focus {
        box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-gray;
    }

    &--active {
        box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;

        &:hover,
        &:focus {
            box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, .12), inset 0 0 0 3px $color-success;
        }

        .OfferChoiceItem-selectedIconWrapper {
            display: flex;
        }
    }
}
</style>
