<template>
    <component
        :is="'li'"
        class="DirectDebitItem"
    >
        <div class="DirectDebitItem-content">
            <div class="DirectDebitItem-header">
                <p
                    :class="`DirectDebitItem-status--${directDebit.status.id}`"
                    class="DirectDebitItem-status mb-2"
                >
                    {{ getStatus }}
                </p>
                <formatted-fare
                    v-if="getFare > 0"
                    :fare="getFare"
                    :size="'SMedium'"
                    class="DirectDebitItem-total"
                    :class="`DirectDebitItem-total--${directDebit.status.id}`"
                />
            </div>
            <p
                v-if="!isEmpty(getFareMedia) || !isEmpty(getProductLabel)"
                class="DirectDebitItem-fareMedia mb-2"
            >
                <span class="font-weight-bold DirectDebitItem-fareMediaProductLabel">
                    {{ $t('due_date_number_with_details', {
                        id: directDebit.id,
                        details: getProductLabel || getFareMedia ? ` - ${getProductLabel || getFareMedia}` : ''
                    }) }}
                </span>
                <span
                    v-if="!isEmpty(getFareMedia)"
                    class="DirectDebitItem-fareMediaNumber"
                >
                    {{ `-${getFareMedia}` }}
                </span>
            </p>
            <p v-else>
                <span class="font-weight-bold DirectDebitItem-fareMediaProductLabel">
                    {{ $t('due_date_number_with_details', {
                        id: directDebit.id
                    }) }}
                </span>
            </p>
            <div class="text-right">
                <v-btn
                    v-if="directDebit.status.id === 'REJECTED'"
                    class="pl-4 font-weight-bold DirectDebitItem-regularization"
                    color="primary"
                    @click="regularization"
                >
                    <v-icon
                        icon="mdi-alert-circle"
                        class="mr-1"
                    />
                    {{ $t('regularization') }}
                </v-btn>
            </div>
        </div>
    </component>
</template>

<script setup>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import { isEmpty } from 'global-utils'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { useStore } from 'vuex'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'

const store = useStore()
const { t } = useI18n()

const cart = computed(() => store.state.cartModule.cart)
const getFare = computed(() => {
    return props.directDebit.amount.value
})
const getDirectDebitDate = computed(() => {
    const date = props.directDebit.effectiveDate || props.directDebit.date
    return moment(date).format(t('date_format:default'))
})

const getProductLabel = computed(() => {
    const { productLabel } = props.directDebit
    return isEmpty(productLabel) ? '' : productLabel
})

const getFareMedia = computed(() => {
    const { supportOwnerName, providerFareMediaId } = props.directDebit
    let fareMediaLabel = ''

    if (getProductLabel.value && !isEmpty(supportOwnerName)) {
        fareMediaLabel = t('fare_media:on_card_of').replace('%cardOwner%', supportOwnerName)
        if (providerFareMediaId) {
            fareMediaLabel += ` - n°${providerFareMediaId}`
        }
    } else if (!isEmpty(supportOwnerName)) {
        fareMediaLabel = t('fare_media:card_of').replace('%cardOwner%', supportOwnerName)
        if (providerFareMediaId) {
            fareMediaLabel += ` - n°${providerFareMediaId}`
        }
    } else if (!isEmpty(providerFareMediaId)) {
        if (getProductLabel.value) {
            fareMediaLabel += ' - '
        }
        fareMediaLabel += t('fare_media:card_number').replace('%cardNumber%', providerFareMediaId)
    }
    return fareMediaLabel
})

const getStatus = computed(() => {
    const key = `direct_debit:status:${props.directDebit.status.id.toLowerCase()}:label`
    return t(key).replace('%date%', getDirectDebitDate.value)
})

const props = defineProps({
    directDebit: {
        type: Object,
        required: true
    },
    providerUserId: {
        type: String,
        required: true
    },
    providerUserExternalId: {
        type: String,
        required: true
    }
})

const regularization = async () => {
    const body = {
        orderId: cart.value.id,
        items: [
            {
                type: 'MODALPRODUCT',
                id: 'conduent:scheduledpaymentregularisation',
                version: 1,
                saleableUnit: 'NONE',
                quantity: 1,
                saleOption: {
                    target: 'REGULARIZATION',
                    deliveryMode: 'EMAIL',
                    basketInfo: 'PROVIDERACCOUNT',
                    provider: props.directDebit.providerId,
                    providerUserId: props.providerUserId,
                    providerUserExternalId: props.providerUserExternalId,
                    fareMediaId: props.directDebit.providerFareMediaId
                },
                parameters: [
                    {
                        productParameter: 'REGULARIZATION',
                        code: 'SCHEDULEPAYMENTID',
                        value: props.directDebit.id
                    }
                ]
            }
        ]
    }
    await store.dispatch(cartActionTypes.ADD_TO_CART, {
        addToCartBody: body
    })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DirectDebitItem {
    margin: $s4;
    border-radius: $border-radius-medium;
    box-shadow: 0 3px 8px 3px rgba(65, 89, 113, .09);
    background: $color-lightest;
    color: $color-lighterText;

    @media (max-width: $tablet-breakpoint) {
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0;
        box-shadow: none;
    }

    &:last-child {
        margin-bottom: 0;

        @media (max-width: $tablet-breakpoint) {
            border-bottom: 0;
        }
    }

    &-content {
        padding: $padding-small;

        @media (max-width: $tablet-breakpoint) {
            padding: 0 0 $padding-xsmall 0;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: $tablet-breakpoint) {
            display: block;
        }
    }

    &-status {
        display: block;
        font-family: $font-family_title;
        font-size: $font-smedium;
        font-weight: $fontWeight-defaultBold;
        color: $color-black;

        &--NOT_SUBMITTED,
        &--REJECTED,
        &--DELETED {
            color: $color-danger;
        }
    }

    &-free,
    &-total {
        color: $color-defaultText;

        &--NOT_SUBMITTED,
        &--REJECTED,
        &--DELETED {
            color: $color-danger;
        }

        @media (max-width: $tablet-breakpoint) {
            display: block;
            margin-bottom: $margin-small;
        }
    }

    &-fareMedia {
        font-size: $font-xsmall;
        color: $color_light_text;
    }

    &-fareMediaProductLabel {
        @media (max-width: $tablet-breakpoint) {
            display: block;
            margin-top: $margin-small;
        }
    }

    &-fareMediaNumber {
        @media (max-width: $tablet-breakpoint) {
            display: block;
            margin-top: $margin-xxsmall;
        }
    }

    &-regularization {
        @media (max-width: $tablet-breakpoint) {
            margin-top: $margin-xxsmall;
        }
    }
}
</style>
