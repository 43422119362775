import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import { isEmpty } from 'global-utils'
import i18n from 'i18n'

export default class {
    constructor () {
        this._store = null
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    setConfig (params) {
        if (!isEmpty(params.title)) {
            params.title = i18n.global.t(params.title, i18n.locale)
        }
        this.store.commit(mainMutationTypes.SET_PAGE_CONFIG, params)
    }
}
