<template>
    <div class="FileInputFieldWithPicture">
        <label
            :for="`js-file-${fileId}`"
            class="FileInputFieldWithPicture-label"
        >
            <div class="FileInputFieldWithPicture-glimpseWrapper">
                <img
                    v-if="glimpseBase64String !== '' && errorLabel === ''"
                    :src="glimpseBase64String"
                    class="FileInputFieldWithPicture-glimpse"
                    alt=""
                >
                <span class="FileInputFieldWithPicture-glimpseIconWrapper">
                    <icomoon-icon
                        name="Cloud--filled"
                        class="FileInputFieldWithPicture-glimpseIcon"
                    />
                </span>
            </div>
            <div class="FileInputFieldWithPicture-texts">
                <span class="FileInputFieldWithPicture-text">
                    {{ label }}
                </span>
                <span
                    v-if="getFileName === '' && description !== ''"
                    class="FileInputFieldWithPicture-description"
                >({{ description }})</span>
                <span
                    v-if="isFileInstructionsDisplayed && getFileName === '' && getFileInstructions !== ''"
                    class="FileInputFieldWithPicture-fileFormat"
                    v-html="$sanitize(getFileInstructions)"
                />
                <em
                    v-if="getFileName !== ''"
                    class="FileInputFieldWithPicture-fileName"
                >
                    {{ getFileName }}
                    <icomoon-icon
                        name="Edit"
                        class="FileInputFieldWithPicture-icon"
                    />
                </em>
            </div>
        </label>
        <input
            :id="`js-file-${fileId}`"
            ref="referenceFile"
            :aria-label="label"
            :aria-required="isRequired"
            :required="isRequired"
            class="FileInputFieldWithPicture-input"
            type="file"
            tabindex="0"
            autofocus
            @change="fieldValueChanged"
        >
        <div
            v-if="errorLabel !== ''"
            :aria-label="errorLabel"
            class="FileInputFieldWithPicture-error"
        >
            {{ errorLabel }}
        </div>
    </div>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import FileManager from '@/StoreWeb/managers/FileManager'
import config from 'config'
import i18n from 'i18n'
import { computed, ref } from 'vue'

const emit = defineEmits(['fileInputError', 'fieldValueChanged'])

const props = defineProps({
    isRequired: {
        type: Boolean,
        default: true
    },
    description: {
        type: String,
        default: ''
    },
    isFileInstructionsDisplayed: {
        type: Boolean,
        default: true
    },
    fileName: {
        type: String,
        default: ''
    },
    mimeTypes: {
        type: Array,
        default: () => ['image/jpeg', 'image/png', 'application/pdf']
    },
    label: {
        type: String,
        default: 'forms:fileinput:default_label'
    },
    maxSize: {
        type: Number,
        default: 5000000
    },
    fileId: {
        type: [Number, String],
        required: true
    }
})

const errorLabel = ref('')
const file = ref(null)
const referenceFile = ref(null)
const document = ref(null)
const getFileName = computed(() => props.fileName || file?.value?.name || '')
const getFileInstructions = computed(() => FileManager.getFileInstructions(props.mimeTypes, config.features.catalog.file_upload_max_size || props.maxSize))

function checkFileValid () {
    file.value = referenceFile.value?.files[0] || null
    errorLabel.value = ''

    if (!file.value) {
        setError('forms:fileinput:unknown_error')
        return false
    }

    if (!isAuthorizedExtension()) {
        setError(i18n.global.t('forms:fileinput:wrong_file_type'))
        return false
    }

    if (!FileManager.isAuthorizedSize(file.value.size, config.features.catalog.file_upload_max_size || props.maxSize)) {
        setError(FileManager.getFileSizeExceded(file.value.name || props.fileName, file.value.size, config.features.catalog.file_upload_max_size || props.maxSize))
        return false
    }

    clearError()
    return true
}

function setError (message) {
    errorLabel.value = message
    emit('fileInputError', message)
    file.value = null
}

function clearError () {
    errorLabel.value = ''
    emit('fileInputError', false)
}

function fieldValueChanged (event) {
    if (!event.target.files || event.target.files.length === 0 || !checkFileValid()) {
        return
    }
    document.value = {
        id: event.target.id.split('js-file-')[1],
        file: event.target.files[0]
    }
    emit('fieldValueChanged', document.value)
}

function isAuthorizedExtension () {
    return props.mimeTypes.indexOf(file.value.type) !== -1
}

defineExpose({
    setError,
    fileId: props.fileId
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.FileInputFieldWithPicture {
    &-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $s4;
        cursor: pointer;
    }

    &-glimpseWrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 125px;
        height: 125px;
        border: 1px solid $color-lightgray2;
        border-radius: 125px;
        background-color: transparent;
        transition: background-color .3s ease-in-out;
    }

    &-glimpse {
        max-width: 125px;
        max-height: 125px;
    }

    &-glimpseIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &-glimpseIcon {
        font-size: 64px;
        color: $color-lighterText;
        transition: color .3s ease-in-out;
    }

    &-texts {
        text-align: center;
    }

    &-text {
        display: block;
        font-size: $font-xsmall;
        font-weight: $fontWeight-defaultBold;
        color: $color-lightText;
    }

    &-description {
        display: block;
        font-size: $font-xxsmall;
        color: $color-lightText;
    }

    &-fileFormat {
        display: block;
        font-size: $font-xxsmall;
        color: $color-lighterText;
    }

    &-fileName {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-xsmall;
        color: $color-brandPrimary;
    }

    &-icon {
        font-size: $font-medium;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    &-error {
        max-width: 500px;
        margin-top: $s2;
        font-size: $font-xsmall;
        text-align: center;
        color: $color-danger;
    }

    &:hover {
        .FileInputFieldWithPicture {
            &-glimpseWrapper {
                background-color: $color-lighterText;
            }

            &-glimpseIcon {
                color: $color-white;
            }
        }
    }
}
</style>
