<template>
    <ul class="AuthenticationDescriptions">
        <li
            v-for="(hint, index) in hints"
            :key="`key_description_${index}`"
            class="AuthenticationDescriptions-hint"
        >
            <v-icon
                color="success"
                class="AuthenticationDescriptions-check"
            >
                mdi-check
            </v-icon>
            {{ $t(hint) }}
        </li>
    </ul>
</template>

<script setup>

const hints = [
    'user_auth_modal:description_card_info',
    'user_auth_modal:description_billing',
    'user_auth_modal:description_reloading'
]
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AuthenticationDescriptions {
    display: flex;
    flex-direction: column;
    gap: $s6;
    width: 100%;
    padding: $s6;
    border-radius: $s4;
    background-color: #faf6ff;
    list-style-type: none;
    text-align: left;

    &-hint {
        display: flex;
        align-items: center;
    }

    &-check {
        margin-right: $s3;
        font-size: 28px;
    }
}
</style>
