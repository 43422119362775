<template>
    <div class="AddNewProfileModal">
        <v-progress-circular
            v-if="userPendingRequests"
            indeterminate
            color="primary"
        />
        <modal
            v-else-if="fareProfiles.length > 0"
            v-model="showModal"
            :title="$t('add_new_profile:title')"
        >
            <div class="AddNewProfileModal-body">
                <span class="AddNewProfileModal-description">
                    {{ $t('add_new_profile:description') }}
                </span>
                <v-select
                    v-model="selectedProfile"
                    :items="fareProfiles"
                    :variant="getVariant"
                    item-title="name"
                    item-value="id"
                    @update:model-value="fareProfileSelected"
                />
                <div class="AddNewProfileModal-inputs">
                    <file-input-field-with-picture
                        v-for="askedDocument in formattedAskedDocuments"
                        :key="askedDocument.id"
                        :fileId="askedDocument.id"
                        :label="askedDocument.name"
                        :max-size="askedDocument.size"
                        :mime-types="getDocumentMimeTypes(askedDocument.supportedTypes)"
                        :variant="getVariant"
                        @file-input-error="setUploadedFileError"
                        @field-value-changed="documentChanged"
                    />
                </div>
            </div>
            <template #actions>
                <v-btn
                    :disabled="isCheckEvidenceEnabled && isProvidedEvidencesEmpty"
                    color="primary"
                    variant="flat"
                    @click="addNewProfile"
                >
                    {{ $t('next') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import FileInputFieldWithPicture from '@/StoreWeb/components/common/FileInputFieldWithPicture'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'
import { convertToOctets, getMimeTypesExtension } from '@/StoreWeb/managers/FileManager'
import * as actionTypes from '@/StoreWeb/store/modules/main/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { isEmpty } from 'global-utils'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    account: {
        type: Object,
        required: true
    },
    fareProfiles: {
        type: Array,
        required: true
    },
    modelValue: {
        type: Boolean,
        required: true
    },
    providerAccount: {
        type: Object,
        default: null
    }
})

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const { getVariant } = useTheme()

const selectedProfile = ref(null)
const askedDocuments = computed(() => selectedProfile.value?.documents || [])
const formattedAskedDocuments = computed(() => !isEmpty(askedDocuments.value) ? formatDocuments(selectedProfile.value?.documents) : [])
const isCheckEvidenceEnabled = computed(() => selectedProfile.value?.documents?.length > 0)
const isProvidedEvidencesEmpty = computed(() => !uploadedFiles.value?.length)
const uploadedFiles = ref([])
const formError = ref(false)

const getWalletProviderAccounts = computed(() => store.getters.getWalletProviderAccounts)
const getWalletAssociatedAccounts = computed(() => store.getters.getWalletAssociatedAccounts)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests.getNewProfileAccount)
const uploadedFileError = ref(false)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

const getRecipientUser = computed(() => {
    if (props.providerAccount) {
        return {
            associationId: props.providerAccount.id,
            firstName: props.providerAccount.firstName,
            lastName: props.providerAccount.lastName,
            userId: props.providerAccount.userId,
            type: props.providerAccount?.type?.id
        }
    }
    return null
})

onMounted(() => {
    // Search for the profile matching the ID in the route parameters
    const selectedProfileFromRouteParams = props.fareProfiles.find(fareProfile => fareProfile.id === route.params?.profileId)
    // If a matching profile is found, use it. Otherwise, take the first profile from the list.
    selectedProfile.value = selectedProfileFromRouteParams ?? props.fareProfiles[0]
})

async function addNewProfile () {
    if (formError.value !== false) {
        store.commit(mainMutationTypes.SET_FEEDBACK_ERROR, t('profiles:set:error'))
        closeModal()
        return
    }

    try {
        const uploadedFilesPromises = uploadedFiles.value.map(async (uploadedFile) => {
            const params = {
                formData: getFormData(uploadedFile.file),
                id: uploadedFile.id
            }
            const document = await store.dispatch(actionTypes.UPLOAD_DOCUMENT, params)

            return {
                uploadedFileId: document.documentId,
                documentId: uploadedFile.id
            }
        })
        const documents = await Promise.all(uploadedFilesPromises)
        const params = {
            providerId: props.account.provider,
            accountId: props.account.id,
            id: selectedProfile.value?.id,
            documents,
            providerUserExternalId: props.providerAccount.providerUserExternalId,
            providerUserId: props.providerAccount.id
        }

        const associatedProvider = getWalletAssociatedAccounts.value.find(obj => obj.id === props.providerAccount.id)
        const fatherAccount = getWalletProviderAccounts.value[0]

        if (!isEmpty(associatedProvider)) {
            params.recipientUser = getRecipientUser.value
            params.providerUserId = fatherAccount.id
            params.providerUserExternalId = fatherAccount.providerUserExternalId
        }

        await store.dispatch(userActionTypes.SET_NEW_PROFILE_ACCOUNT, params)
        store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, t('add:profile:success'))
        closeModal()
    } catch (error) {
        store.commit(mainMutationTypes.SET_FEEDBACK_ERROR, t('profiles:set:error'))
    }
}

function closeModal () {
    showModal.value = false
    uploadedFiles.value = []
}

function documentChanged (document) {
    if (!document?.id || uploadedFileError.value) {
        uploadedFileError.value = true
        return
    }

    const { file, id: documentId } = document

    const existingFileIndex = uploadedFiles.value.findIndex(fileObj => fileObj.id === documentId)

    if (existingFileIndex !== -1) {
        const existingFileObject = uploadedFiles.value[existingFileIndex]
        if (existingFileObject.file !== file) {
            existingFileObject.file = file
        }
    } else {
        uploadedFiles.value.push({ file, id: documentId })
    }

    uploadedFileError.value = false
}

function fareProfileSelected (value) {
    selectedProfile.value = props.fareProfiles.find((fareProfile) => fareProfile.id === value)
    formError.value = false
    uploadedFiles.value = []
}

function getDocumentMimeTypes (extensions) {
    return getMimeTypesExtension(extensions)
}

function getFormData (file) {
    const formData = new FormData()
    formData.append('file', file, file.name || '')
    return formData
}

function setUploadedFileError (error) {
    formError.value = error !== false
}

function formatDocuments (documents) {
    return documents.map((document) => {
        if (!isEmpty(document.size) && !isEmpty(document.unit)) {
            return {
                ...document,
                size: convertToOctets(document.size, document.unit)
            }
        }
        return document
    })
}

</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddNewProfileModal {
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: $s2;
        width: 100%;
    }

    &-inputs {
        display: grid;
        grid-template-columns: 1fr;
        gap: $s8;
        width: 100%;

        @media (min-width: $tablet-breakpoint) {
            grid-template-columns: repeat(2, 1fr);

            &:has(> :only-of-type) {
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>
