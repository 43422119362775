import i18n from 'i18n'
import config from 'config'

export default class {
    constructor (id) {
        this._addresses = []
        this._baskets = []
        this._counters = []
        this._creationDate = ''
        this._currency = ''
        this._purchaseDate = ''
        this._id = id
        this._idLabel = ''
        this._externalIdLabel = ''
        this._invoice = null
        this._invoices = []
        this._operation = ''
        this._packages = []
        this._payments = []
        this._plans = []
        this._status = null
        this._totalAmountExcludingTaxes = 0
        this._totalAmountIncludingTaxes = 0
        this._totalVatAmount = 0
        this._updateDate = ''
        this._userEmail = ''
        this._userFirstName = ''
        this._userId = ''
        this._userLastName = ''
        this._external = ''
    }

    get addresses () {
        return this._addresses
    }

    set addresses (addresses) {
        this._addresses = addresses
    }

    get baskets () {
        return this._baskets
    }

    set baskets (baskets) {
        this._baskets = baskets
    }

    get currency () {
        return this._currency
    }

    set currency (currency) {
        this._currency = currency
    }

    get counters () {
        return this._counters
    }

    set counters (counters) {
        this._counters = counters
    }

    get creationDate () {
        return this._creationDate
    }

    set creationDate (creationDate) {
        this._creationDate = creationDate
    }

    get purchaseDate () {
        return this._purchaseDate
    }

    set purchaseDate (purchaseDate) {
        this._purchaseDate = purchaseDate
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get idLabel () {
        return this._idLabel
    }

    set idLabel (idLabel) {
        this._idLabel = idLabel
    }

    get externalIdLabel () {
        return this._externalIdLabel
    }

    set externalIdLabel (externalIdLabel) {
        this._externalIdLabel = externalIdLabel
    }

    get invoice () {
        return this._invoice
    }

    set invoice (invoice) {
        this._invoice = invoice
    }

    get invoices () {
        return this._invoices
    }

    set invoices (invoices) {
        this._invoices = invoices
    }

    get operation () {
        return this._operation
    }

    set operation (operation) {
        this._operation = operation
    }

    get packages () {
        return this._packages
    }

    set packages (packages) {
        this._packages = packages
    }

    get payments () {
        return this._payments
    }

    set payments (payments) {
        this._payments = payments
    }

    get plans () {
        return this._plans
    }

    set plans (plans) {
        this._plans = plans
    }

    get status () {
        return this._status
    }

    set status (status) {
        this._status = status
    }

    get totalAmountExcludingTaxes () {
        return this._totalAmountExcludingTaxes
    }

    set totalAmountExcludingTaxes (totalAmountExcludingTaxes) {
        this._totalAmountExcludingTaxes = totalAmountExcludingTaxes
    }

    get totalAmountIncludingTaxes () {
        return this._totalAmountIncludingTaxes
    }

    set totalAmountIncludingTaxes (totalAmountIncludingTaxes) {
        this._totalAmountIncludingTaxes = totalAmountIncludingTaxes
    }

    get totalVatAmount () {
        return this._totalVatAmount
    }

    set totalVatAmount (totalVatAmount) {
        this._totalVatAmount = totalVatAmount
    }

    get updateDate () {
        return this._updateDate
    }

    set updateDate (updateDate) {
        this._updateDate = updateDate
    }

    get userEmail () {
        return this._userEmail
    }

    set userEmail (userEmail) {
        this._userEmail = userEmail
    }

    get userFirstName () {
        return this._userFirstName
    }

    set userFirstName (userFirstName) {
        this._userFirstName = userFirstName
    }

    get userId () {
        return this._userId
    }

    set userId (userId) {
        this._userId = userId
    }

    get userLastName () {
        return this._userLastName
    }

    set userLastName (userLastName) {
        this._userLastName = userLastName
    }

    set external (external) {
        this._external = external
    }

    get external () {
        return this._external
    }

    getStatus () {
        return this.status.label
    }

    getTitle () {
        return i18n.global.t('account:order_details:title').replace('%orderId%', config?.features?.orders?.id_display === 'externalIdLabel' ? this.externalIdLabel : this.idLabel)
    }

    getExternalId () {
        if (this.externalIdLabel) {
            return i18n.global.t('account:order_details:external_id').replace('%orderExternalId%', this.externalIdLabel)
        }

        return ''
    }

    toJSON () {
        return {
            addresses: this.addresses,
            baskets: this.baskets,
            currency: this.currency,
            counters: this.counters,
            creationDate: this.creationDate,
            purchaseDate: this.purchaseDate,
            id: this.id,
            idLabel: this.idLabel,
            invoice: this.invoice,
            invoices: this.invoices,
            operation: this.operation,
            packages: this.packages,
            payments: this.payments,
            plans: this.plans,
            status: this.status,
            totalAmountExcludingTaxes: this.totalAmountExcludingTaxes,
            totalAmountIncludingTaxes: this.totalAmountIncludingTaxes,
            totalVatAmount: this.totalVatAmount,
            updateDate: this.updateDate,
            userEmail: this.userEmail,
            userFirstName: this.userFirstName,
            userId: this.userId,
            userLastName: this.userLastName,
            external: this.external,
            externalIdLabel: this.externalIdLabel
        }
    }
}
