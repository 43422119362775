<template>
    <div class="AccountAssociationAddModal">
        <modal
            v-model="showModal"
            :title="$t('account_association:create:title')"
            :width="'small'"
        >
            <v-alert
                v-if="accountErrorMessage !== null"
                class="AccountAssociationAddModal-alertMessage mb-5"
                color="error"
                density="compact"
                prominent
                :text="accountErrorMessage"
                variant="tonal"
            />
            <div class="AccountAssociationAddModal-body">
                <v-select
                    v-if="getAccountAssociationTypes.length > 1"
                    v-model="selectedType"
                    :items="getAccountAssociationTypes"
                    :label="$t('account_association:chose_type')"
                    :variant="getVariant"
                    class="AccountAssociationAddModal-typeSelector"
                    item-title="name"
                    item-value="id"
                    return-object
                    @update:model-value="updateSelectedType"
                />
                <v-card
                    v-if="getAccountAssociationTypes.length === 1 && accountAssociationRoles.length > 1"
                    class="mb-5"
                    color="secondary"
                    elevation="0"
                    variant="tonal"
                >
                    <v-card-text>
                        <p class="AccountAssociationAddModal-chosenType text-body-1 font-weight-bold">
                            {{ selectedTypeRecap }}
                        </p>
                    </v-card-text>
                </v-card>
                <v-select
                    v-if="accountAssociationRoles.length > 1"
                    v-model="selectedRole"
                    :items="accountAssociationRoles"
                    :label="$t('account_association:chose_role')"
                    :variant="getVariant"
                    class="AccountAssociationAddModal-roleSelector"
                    item-title="label"
                    item-value="id"
                    return-object
                />
                <v-card
                    v-if="accountAssociationRoles.length === 1"
                    class="mb-10"
                    color="secondary"
                    elevation="0"
                    variant="tonal"
                >
                    <v-card-text>
                        <p
                            v-if="getAccountAssociationTypes.length === 1"
                            class="AccountAssociationAddModal-chosenType text-body-1 font-weight-bold"
                        >
                            {{ selectedTypeRecap }}
                        </p>
                        <p class="text-body-2">
                            {{ selectedRoleRecap }}
                        </p>
                    </v-card-text>
                </v-card>
                <v-select
                    v-if="accountAssociationModes.length > 1"
                    v-model="selectedMode"
                    :items="accountAssociationRoles"
                    :label="$t('account_association:chose_mode')"
                    :variant="getVariant"
                    class="AccountAssociationAddModal-roleSelector"
                    item-title="name"
                    item-value="id"
                    return-object
                />
                <account-association-add-last-name-first-name-birthdate-mode-form
                    v-if="selectedMode === 'LASTNAME_FIRSTNAME_BIRTHDATE'"
                    @last-name-first-name-birthdate-form-updated="onLastNameFirstNameBirthdateFormUpdated"
                />
                <vuetify-dynamic-form
                    v-else-if="selectedTemplate"
                    :is-part-of-form="true"
                    :template="selectedTemplate"
                    :variant="getVariant"
                    class="mt-5"
                    form-title-class="h4"
                    @form-updated="onDynamicFormUpdated($event, selectedMode)"
                />
                <WalletAddAccount />
            </div>
            <template #actions>
                <v-btn
                    :disabled="isSubmitDisabled"
                    class="bg-primary ml-2"
                    size="large"
                    @click="createAccountAssociation"
                >
                    {{ $t(('button:create')) }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'global-utils'
import config from 'config'
import VuetifyDynamicForm from '@/StoreWeb/components/form/VuetifyDynamicForm'
import AccountAssociationAddLastNameFirstNameBirthdateModeForm from './AccountAssociationAddLastNameFirstNameBirthdateModeForm.vue'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import FormNormalizer from '@/StoreWeb/normalizers/form/FormNormalizerSingleton'
import * as accountAssociationActionTypes from '@/StoreWeb/store/modules/account-association/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as accountAssociationMutationTypes from '@/StoreWeb/store/modules/account-association/mutation-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import AccountAssociationNormalizer from '@/StoreWeb/normalizers/account-association/AccountAssociationNormalizerSingleton'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()
const accountAssociationNormalizer = AccountAssociationNormalizer.getInstance()
const formNormalizer = FormNormalizer.getInstance()
const { getVariant } = useTheme()

const accountErrorMessage = ref(null)
const key = ref('')
const accountAssociationRoles = ref([])
const selectedProvider = ref('conduent')
const selectedType = ref(null)
const selectedRole = ref({ id: 'fromName' })
const selectedMode = ref(null)
const template = ref(null)

const accountAssociationModes = computed(() => {
    if (!isEmpty(selectedType.value)) {
        return !isEmpty(selectedType.value?.modes) ? selectedType.value.modes : []
    }

    return []
})
const accountAssociationTypes = computed(() => store.state.accountAssociationModule.accountAssociationTypes)
const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const getAccountAssociationTypes = computed(() => {
    const types = []

    accountAssociationTypes.value.forEach(type => {
        type.label = type.name + (debugInformationsDisplay.value > 1 ? ' (type #' + type.id + ')' : '')
        types.push(type)
    })

    return types
})
const isSubmitDisabled = computed(() => !checkFormError())
const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        if (!newValue && !isEmpty(getAccountAssociationTypes.value)) {
            updateSelectedType()
            applySelectedTypeDefaultMode()
        }

        emit('update:modelValue', newValue)
    }
})
const selectedRoleRecap = computed(() => {
    if (accountAssociationRoles.value.length === 1) {
        const [role] = accountAssociationRoles.value

        return `${t('account_association:your_role')} : ${role.label}`
    }

    return ''
})
const selectedTemplate = computed(() => template.value)
const selectedTypeRecap = computed(() => {
    if (getAccountAssociationTypes.value.length === 1) {
        const [type] = getAccountAssociationTypes.value

        return `${t('account_association:type')} : ${type.name}`
    }

    return ''
})
const walletAccount = computed(() => walletAccounts.value.find(account => account.provider === selectedProvider.value))
const walletAccounts = computed(() => store.getters.getWalletProviderAccounts)

function applySelectedTypeDefaultMode () {
    if (!isEmpty(accountAssociationModes.value)) {
        const [firstMode] = accountAssociationModes.value

        selectedMode.value = firstMode
    }
}

function checkFormError () {
    return (
        !isEmpty(walletAccount.value) &&
        !isEmpty(selectedType.value?.id) &&
        !isEmpty(selectedRole.value) &&
        !isEmpty(selectedMode.value) &&
        !isEmpty(key.value)
    )
}

function closeModal () {
    showModal.value = false
}

async function createAccountAssociation () {
    if (checkFormError()) {
        accountErrorMessage.value = null

        try {
            const updatedAccountAssociationList = await store.dispatch(accountAssociationActionTypes.CREATE_ACCOUNT_ASSOCIATION, {
                isMaster: selectedRole.value.id === 'fromName',
                key: key.value,
                mode: selectedMode.value,
                type: selectedType.value.id,
                providerId: selectedProvider.value,
                providerUserId: walletAccount.value.id,
                providerUserExternalId: walletAccount.value.providerUserExternalId,
                cachiosOptions: {
                    force: true
                }
            })

            store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, t('account_association:create:success'))

            if (updatedAccountAssociationList) {
                const normalizedAccountAssociations = accountAssociationNormalizer.normalizeArray(updatedAccountAssociationList.associations)

                store.commit(accountAssociationMutationTypes.SET_USER_ACCOUNT_ASSOCIATIONS, normalizedAccountAssociations)

                await store.dispatch(userActionTypes.GET_WALLET, {
                    wsOptions: {
                        cacheAsync: false,
                        cacheValidity: 0
                    },
                    cachiosOptions: {
                        force: true
                    }
                })
            }

            closeModal()
        } catch (error) {
            accountErrorMessage.value = error.response.data.message
        }
    }
}

function onDynamicFormUpdated (data) {
    if (data.isValid) {
        setKey(data.values)
    } else {
        key.value = ''
    }
}

function selectDefaultType () {
    if (!isEmpty(getAccountAssociationTypes.value)) {
        if (isEmpty(selectedType.value) && !isEmpty(getAccountAssociationTypes.value)) {
            const [firstType] = getAccountAssociationTypes.value
            selectedType.value = firstType

            applySelectedTypeDefaultMode()
        }

        if (isEmpty(accountAssociationRoles.value)) {
            updateRoles()
        }
    }
}

async function selectTemplate (templateCode) {
    const templateUrl = `${process.env.VUE_APP_CDN_URL}${process.env.VUE_APP_NETWORK_ID}/template/account-association/${selectedProvider.value}/template_${templateCode}.json`

    try {
        const response = await fetch(templateUrl)

        if (!response.ok) {
            ErrorManager.displayErrorModal(t('template:error:not_found'), response.status)
            return
        }

        const data = await response.json()

        if (!isEmpty(data)) {
            template.value = formNormalizer.normalize(data)
        } else {
            ErrorManager.displayErrorModal(t('template:error:invalid'), response.status)
        }
    } catch (error) {
        ErrorManager.displayErrorModal(t('template:error:invalid'), error)
    }
}

function setKey (values) {
    switch (selectedMode.value) {
        case 'EMAIL':
            key.value = `${values.email}`
            break
        case 'FAREMEDIAID_BIRTHDATE':
            key.value = `${values.fareMediaId}|${values.birthDate}`
            break
        default:
            key.value = ''
    }
}

function onLastNameFirstNameBirthdateFormUpdated (value) {
    key.value = value
}

function updateRoles () {
    accountAssociationRoles.value = []

    if (!isEmpty(selectedType.value)) {
        const { direction } = config.features.account_association
        const { fromName, toName } = selectedType.value

        if ((direction === 'bidirectional' || direction === 'master_to_slave') && fromName) {
            accountAssociationRoles.value.push({ id: 'fromName', label: fromName })
        }

        if ((direction === 'bidirectional' || direction === 'slave_to_master') && toName) {
            accountAssociationRoles.value.push({ id: 'toName', label: toName })
        }

        const [firstMode] = accountAssociationModes.value
        const [firstRole] = accountAssociationRoles.value

        selectedMode.value = firstMode

        if (firstRole) {
            selectedRole.value = firstRole
        }
    }
}

function updateSelectedType () {
    updateRoles()
}

onMounted(() => {
    selectDefaultType()
})

onUpdated(() => {
    selectDefaultType()
})

watch(() => selectedMode.value, () => {
    if (['EMAIL', 'FAREMEDIAID_BIRTHDATE', 'ACCOUNT', 'LASTNAME_FIRSTNAME_BIRTHDATE'].includes(selectedMode.value)) {
        selectTemplate(selectedMode.value)
    }
})

</script>

<style lang="scss" scoped>
@import 'globalScss';

.AccountAssociationAddModal {
    &-chosenType {
        color: $color-brandSecondary;
    }
}
</style>
