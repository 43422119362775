<template>
    <div class="LogoutConfirmationModal">
        <modal
            v-model="showModal"
            :title="$t('switch_connection_mode:modal:title')"
        >
            {{ $t('switch_connection_mode:modal:subtitle') }}
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="primary"
                    variant="flat"
                    @click="logoutAndOpenCardReader"
                >
                    {{ $t('button:continue') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import emitter from 'global-emitter'
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emit('update:modelValue', newValue)
    }

})

function closeModal () {
    showModal.value = false
}

function logoutAndOpenCardReader () {
    localStorage.setItem('openCardReaderAfterLogout', true)
    emitter.emit('logOut')
}
</script>
