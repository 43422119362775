<template>
    <div class="FareMediaIdModal">
        <modal v-model="showModal">
            <v-alert
                v-if="errorMessage !== null"
                type="error"
            >
                {{ errorMessage }}
            </v-alert>
            <template v-if="!showCheckFareMediaLoader">
                <template v-if="selectedProvider?.id">
                    <template
                        v-if="selectedProvider?.fare_media?.manual_input"
                    >
                        <v-btn
                            v-if="providersSupportingFareMedias.length > 1"
                            class="align-self-start"
                            color="primary"
                            size="small"
                            variant="text"
                            @click="resetSelectedProvider"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                            {{ t('button:back') }}
                        </v-btn>
                        <p class="h3 ma-0">
                            {{ t('fare_media:manually_input') }}
                        </p>
                        <v-text-field
                            v-model="supportId"
                            :label="$t('sale_option:card_number')"
                            :variant="getVariant"
                            class="FareMediaSelector-field align-self-stretch"
                        >
                            <template #append>
                                <v-btn
                                    v-if="showGetFareMediaIdThroughNfcButton"
                                    :title="$t('sale_option:get_fare_media_id_through_nfc_button')"
                                    class="FareMediaSelector-nfcButton v-btn__iconStyle"
                                    color="primary"
                                    elevation="0"
                                    size="large"
                                    variant="outlined"
                                    @click="getFareMediaIdThroughNFC"
                                >
                                    <v-icon>mdi-nfc</v-icon>
                                </v-btn>
                                <v-btn
                                    :disabled="isEmpty(supportId)"
                                    :title="$t('button:validate')"
                                    class="FareMediaSelector-button v-btn__iconStyle"
                                    color="primary"
                                    elevation="0"
                                    size="large"
                                    @click.stop="checkAndApplyFareMedia"
                                >
                                    <icomoon-icon
                                        class="FareMediaSelector-buttonIcon"
                                        name="Check"
                                    />
                                </v-btn>
                            </template>
                        </v-text-field>
                    </template>
                    <template
                        v-if="isCardReaderAvailable && selectedProvider?.fare_media?.reader"
                    >
                        <p
                            v-if="selectedProvider?.fare_media?.manual_input"
                            class="h3 ma-0"
                        >
                            {{ t('miscellaneous:or') }}
                        </p>
                        <v-btn
                            class="rounded-xl"
                            color="primary"
                            elevation="0"
                            @click="useCardReader"
                        >
                            {{ t('card_reader:use_button_text') }}
                        </v-btn>
                    </template>
                </template>
                <template v-else>
                    <p class="h3 ma-0 text-center">
                        {{ t('fare_media:choose_provider') }}
                    </p>
                    <v-btn
                        v-for="provider in providersSupportingFareMedias"
                        :key="provider.id"
                        class="w-100"
                        color="primary"
                        variant="outlined"
                        @click="selectedProvider = provider"
                    >
                        {{ getAddFareMediaButtonText(provider.id) }}
                    </v-btn>
                </template>
            </template>
        </modal>
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="t('card_reader:scan_modal:title')"
            :operationType="'READ'"
            @operation-finished="onOperationFinished"
        />
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { isEmpty } from 'global-utils'
import config from 'config'
import emitter from 'global-emitter'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { useRouter } from 'vue-router'
import { checkAndGetFareMedia } from '@/StoreWeb/js/mixins/faremedia-utils'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const router = useRouter()
const { t } = useI18n()
const { getVariant } = useTheme()

const errorMessage = ref(null)
const selectedProvider = ref(null)
const showSupportReaderModal = ref(false)
const showCheckFareMediaLoader = ref(false)
const supportId = ref('')
const authenticated = computed(() => store.state.login.authenticated)

const showModal = computed({
    get: () => {
        if (props.modelValue) {
            init()
        }

        return (selectedProvider.value?.fare_media?.manual_input ?? false) && props.modelValue
    },
    set: (newValue) => {
        if (!newValue && isEmpty(selectedFareMedia.value)) {
            resetSelectedProvider()
        }

        emit('update:modelValue', newValue)
    }
})

watch(showSupportReaderModal, (newValue) => {
    if (!newValue) {
        showModal.value = false
    }
})

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const isCardReaderAvailable = computed(() => !(isMobileApp.value || isMobileOrTablet.value))
const isMobileApp = computed(() => store.state.isMobileApp)
const isMobileOrTablet = computed(() => store.state.isMobileOrTablet)
const providersSupportingFareMedias = computed(() => {
    if (!isEmpty(config.providers)) {
        const providers = config.providers.filter(provider => {
            return provider?.fare_media?.enabled
        })
        return providers
    }
    return []
})
const showGetFareMediaIdThroughNfcButton = computed(() => {
    return isMobileApp.value &&
        !isEmpty(applicationSession.value) &&
        !isEmpty(applicationSession.value.data) &&
        applicationSession.value.data.isNfcSupported
})

const checkAndApplyFareMedia = async () => {
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { title: t('default_loading:title'), subtitle: t('default_loading:subtitle'), value: true })
    showCheckFareMediaLoader.value = true
    const response = await checkAndGetFareMedia({
        isForConsultation: true,
        providerId: selectedProvider.value.id,
        fareMediaId: supportId.value
    }, false)

    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
    showCheckFareMediaLoader.value = false

    if (response.isValid && response?.fareMedia?.id) {
        errorMessage.value = null
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, response.fareMedia)

        const selectedSupport = {
            providerId: selectedProvider.value.id,
            fareMediaId: response.fareMedia.id
        }
        if (response.fareMedia?.providerUserExternalId) {
            selectedSupport.providerUserExternalId = response.fareMedia.providerUserExternalId
        }
        if (response.fareMedia?.providerUserId) {
            selectedSupport.providerUserId = response.fareMedia.providerUserId
        }
        if (response.fareMedia?.isTemporary) {
            selectedSupport.isTemporary = response.fareMedia.isTemporary
        }

        setSelectedFareMediaCookie(selectedSupport)

        emitter.emit('selectedSupportChanged')
        closeModal()
    } else if (
        selectedProvider.value &&
        (!selectedProvider.value?.fare_media?.manual_input || !selectedProvider.value?.fare_media?.reader)
    ) {
        closeModal()
    }
}

const closeModal = () => {
    showModal.value = false
}

const getAddFareMediaButtonText = (providerId) => {
    return t(`fare_media:${providerId}:read_button_text`)
}

const getFareMediaIdThroughNFC = async () => {
    if (isMobileApp.value) {
        const baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_CATALOG}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.GET_FARE_MEDIA_ID_THROUGH_NFC}`

        applicationSession.value.data.addToCartFlow = localStorage.getItem('addToCartFlow')
        applicationSession.value.sessionContext = {
            currentStep: baseUrl,
            resumeStepSuccess: `${baseUrl}&status=success`,
            resumeStepError: `${baseUrl}&status=error`
        }

        store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
        await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)

        const externalLink = router.resolve({
            name: 'externalLinks',
            params: { action: externalActionTypes.GET_FARE_MEDIA_ID_THROUGH_NFC },
            query: { sessionId: applicationSession.value.id }
        })

        if (!isEmpty(externalLink)) {
            window.location.href = window.location.origin + externalLink.href
        }
    }
}

const init = () => {
    if (providersSupportingFareMedias.value.length === 1) {
        [selectedProvider.value] = providersSupportingFareMedias.value

        if (selectedProvider.value?.fare_media?.reader && !selectedProvider.value?.fare_media?.manual_input) {
            useCardReader()
        }
    }
}

const onOperationFinished = async (data) => {
    if (authenticated.value) {
        await store.dispatch(userActionTypes.GET_WALLET, {
            wsOptions: {
                cacheAsync: false,
                cacheValidity: 0
            },
            cachiosOptions: {
                force: true
            }
        })
    }
    if (data?.support) {
        supportId.value = data.support?.fareMediaId
        await checkAndApplyFareMedia(true)
    }
    showSupportReaderModal.value = false
}

const resetSelectedProvider = () => {
    if (providersSupportingFareMedias.value.length === 1) {
        [selectedProvider.value] = providersSupportingFareMedias.value
    } else {
        selectedProvider.value = null
    }
}

const useCardReader = () => {
    showSupportReaderModal.value = true
}
</script>
