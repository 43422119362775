import * as mutationTypes from './mutation-types'
import cloneDeep from 'lodash.clonedeep'

export default {
    [mutationTypes.SET_ANONYMOUS_PENDING_REQUEST] (state, pendingRequest) {
        if (state.anonymousPendingRequests[pendingRequest.key] !== undefined) {
            state.anonymousPendingRequests[pendingRequest.key] = pendingRequest.value
        }
    },
    [mutationTypes.SET_PLUGGED_SUPPORT_ID] (state, pluggedSupportId) {
        state.pluggedSupportId = pluggedSupportId
    },
    [mutationTypes.SET_SUPPORT_CONTENT] (state, supportContent) {
        state.supportContent = cloneDeep(supportContent)
    }
}
