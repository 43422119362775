<template>
    <div
        v-if="isCardReaderAvailable"
        class="SupportReaderModal"
    >
        <modal
            v-model="showModal"
            :title="headerTitle"
        >
            <support-reader
                :headerTitle="$t('card_reader:scan_modal:title')"
                :operationType="operationType"
                :supportId
                @operation-finished="onOperationFinished"
            />
        </modal>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import i18n from 'i18n'
import SupportReader from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReader'

const emit = defineEmits(['update:modelValue', 'operationFinished'])

const props = defineProps({
    headerTitle: {
        type: String,
        default: i18n.global.t('card_reader:read:title')
    },
    modelValue: {
        type: Boolean,
        required: true
    },
    operationType: {
        type: String,
        default: 'READ',
        validator: (val) => ['READ', 'WRITE'].includes(val)
    },
    shouldAutoCloseModalAfterOperation: {
        type: Boolean,
        default: true
    },
    supportId: {
        type: String,
        default: ''
    }
})

const { isAuthenticated } = useLogin()
const router = useRouter()
const store = useStore()

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emit('update:modelValue', newValue)
    }
})

const isCardReaderAvailable = computed(() => !store.state.isMobileApp && !store.state.isMobileOrTablet)

function onOperationFinished (data) {
    emit('operationFinished', {
        operationReport: data.operationReport,
        support: data.support
    })

    if (data.goToWallet) {
        isAuthenticated.value ? router.push({ name: 'tickets', params: { fareMediaId: data.support.fareMediaId } }) : router.push({ name: 'walletLight', params: data.support })
    }
}
</script>
