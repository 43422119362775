<template>
    <div
        v-if="props.associatedAccounts.length > 0 || config.features.account_association"
        class="AssociatedAccountList"
    >
        <div
            v-if="associatedAccounts"
            class="AssociatedAccountList-block"
        >
            <hr>
            <p class="h2 mt-5">
                {{ $t('account:associated_accounts:title') }}
                <icomoon-icon name="Group" />
            </p>
            <ul class="AssociatedAccountList-list">
                <associated-account-list-item
                    v-for="associatedAccount in associatedAccounts"
                    :key="associatedAccount.id"
                    :associatedAccount="associatedAccount"
                    class="AssociatedAccountList-item"
                    @download-fare-media-certificate-button-clicked="downloadFareMediaCertificateButtonClicked"
                    @suspend-subscription-button-clicked="suspendSubscriptionButtonClicked"
                    @update-fare-media="updateFareMedia"
                />
            </ul>
        </div>
    </div>
</template>

<script setup>
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import config from 'config'
import AssociatedAccountListItem from './AssociatedAccountListItem'

const emit = defineEmits(['downloadFareMediaCertificateButtonClicked', 'suspendSubscriptionButtonClicked', 'updateFareMedia'])

const props = defineProps({
    associatedAccounts: {
        type: Array,
        required: true
    }
})

function downloadFareMediaCertificateButtonClicked (data) {
    emit('downloadFareMediaCertificateButtonClicked', data)
}

function suspendSubscriptionButtonClicked (data) {
    emit('suspendSubscriptionButtonClicked', data)
}

function updateFareMedia (data) {
    emit('updateFareMedia', data)
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AssociatedAccountList {
    &-block {
        margin: 0 0 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-addAccountAssociationButton {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        font-weight: bold;
    }
}
</style>
