<template>
    <div class="EditPhone">
        <v-form @submit.prevent="submit">
            <v-text-field
                v-model="mobilePhoneNumber"
                :error-messages="v$.mobilePhoneNumber.$errors.map(e => e.$message)"
                :label="$t('form:user_infos:mobile_phone:label')"
                :variant="getVariant"
                @update:model-value="v$.mobilePhoneNumber.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        icon="mdi-check"
                        color="success"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        icon="mdi-close"
                        color="error"
                    />
                </template>
            </v-text-field>
            <v-text-field
                v-model="landlinePhoneNumber"
                :error-messages="v$.landlinePhoneNumber.$errors.map(e => e.$message)"
                :label="$t('form:user_infos:landline_phone:label')"
                :variant="getVariant"
                @update:model-value="v$.landlinePhoneNumber.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        color="success"
                        icon="mdi-check"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        color="error"
                        icon="mdi-close"
                    />
                </template>
            </v-text-field>
            <v-text-field
                v-model="deskPhoneNumber"
                :error-messages="v$.deskPhoneNumber.$errors.map(e => e.$message)"
                :label="$t('form:user_infos:desk_phone:label')"
                :variant="getVariant"
                @update:model-value="v$.deskPhoneNumber.$touch()"
            >
                <template #append>
                    <v-icon
                        v-if="submitted && submitSucceed"
                        color="success"
                        icon="mdi-check"
                    />
                    <v-icon
                        v-else-if="submitted && !submitSucceed"
                        color="error"
                        icon="mdi-close"
                    />
                </template>
            </v-text-field>
        </v-form>
    </div>
</template>

<script setup>
import { useTheme } from '@/StoreWeb/js/composables/theme-utils'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { minLength, required } from '@/StoreWeb/utils/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import { isEmpty } from 'global-utils'
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const { getVariant } = useTheme()

const props = defineProps({
    mobilePhoneNumberId: { type: String, default: '', required: true },
    mobilePhoneNumber: { type: String, default: '', required: true },
    landlinePhoneNumberId: { type: String, default: '', required: true },
    landlinePhoneNumber: { type: String, default: '', required: true },
    deskPhoneNumberId: { type: String, default: '', required: true },
    deskPhoneNumber: { type: String, default: '', required: true }
})

watch(
    [() => props.mobilePhoneNumber, () => props.landlinePhoneNumber, () => props.deskPhoneNumber, () => props.mobilePhoneNumberId, () => props.landlinePhoneNumberId, () => props.deskPhoneNumberId],
    ([newMobile, newLandline, newDesk, newMobileId, newLandlineId, newDeskId]) => {
        mobilePhoneNumber.value = newMobile
        originalPhoneValues.value.mobilePhoneNumber = newMobile

        landlinePhoneNumber.value = newLandline
        originalPhoneValues.value.landlinePhoneNumber = newLandline

        deskPhoneNumber.value = newDesk
        originalPhoneValues.value.deskPhoneNumber = newDesk

        mobilePhoneNumberId.value = newMobileId
        landlinePhoneNumberId.value = newLandlineId
        deskPhoneNumberId.value = newDeskId
    }
)

const originalPhoneValues = ref({
    mobilePhoneNumber: props.mobilePhoneNumber,
    landlinePhoneNumber: props.landlinePhoneNumber,
    deskPhoneNumber: props.deskPhoneNumber
})

const emit = defineEmits(['validated', 'submitted'])

const mobilePhoneNumberId = ref(props.mobilePhoneNumberId)
const mobilePhoneNumber = ref(props.mobilePhoneNumber)
const landlinePhoneNumberId = ref(props.landlinePhoneNumberId)
const landlinePhoneNumber = ref(props.landlinePhoneNumber)
const deskPhoneNumberId = ref(props.deskPhoneNumberId)
const deskPhoneNumber = ref(props.deskPhoneNumber)
const submitted = ref(false)
const submitSucceed = ref(false)
const hasChanged = ref(false)

const hasMobilePhoneChanged = () => {
    const { mobilePhoneNumber: originalMobile } = originalPhoneValues.value
    return originalMobile !== mobilePhoneNumber.value
}

const hasLandlinePhoneChanged = () => {
    const { landlinePhoneNumber: originalLandline } = originalPhoneValues.value
    return originalLandline !== landlinePhoneNumber.value
}

const hasDeskPhoneChanged = () => {
    const { deskPhoneNumber: originalDesk } = originalPhoneValues.value
    return originalDesk !== deskPhoneNumber.value
}

watch([mobilePhoneNumber, landlinePhoneNumber, deskPhoneNumber], () => {
    hasChanged.value = hasMobilePhoneChanged() || hasLandlinePhoneChanged() || hasDeskPhoneChanged()
})

const rules = {
    mobilePhoneNumber: { required, minLength: minLength(10) },
    landlinePhoneNumber: { minLength: minLength(10) },
    deskPhoneNumber: { minLength: minLength(10) }
}

const v$ = useVuelidate(rules, { mobilePhoneNumber, landlinePhoneNumber, deskPhoneNumber })

async function validate () {
    submitSucceed.value = false
    submitted.value = false

    emit('validated', await v$.value.$validate())
}

function buildPhoneNumberEntry (value, id) {
    const entry = { value }
    if (!isEmpty(id)) {
        entry.id = id
    }
    return entry
}

async function submit () {
    let result = { success: true }
    if (hasChanged.value) {
        const body = {}

        if (!isEmpty(landlinePhoneNumber.value)) {
            body.landlinePhoneNumber = buildPhoneNumberEntry(landlinePhoneNumber.value, landlinePhoneNumberId.value)
        }

        if (!isEmpty(mobilePhoneNumber.value)) {
            body.mobilePhoneNumber = buildPhoneNumberEntry(mobilePhoneNumber.value, mobilePhoneNumberId.value)
        }

        if (!isEmpty(deskPhoneNumber.value)) {
            body.deskPhoneNumber = buildPhoneNumberEntry(deskPhoneNumber.value, deskPhoneNumberId.value)
        }

        result = await store.dispatch(userActionTypes.SET_PHONE, body)
    }

    submitSucceed.value = result.success
    submitted.value = true
    hasChanged.value = false

    emit('submitted', result)
}

defineExpose({ validate, submit, hasChanged })
</script>
