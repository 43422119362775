<template>
    <div class="OrderBasketProduct d-flex flex-wrap justify-space-between align-center gap-5 w-100 py-3 px-0">
        <div>
            <strong class="OrderBasketProduct-quantity mr-1 font-weight-bold">{{ product.quantity }}x</strong>
            <strong class="OrderBasketProduct-title">{{ product.name }}</strong>
            <p class="OrderBasketProduct-title">
                {{ product.providerNetworkLabel }}
            </p>
            <div
                v-if="product.providerItemDescription !== ''"
                class="OrderBasketProduct-providerDescription"
                v-html="$sanitize(formattedProviderItemDescription)"
            />
        </div>
        <formatted-fare
            :fare="product.totalFareIncludingTaxes"
            :currency="currency"
            :size="'Small'"
            class="OrderBasketProduct-fare ma-0"
        />
        <div
            v-if="!isEmpty(product.address)"
            class="OrderBasketProduct-address"
        >
            <span>{{ $t('address:delivery_mode:postal_on_product:get_order') }}</span>
            <address class="ma-0">
                <template v-if="product.address.receiverFirstname || product.address.receiverLastname">
                    <strong>{{ product.address.receiverFirstname ?? '' }} {{ product.address.receiverLastname ?? '' }}</strong>
                </template>
                <div
                    v-for="(line, index) in fullAddress"
                    :key="index"
                >
                    {{ line }}
                </div>
            </address>
        </div>
    </div>
</template>

<script setup>
import FormattedFare from '@/StoreWeb/components/common/FormattedFare'
import { isEmpty } from 'global-utils'
import { computed } from 'vue'

const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    currency: {
        type: String,
        default: 'EUR'
    }
})
const fullAddress = computed(() => {
    const address = props.product.address
    const lines = []
    if (address.streetNumber || address.streetName) {
        lines.push(`${address.streetNumber ?? ''} ${address.streetNumberSuffix ?? ''} ${address.streetName ?? ''}`)
    }
    if (address.streetLine2) {
        lines.push(address.streetLine2)
    }
    if (address.zipCode || address.city) {
        lines.push(`${address.zipCode ?? ''} ${address.city ?? ''}`)
    }
    if (address.country) {
        lines.push(address.country)
    }

    return lines.filter(line => line.trim() !== '')
})

const formattedProviderItemDescription = computed(() => {
    if (!props.product.providerItemDescription) return ''
    return props.product.providerItemDescription.replace(/\n/g, '<br>')
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.OrderBasketProduct {
    &-quantity {
        font-weight: $fontWeight-defaultRegular;
        color: $color-brandSecondary;
    }

    &-title {
        font-weight: $fontWeight-defaultMedium;
        color: $color-lightText;
    }

    &-fare {
        color: $color-lightText;
    }

    &-address {
        font-size: 14px;
    }

    &-providerDescription {
        font-size: 14px;
        color: $color-lightText;
    }
}
</style>
