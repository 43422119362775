export default class {
    constructor (id) {
        this._birthDate = ''
        this._civility = ''
        this._deskPhoneNumber = ''
        this._email = ''
        this._firstName = ''
        this._id = id
        this._lastName = ''
        this._mobilePhoneNumber = null
        this._landlinePhoneNumber = null
    }

    get birthDate () {
        return this._birthDate
    }

    set birthDate (birthDate) {
        this._birthDate = birthDate
    }

    get civility () {
        return this._civility
    }

    set civility (civility) {
        this._civility = civility
    }

    get deskPhoneNumber () {
        return this._deskPhoneNumber
    }

    set deskPhoneNumber (deskPhoneNumber) {
        this._deskPhoneNumber = deskPhoneNumber
    }

    get email () {
        return this._email
    }

    set email (email) {
        this._email = email
    }

    get firstName () {
        return this._firstName
    }

    set firstName (firstName) {
        this._firstName = firstName
    }

    get id () {
        return this._id
    }

    set id (id) {
        this._id = id
    }

    get landlinePhoneNumber () {
        return this._landlinePhoneNumber
    }

    set landlinePhoneNumber (landlinePhoneNumber) {
        this._landlinePhoneNumber = landlinePhoneNumber
    }

    get lastName () {
        return this._lastName
    }

    set lastName (lastName) {
        this._lastName = lastName
    }

    get mobilePhoneNumber () {
        return this._mobilePhoneNumber
    }

    set mobilePhoneNumber (mobilePhoneNumber) {
        this._mobilePhoneNumber = mobilePhoneNumber
    }

    getBirthDateTime () {
        return this.birthDate.split('T')[0] + 'T00:00:00'
    }

    toJSON () {
        return {
            birthDate: this.birthDate,
            civility: this.civility,
            deskPhoneNumber: this.deskPhoneNumber,
            email: this.email,
            firstName: this.firstName,
            id: this.id,
            landlinePhoneNumber: this.landlinePhoneNumber,
            lastName: this.lastName,
            mobilePhoneNumber: this.mobilePhoneNumber
        }
    }
}
