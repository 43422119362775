<template>
    <div class="SytralForm">
        <v-alert
            v-if="submitCompleted && submitErrors.length"
            variant="outlined"
            type="warning"
            closable
            prominent
            border="start"
        >
            <div
                v-for="(error, index) in submitErrors"
                :key="index"
                v-html="$sanitize(error)"
            />
        </v-alert>
        <component
            :is="compo.component"
            v-for="(compo, index) in components"
            v-bind="compo.props"
            :key="index"
            :ref="el => componentRefs['component_' + index] = el"
            @validated="handleValidated"
            @submitted="handleSubmitted"
        />
        <div class="d-flex justify-center justify-md-end">
            <v-btn
                class="ml-2"
                color="primary"
                :disabled="buttonDisabled"
                @click="handleValidation"
            >
                {{ $t('personal_info:edit:button') }}
            </v-btn>
        </div>
    </div>
</template>

<script setup>
import EditCivility from '@/StoreWeb/components/account/informations/Sytral/EditCivility.vue'
import EditEmail from '@/StoreWeb/components/account/informations/Sytral/EditEmail.vue'
import EditPhone from '@/StoreWeb/components/account/informations/Sytral/EditPhone.vue'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import emitter from 'global-emitter'
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const store = useStore()
const { t } = useI18n()

const userData = reactive({
    email: '',
    firstName: '',
    lastName: '',
    civility: '',
    birthdate: '',
    mobilePhoneNumber: '',
    mobilePhoneNumberId: '',
    landlinePhoneNumber: '',
    landlinePhoneNumberId: '',
    deskPhoneNumber: '',
    deskPhoneNumberId: ''
})

const components = computed(() => [
    {
        component: EditCivility,
        props: {
            firstName: userData.firstName,
            lastName: userData.lastName,
            civility: userData.civility,
            birthdate: userData.birthdate
        }
    },
    { component: EditEmail, props: { email: userData.email } },
    {
        component: EditPhone,
        props: {
            mobilePhoneNumber: userData.mobilePhoneNumber,
            mobilePhoneNumberId: userData.mobilePhoneNumberId,
            landlinePhoneNumber: userData.landlinePhoneNumber,
            landlinePhoneNumberId: userData.landlinePhoneNumberId,
            deskPhoneNumber: userData.deskPhoneNumber,
            deskPhoneNumberId: userData.deskPhoneNumberId
        }
    }
])

const componentRefs = reactive({})
components.value.forEach((component, index) => {
    const name = `component_${index}`
    componentRefs[name] = ref(null)
})

const validatedCount = ref(0)
const submittedCount = ref(0)

const validationCompleted = ref(false)
const submitCompleted = ref(false)

const submitErrors = ref([])

// We need a special var to store the fact that email has changed because email has a specific
// feedback message that we always want to show
const emailChangesResult = ref(null)
const buttonDisabled = computed(() => {
    let disabled = true
    components.value.forEach((component, index) => {
        const thisComponent = componentRefs[`component_${index}`]
        if (thisComponent?.hasChanged) {
            disabled = false
        }
    })
    return disabled
})

const handleValidation = () => {
    validatedCount.value = 0
    submitCompleted.value = false

    components.value.forEach((component, index) => {
        const thisComponent = componentRefs[`component_${index}`]
        thisComponent.validate()
    })
}

const handleValidated = async (validated) => {
    if (validated) {
        validatedCount.value++
        if (validatedCount.value === components.value.length) {
            validationCompleted.value = true
            submittedCount.value = 0
            submitErrors.value = []

            store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

            // We want to loop asynchronously on each component to submit them ONE BY ONE
            // because the backend service is unable to keep up coherence with multiple requests at the same time
            let index = 0
            // eslint-disable-next-line no-unused-vars
            for (const _ of components.value) {
                const thisComponent = componentRefs[`component_${index}`]
                await thisComponent.submit()
                index++
            }
        }
    }
}

// This function is called by each component when it has finished submitting so it behaves like a loop
// It will treat each `result` one by one and each result will erase the previous result
// Then we have a problem : the email result must always be kept to display a specific message
const handleSubmitted = (result) => {
    submittedCount.value++

    // If the actual result is of type email, we absolutely want to keep the result in a variable
    // to reuse it at the end of the process
    if (result.type === 'email') {
        emailChangesResult.value = result
    }

    // If all components have been submitted, we can stop the loader and display the feedback message
    if (submittedCount.value === components.value.length) {
        submitCompleted.value = true

        store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

        store.dispatch(userActionTypes.GET_PERSONAL_INFO, {
            cachiosOptions: {
                force: true
            }
        })

        // If everything was successfully submitted, we can display a success message
        if (result.success && !submitErrors.value.length) {
            let msg = t('personal_info:edit:success')

            // And if the email was also changed, we absolutely want it to have a specific message
            if (emailChangesResult.value !== null) {
                msg = t('email:edit:success')
                    .replaceAll('%newEmail%', emailChangesResult.value.newEmail)
            }

            store.commit(mainMutationTypes.SET_FEEDBACK_SUCCESS, msg)
        }
    }

    if (!result.success) {
        submitErrors.value.push(result.reason)
    }
}

onMounted(() => {
    emitter.on('personalInfoLoaded', onPersonalInfoLoadedHandler)
    store.dispatch(userActionTypes.GET_PERSONAL_INFO)
})

onUnmounted(() => {
    emitter.off('personalInfoLoaded', onPersonalInfoLoadedHandler)
})

const onPersonalInfoLoadedHandler = () => {
    userData.civility = store.state.userModule.personalInfo.civility
    userData.lastName = store.state.userModule.personalInfo.lastName
    userData.firstName = store.state.userModule.personalInfo.firstName
    userData.birthdate = store.state.userModule.personalInfo.birthDate
    userData.email = store.state.userModule.personalInfo.email
    userData.mobilePhoneNumber = store.state.userModule.personalInfo.mobilePhoneNumber?.value
    userData.mobilePhoneNumberId = store.state.userModule.personalInfo.mobilePhoneNumber?.id
    userData.landlinePhoneNumber = store.state.userModule.personalInfo.landlinePhoneNumber?.value
    userData.landlinePhoneNumberId = store.state.userModule.personalInfo.landlinePhoneNumber?.id
    userData.deskPhoneNumber = store.state.userModule.personalInfo.deskPhoneNumber?.value
    userData.deskPhoneNumberId = store.state.userModule.personalInfo.deskPhoneNumber?.id
}
</script>
