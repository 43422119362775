<template>
    <div class="CardPayment">
        <div class="CardPayment-confirm">
            <v-btn
                class="CardPayment-confirmButton"
                color="primary"
                @click="submitPayment"
            >
                {{ buttonLabel }}
            </v-btn>
        </div>
        <div
            v-if="isHtml"
            class="CardPayment-paymentFormWrapper"
            v-html="purifiedCode"
        />
    </div>
</template>

<script setup>
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import emitter from 'global-emitter'
import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted, onUpdated, reactive } from 'vue'
import { useRouter } from 'vue-router'
import AddressNormalizer from '@/StoreWeb/normalizers/address/AddressNormalizerSingleton'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'
import DOMPurify from 'dompurify'
import { isEmpty } from '@/StoreWeb/utils/global-utils'
import { getCartConfirmPayload } from '@/StoreWeb/js/composables/cart-utils'

const store = useStore()
const addressNormalizer = reactive(AddressNormalizer.getInstance())

const router = useRouter()
defineProps({
    buttonLabel: {
        type: String,
        default: 'confirm_order_card_payment_button'
    }
})

const getCart = computed(() => store.getters.getCart)
const payment = computed(() => store.state.paymentModule.payment)
const codeType = computed(() => {
    return payment.value ? payment.value.codeType : ''
})
const code = computed(() => {
    return (codeType.value === 'HTML' || codeType.value === 'TOKEN' || codeType.value === 'URL' ? payment.value.code : '')
})
const purifiedCode = computed(() => {
    return DOMPurify.sanitize(code.value, { ADD_TAGS: ['form', 'input', 'button'], ADD_ATTR: ['action', 'method', 'type', 'name', 'value'] })
})

const isHtml = computed(() => codeType.value === 'HTML')

onMounted(() => {
    emitter.on('paymentInitTokenLoaded', paymentInitTokenLoadedHandler)
    emitter.on('paymentInitUrlLoaded', paymentInitUrlLoadedHandler)
})

onUnmounted(() => {
    emitter.off('paymentInitTokenLoaded', paymentInitTokenLoadedHandler)
    emitter.off('paymentInitUrlLoaded', paymentInitUrlLoadedHandler)
})

onUpdated(() => {
    if (codeType.value === 'HTML') {
        const form = document.querySelector('.CardPayment-paymentFormWrapper form')
        if (form !== null) {
            form.submit()
        }
    }
})

const paymentInitTokenLoadedHandler = () => {
    router.push({ name: 'paymentForm' })
}
const paymentInitUrlLoadedHandler = () => {
    window.location.href = payment.value.code
}
const normalizeAddressFields = (payload) => {
    const fields = ['billingAddress', 'deliveryAddress']
    fields.forEach(field => {
        if (!payload[field]) {
            return
        }
        payload[field] = addressNormalizer.normalize(payload[field])
    })
    if (!isEmpty(payload.deliveryClickAndCollectAddress?.clickAndCollect)) {
        if (!payload.deliveryClickAndCollectAddress.clickAndCollect.address) {
            payload.deliveryClickAndCollectAddress.clickAndCollect.address = {}
        }
        payload.deliveryClickAndCollectAddress.clickAndCollect.address.streetName = payload.deliveryClickAndCollectAddress?.streetName
        payload.deliveryClickAndCollectAddress.clickAndCollect.address.zipCode = payload.deliveryClickAndCollectAddress?.zipCode
        payload.deliveryClickAndCollectAddress.clickAndCollect.address.city = payload.deliveryClickAndCollectAddress?.city
    }
}

const submitPayment = async () => {
    try {
        if (getCart.value.status !== 'PAYMENT_PROCESSING') {
            const cartConfirmPayload = getCartConfirmPayload(normalizeAddressFields)

            if (!cartConfirmPayload) {
                return
            }

            if (['PROVIDERS_TOTAL_CONFIRMED', 'PROVIDERS_TOTAL_CREATED'].includes(getCart.value.status)) {
                await store.dispatch(cartActionTypes.CART_CONFIRMATION, cartConfirmPayload)
            }
        }

        await store.dispatch(paymentActionTypes.PAYMENT_INIT)
    } catch (error) {
        ErrorManager.displayErrorModal('cart:get_cart_confirmation', error)
    }
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.CardPayment {
    &-confirm {
        padding: 15px 0;
        font-size: $font-xsmall;
        text-align: center;
        color: $color-lighterText;
    }

    &-confirmButton {
        margin: $margin-large auto 0;
    }

    &-paymentFormWrapper {
        display: none;
    }
}
</style>
