import { computed } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'

export const useCart = () => {
    const store = useStore()
    const cart = computed(() => store.getters.getCart)

    const baskets = computed(() => !isEmpty(cart.value) && cart.value.baskets ? cart.value.baskets : [])

    const packages = computed(() => !isEmpty(cart.value) && cart.value.packages ? cart.value.packages : [])

    const plans = computed(() => !isEmpty(cart.value) && cart.value.plans ? cart.value.plans : [])

    const cartTotal = computed(() => !isEmpty(cart.value) && cart.value.totalAmountIncludingTaxes ? cart.value.totalAmountIncludingTaxes : 0)

    const cartImmediateTotal = computed(() => !isEmpty(cart.value) && cart.value.immediateAmountIncludingTaxes ? cart.value.immediateAmountIncludingTaxes : 0)

    const hasDifferentImmediateAndTotalAmount = computed(() => cartTotal.value !== cartImmediateTotal.value)

    const isEmptyCart = computed(() => isEmpty(cart.value) || (!isEmpty(cart.value) && baskets.value.length === 0 && packages.value.length === 0 && plans.value.length === 0))

    const currency = computed(() => !isEmpty(cart.value?.currency) ? cart.value.currency : 'EUR')

    return {
        cart,
        /**
         * A list of the baskets in the cart
         * @type {import('vue').ComputedRef<any[]>}
         */
        baskets,
        /**
         * A list of the packages in the cart
         * @type {import('vue').ComputedRef<any[]>}
         */
        packages,
        /**
         * A list of the plans in the cart
         * @type {import('vue').ComputedRef<any[]>}
         */
        plans,
        /**
         * The total amount of the cart including taxes
         * @type {import('vue').ComputedRef<number>}
         */
        cartTotal,
        /**
         * The immediate (that we have to pay today) total amount of the cart including taxes
         * @type {import('vue').ComputedRef<number>}
         */
        cartImmediateTotal,
        /**
         * Whether the immediate and total amount of the cart are different
         * @type {import('vue').ComputedRef<boolean>}
         */
        hasDifferentImmediateAndTotalAmount,
        /**
         * Whether the cart is empty
         * @type {import('vue').ComputedRef<boolean>}
         */
        isEmptyCart,
        /**
         * Get the currency of the cart
         * @type {import('vue').ComputedRef<string>}
         */
        currency
    }
}
