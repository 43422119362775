<template>
    <div class="AccountAssociationRequestList">
        <AccountAssociationDescription
            class="AccountAssociationRequestList-description mb-8"
            :translation-params="{ cmsUrl: config.cms_url }"
        />
        <div
            v-if="showAccountAssociationListLoader"
            class="AccountAssociationRequestList-loader"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            />
        </div>
        <ul
            v-if="userAccountAssociations.length"
            class="AccountAssociationRequestList-list"
        >
            <account-association-request-item
                v-for="accountAssociationRequestItem in userAccountAssociations"
                :key="accountAssociationRequestItem.id"
                :accountAssociationRequest="accountAssociationRequestItem"
                class="AccountAssociationRequestList-item"
            />
        </ul>
        <v-alert
            v-else
            class="AccountAssociationRequestList-empty"
            color="secondary"
            variant="tonal"
        >
            <icomoon-icon
                class="AccountAssociationRequestList-emptyIcon"
                name="Group"
            />
            {{ $t('account_association:empty_list') }}
        </v-alert>
        <template v-if="getShowUserAccountSubscription">
            <user-account-subscription-button
                :provider="getSelectedProvider"
                @show-user-account-subscription-modal="setShowUserAccountSubscriptionModal"
            />
            <user-account-subscription-modal v-model="showUserAccountSubscriptionModal" />
        </template>
        <div
            v-else-if="!isEmpty(getSelectedProvider) && accountAssociationTypes?.length"
            class="d-flex justify-center"
        >
            <v-btn
                v-if="config.features?.account_association?.add && !showAccountAssociationListLoader && !showAccountAssociationAddLoaderAssociations"
                color="primary"
                variant="text"
                class="AccountAssociationRequestList-addAssociation"
                @click="showAccountAssociationAddForm"
            >
                <v-icon
                    icon="mdi-account-multiple-plus"
                    class="mr-2"
                />
                {{ $t('button:associate_an_account') }}
            </v-btn>
            <account-association-add-modal
                v-if="showAccountAssociationAddModal"
                v-model="showAccountAssociationAddModal"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import AccountAssociationRequestItem from './AccountAssociationRequestItem.vue'
import AccountAssociationAddModal from './AccountAssociationAddModal.vue'
import * as actionTypes from '@/StoreWeb/store/modules/account-association/action-types'
import config from 'config'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import UserAccountSubscriptionButton from '@/StoreWeb/components/account/UserAccountSubscriptionButton.vue'
import UserAccountSubscriptionModal from '@/StoreWeb/components/account/UserAccountSubscriptionModal.vue'
import { useUserAccountSubscription } from '@/StoreWeb/js/composables/useUserAccountSubscription'

const store = useStore()
const {
    showUserAccountSubscriptionModal,
    getSelectedProvider,
    getShowUserAccountSubscription,
    getWallet,
    setShowUserAccountSubscriptionModal
} = useUserAccountSubscription()

const showAccountAssociationAddModal = ref(false)

const accountAssociationTypes = computed(() => store.state.accountAssociationModule.accountAssociationTypes)
const userAccountAssociations = computed(() => store.state.accountAssociationModule.userAccountAssociations)
const accountAssociationPendingRequests = computed(() => store.state.accountAssociationModule.accountAssociationPendingRequests)
const showAccountAssociationAddLoaderAssociations = computed(() => accountAssociationPendingRequests.value.getAssociationTypes)
const showAccountAssociationListLoader = computed(() => {
    return accountAssociationPendingRequests.value.getUserAssociations ||
        accountAssociationPendingRequests.value.createAccountAssociation ||
        accountAssociationPendingRequests.value.deleteAccountAssociation
})

function showAccountAssociationAddForm () {
    showAccountAssociationAddModal.value = true
}

onMounted(async () => {
    await store.dispatch(actionTypes.GET_ACCOUNT_ASSOCIATION_TYPES)

    if (!isEmpty(getSelectedProvider.value?.accounts)) {
        await store.dispatch(actionTypes.GET_USER_ACCOUNT_ASSOCIATIONS)
    }
})

watch(() => getWallet.value, async () => {
    if (!isEmpty(getSelectedProvider.value?.accounts)) {
        await store.dispatch(actionTypes.GET_USER_ACCOUNT_ASSOCIATIONS)
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AccountAssociationRequestList {
    &-loader {
        width: 100%;
        margin-bottom: $s6;
        text-align: center;
    }

    &-block {
        display: flex;
        flex-direction: column;
        gap: $s4;
    }

    &-list {
        margin: 0 0 $s5;
        padding: 0;
        list-style: none;
    }

    &-empty {
        margin-bottom: $s4;

        :deep(.v-alert__content) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: $s2;
        }
    }

    &-emptyIcon {
        font-size: 20px;
        color: $color-brandPrimary;
    }

    &-addAssociation {
        width: max-content;
        font-weight: $fontWeight-defaultBold;
    }
}
</style>
